import { APIChecklistConfig } from "@/APIModels/configuration/features/types";
import { UIFeatureConfig } from "@/UIModels/configuration/features";

export class UIChecklistConfig {
  public enabled = false;
  public outcomes = new UIFeatureConfig();

  public constructor(apiSource?: APIChecklistConfig) {
    if (apiSource?.enabled) {
      this.enabled = apiSource.enabled;
      this.outcomes = new UIFeatureConfig(apiSource.outcomes);
    }
  }
}
