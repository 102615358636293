import store from '@/store/index';
import { createRouter, createWebHistory } from "vue-router";
import { isMasked } from '@/utils';
import i18n from '@/i18n';

import Base from '@/layouts/Base.vue';
import BaseOrgan from '@/layouts/BaseOrgan.vue';
import BaseRecipient from '@/layouts/BaseRecipient.vue';
import BaseDeceasedDonor from '@/layouts/BaseDeceasedDonor.vue';
import BaseLivingDonor from '@/layouts/BaseLivingDonor.vue';

import Index from '@/views/Index.vue';
import Login from '@/views/Login.vue';
import FusionRequest from '@/views/FusionRequest.vue';
import AccessToken from '@/views/AccessToken.vue';
import Reports from '@/views/Reports.vue';
import NewOrgan from '@/views/organs/NewOrgan.vue';
import EditOrgan from '@/views/organs/EditOrgan.vue';
import EditPolicyExemptions from '@/views/organs/EditPolicyExemptions.vue';
import EditDeceasedDonorAllocations from '@/views/deceasedDonors/EditDeceasedDonorAllocations.vue';
import RecipientHla from '@/views/hla/RecipientHla.vue';
import NewRecipient from '@/views/recipients/NewRecipient.vue';
import EditRecipient from '@/views/recipients/EditRecipient.vue';
import WaitlistSummary from '@/views/waitlist/WaitlistSummary.vue';
import ListRecipients from '@/views/recipients/ListRecipients.vue';
import NewDeceasedDonor from '@/views/deceasedDonors/NewDeceasedDonor.vue';
import EditDeceasedDonor from '@/views/deceasedDonors/EditDeceasedDonor.vue';
import ListDeceasedDonors from '@/views/deceasedDonors/ListDeceasedDonors.vue';
import ListDeceasedDonorsActive from '@/views/deceasedDonors/ListDeceasedDonorsActive.vue';
import NewLivingDonor from '@/views/livingDonors/NewLivingDonor.vue';
import EditLivingDonor from '@/views/livingDonors/EditLivingDonor.vue';
import EditLivingDonorAllocations from '@/views/livingDonors/EditLivingDonorAllocations.vue';
import ListLivingDonors from '@/views/livingDonors/ListLivingDonors.vue';
import Administration from '@/views/Administration.vue';
import CtrIntegration from '@/views/administration/CtrIntegration.vue';
import Accounts from '@/views/administration/Accounts.vue';
import ProfilePage from '@/views/administration/ProfilePage.vue';
import SearchIndex from "@/views/search/SearchIndex.vue";
import Test from "@/views/test/Test.vue";
import ProtoPrioritizationReport from "@/prototypes/views/recipients/ProtoPrioritizationReport.vue";
import ProtoListRecipients from '@/prototypes/views/recipients/ProtoListRecipients.vue';
import { setPageTitle, resetPageTitle, useCurrentPageStore } from '@/stores/currentPage';
import { UIRecipient } from './UIModels/recipient';
import { PROTOTYPES } from './UIModels/prototypes/list';
import ImportPatient from '@/views/importPatient/ImportPatient.vue';
import ProtoOffersPages from '@/prototypes/offers/ProtoOffersPages.vue';
import { UIImportPatients } from './UIModels/importPatients';
import { UILivingDonor } from './UIModels/livingDonor';

// Configure Bootstrap scroll spy functionality to show scroll position on the sidebar
document.body.setAttribute('data-spy', 'scroll');
document.body.setAttribute('data-target', '.page-nav');
document.body.setAttribute('data-offset', '150');
document.body.setAttribute('data-n-head','data-spy,data-target,data-offset');

/*
  Configure which 'option' values are permitted for deceased-donor-organ-option (allocation) route. This determines
  routing behaviour only, whereas consequences of these options are determined within the relevant vue component.
  E.g. Kidney allocations must be 'local' or 'provincial', which affects the allocation POST request payload generated
  in the 'AllocationControls' component.
*/
const ORGAN_ALLOCATION_OPTIONS: { [key: string]: string[] } = {
  // Kidney
  '3': ['local', 'provincial', 'double'],
};

// TODO: TECH_DEBT remove 'any' in favour of strict typing
const routes: any[] = [
  {
    /**
     * NOTE: we are relying on checking this 'name' to handle some special cases for widget loading
     *
     * if this dashboard index route name changes, we need to update loadIfStillViewingDashboard in:
     * - WidgetPrimaryOffers
     * - WidgetBackupOffers
     * - WidgetExdDonors
     * - WidgetTransplantInProgress
     * - WidgetLabResults
     */
    path: '/',
    name: 'index',
    component: Index,
    beforeEnter: (to: any, from: any, next: any) => {
      setPageTitle(i18n.t('page.dashboard'));
      next();
    },
  },
  {
    path: '/recipients',
    component: Base,
    children: [
      {
        path: '',
        name: "list-recipients",
        component:  ListRecipients,
        beforeEnter: (to: any, from: any, next: any) => {
          setPageTitle(i18n.t('page.recipient.list'));
          next();
        },
      },
      {
        path: 'new',
        component: NewRecipient,
        name: 'new-recipient',
        beforeEnter: (to: any, from: any, next: any) => {
          setPageTitle(i18n.t('page.recipient.new'));
          useCurrentPageStore().setCurrentRecipient(new UIRecipient());
          store.dispatch('users/loadUser');
          store.dispatch('validations/reset'),
          store.commit('labs/clearVirologies');
          store.dispatch('users/setRoute', 'recipients');
          next();
        }
      },
      {
        path: ':id',
        component: BaseRecipient,
        beforeEnter: (to: any, from: any, next: any) => {
          useCurrentPageStore().setCurrentRecipient(new UIRecipient(to.params.id));
          store.dispatch('users/loadUser');
          store.dispatch('validations/reset'),
          store.commit('recipients/clearRecipient');
          store.commit('recipients/clearRecipientDeath');
          store.dispatch('recipients/get', to.params.id);
          store.dispatch('users/setRoute', 'recipients');
          next();
        },
        children: [
          {
            path: 'new/organ/:organ_code',
            component: NewOrgan,
            name: 'new-organ',
            beforeEnter: (to: any, from: any, next: any) => {
              store.dispatch('users/loadUser');
              store.dispatch('validations/reset'),
              store.commit('journeyState/clearJourney');
              store.commit('journeyState/clearWaitlistDecisions');
              store.commit('journeyState/clearJourneyDurations');
              store.dispatch('users/setRoute', 'recipients/organ');
              next();
            }
          },
          {
            path: 'organs',
            component: BaseOrgan,
            beforeEnter: (to: any, from: any, next: any) => {
              store.dispatch('users/loadUser');
              store.dispatch('validations/reset'),
              store.commit('journeyState/clearJourney');
              store.commit('journeyState/clearWaitlistDecisions');
              store.commit('journeyState/clearJourneyDurations');
              store.dispatch('recipients/get', to.params.id).then(() => {
                store.dispatch('journeyState/getJourney', to.params.organ_id);
              });
              store.dispatch('users/setRoute', 'recipients/organ');
              next();
            },
            children: [
              {
                path: 'edit/:organ_id',
                component: EditOrgan,
                name: 'edit-organ',
                beforeEnter: (to: any, from: any, next: any) => {
                  // store.commit('recipients/clearRecipientDiagnosticsHla');
                  next();
                },
              },
              {
                path: 'edit/:organ_id/policy_exemptions',
                component: EditPolicyExemptions,
                name: 'edit-organ-policy_exemptions',
                beforeEnter: (to: any, from: any, next: any) => {
                  // store.commit('recipients/clearRecipientDiagnosticsHla');
                  next();
                },
              }
            ]
          },
          {
            path: 'hla',
            component: RecipientHla,
            name: 'recipient-hla',
            beforeEnter: (to: any, from: any, next: any) => {
              store.commit('recipients/clearRecipientDiagnosticsHla');
              next();
            },
          },
          // if nothing matches above show the edit page
          {
            path: '',
            component: EditRecipient,
            name: 'edit-recipient',
            beforeEnter: (to: any, from: any, next: any) => {
              store.dispatch('users/loadUser');
              store.dispatch('validations/reset'),
              store.dispatch('users/setRoute', 'recipients');
              next();
            }
          },
          {
            path: 'offers',
            component: ProtoOffersPages,
            name: 'proto-offers-pages',
            beforeEnter: (to: any, from: any, next: any) => {
              next();
            }
          }
        ]
      }
    ]
  },
  {
    path: '/proto_recipients',
    component: Base,
    children: [
      {
        path: '',
        name: "proto-recipients",
        component: ProtoListRecipients,
        beforeEnter: (to: any, from: any, next: any) => {
          if (!useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.ProtoRecipientsListings)) {
            next({ name: 'list-recipients' });
          }
          setPageTitle(i18n.t('page.recipient.list'));
          next();
        },
      },
    ]
  },
  {
    path: '/donors',
    component: Base,
    children: [
      {
        path: '',
        component: ListDeceasedDonors,
        name: 'list-deceased-donors',
        beforeEnter: (to: any, from: any, next: any) => {
          setPageTitle(i18n.t('page.donor.list'));
          next();
        },
      },
      {
        path: 'active',
        component: ListDeceasedDonorsActive,
        name: 'list-deceased-donors-active',
        beforeEnter: (to: any, from: any, next: any) => {
          setPageTitle(i18n.t('page.donor.active'));
          next();
        },
      },
      {
        path: 'new',
        component: NewDeceasedDonor,
        name: 'new-deceased-donor',
        beforeEnter: (to: any, from: any, next: any) => {
          setPageTitle(i18n.t('page.donor.new'));
          store.dispatch('users/loadUser');
          store.dispatch('validations/reset'),
          store.dispatch('users/setRoute', 'donor');
          next();
        }
      },
      {
        path: ':id',
        component: BaseDeceasedDonor,
        beforeEnter: (to: any, from: any, next: any) => {
          store.dispatch('users/loadUser');
          store.dispatch('validations/reset'),
          store.commit('labs/clearCrossmatchLab');
          store.commit('labs/clearCrossmatchSample');
          store.dispatch('users/setRoute', 'donor');
          next();
        },
        children: [
          {
            path: 'organ/:organ_code',
            name: 'deceased-donor-organ',
            component: EditDeceasedDonorAllocations,
            beforeEnter: (to: any, from: any, next: any) => {
              store.dispatch('users/loadUser');
              store.dispatch('validations/reset'),
              // load root donor record
              store.commit('deceasedDonors/clearDeceasedDonor');
              store.dispatch('deceasedDonors/get', to.params.id);
              store.dispatch('users/setRoute', 'donor/organ');
              // get exclusion filters
              store.dispatch('allocations/getExclusionRules', { clientId: to.params.id, organCode: to.params.organ_code });
              next();
            },
            children: [
              {
                path: ':option',
                name: 'deceased-donor-organ-option',
                component: EditDeceasedDonorAllocations,
                beforeEnter: (to: any, from: any, next: any) => {
                  // Get 'option' route parameter for Organ type e.g. 'local' or 'provincial' for Kidney
                  const option = to.params.option as string;
                  // Reject route if 'option' route parameter not expected for Organ type
                  const organCode = to.params.organ_code as string;
                  const organOptions: string[] = ORGAN_ALLOCATION_OPTIONS[organCode] || [];
                  if (!organOptions.includes(option)) {
                    // Redirect to Donor Profile page if invalid 'option'
                    console.warn('Invalid organ allocation option', { option, organCode });
                    next({ name: 'edit-deceased-donor', params: to.params });
                  } else {
                    // Proceed to Organ route only if valid 'option' route parameter
                    next();
                  }
                },
              }
            ],
          },
          {
            path: '',
            component: EditDeceasedDonor,
            name: 'edit-deceased-donor',
            beforeEnter: (to: any, from: any, next: any) => {
              store.dispatch('users/loadUser');
              store.dispatch('validations/reset'),
              store.dispatch('users/setRoute', 'donor');
              // load root donor record
              store.commit('deceasedDonors/clearDeceasedDonor');
              store.dispatch('deceasedDonors/get', to.params.id);
              next();
            },
          }
        ]
      }
    ]
  },
  {
    path: '/living-donors',
    component: Base,
    children: [
      {
        path: '',
        component: ListLivingDonors,
        name: 'list-living-donors',
        beforeEnter: (to: any, from: any, next: any) => {
          setPageTitle(i18n.t('page.living_donor.list'));
          next();
        },
      },
      {
        path: 'new',
        component: NewLivingDonor,
        name: 'new-living-donor',
        beforeEnter: (to: any, from: any, next: any) => {
          setPageTitle(i18n.t('page.living_donor.new'));
          useCurrentPageStore().setCurrentLivingDonor(new UILivingDonor());
          store.dispatch('users/loadUser');
          store.dispatch('validations/reset'),
          store.dispatch('users/setRoute', 'livingDonor');
          next();
        }
      },
      {
        path: ':id',
        component: BaseLivingDonor,
        beforeEnter: (to: any, from: any, next: any) => {
          useCurrentPageStore().setCurrentLivingDonor(new UILivingDonor(to.params.id));
          store.dispatch('users/loadUser');
          store.dispatch('validations/reset'),
          store.commit('livingDonors/clearLivingDonor');
          store.dispatch('livingDonors/get', to.params.id);
          store.dispatch('users/setRoute', 'livingDonor');
          next();
        },
        children: [
          {
            path: 'organ/:organ_code',
            name: 'living-donor-organ',
            component: EditLivingDonorAllocations,
            beforeEnter: (to: any, from: any, next: any) => {
              store.dispatch('users/loadUser');
              store.dispatch('validations/reset'),
              // load root donor record
              store.commit('livingDonors/clearLivingDonor');
              store.dispatch('livingDonors/get', to.params.id);
              store.dispatch('users/setRoute', 'livingDonor/organ');
              // get exclusion filters
              store.dispatch('livingAllocations/getExclusionRules', { clientId: to.params.id, organCode: to.params.organ_code });
              next();
            },
            children: [
              {
                path: ':option',
                name: 'living-donor-organ-option',
                component: EditLivingDonorAllocations,
                beforeEnter: (to: any, from: any, next: any) => {
                  // Get 'option' route parameter for Organ type e.g. 'local' or 'provincial' for Kidney
                  const option = to.params.option as string;
                  // // Reject route if 'option' route parameter not expected for Organ type
                  const organCode = to.params.organ_code as string;
                  const organOptions: string[] = ORGAN_ALLOCATION_OPTIONS[organCode] || [];
                  if (!organOptions.includes(option)) {
                    // Redirect to Donor Profile page if invalid 'option'
                    console.warn('Invalid organ allocation option', { option, organCode });
                    next({ name: 'edit-living-donor', params: to.params });
                  } else {
                    // Proceed to Organ route only if valid 'option' route parameter
                    next();
                  }
                },
              }
            ],
          },
          {
            path: '',
            component: EditLivingDonor,
            name: 'edit-living-donor',
            beforeEnter: (to: any, from: any, next: any) => {
              store.dispatch('users/loadUser');
              store.dispatch('validations/reset'),
              store.dispatch('users/setRoute', 'livingDonor');
              next();
            },
          }
        ]
      }
    ]
  },
  {
    path: '/reports',
    name: 'reports',
    component: Reports,
    beforeEnter: (to: any, from: any, next: any) => {
      setPageTitle(i18n.t('page.reports'));
      next();
    }
  },
  {
    path: '/administration',
    component: Base,
    children: [
      {
        path: '',
        name: 'administration',
        component: Administration,
        beforeEnter: (to: any, from: any, next: any) => {
          setPageTitle(i18n.t('page.admin'));
          next();
        },
      },
      {
        path: 'users',
        name: 'Users',
        component: Accounts,
        beforeEnter: (to: any, from: any, next: any) => {
          setPageTitle(i18n.t('page.manage_users'));
          next();
        },
      },
      {
        path: 'profile',
        name: 'profilePage',
        component: ProfilePage,
        beforeEnter: (to: any, from: any, next: any) => {
          setPageTitle(i18n.t('page.user_profile'));
          next();
        },
      },
      {
        path: 'ctr',
        name: 'ctrIntegration',
        component: CtrIntegration,
        beforeEnter: (to: any, from: any, next: any) => {
          setPageTitle(i18n.t('page.ctr_integration'));
          next();
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: (to: any, from: any, next: any) => {
      setPageTitle(i18n.t('page.login'));
      next();
    },
  },
  {
    path: '/access_token/fusionauth',
    name: 'fusionRequest',
    component: FusionRequest,
    beforeEnter: (to: any, from: any, next: any) => {
      setPageTitle(i18n.t('page.login'));
      next();
    },
  },
  {
    path: '/access_token',
    name: 'accessToken',
    component: AccessToken,
  },
  {
    path: '/waitlist',
    name: 'waitlist',
    component: WaitlistSummary,
    beforeEnter: (to: any, from: any, next: any) => {
      setPageTitle(i18n.t('page.waitlist'));
      next();
    },
  },
  {
    name: 'global-search',
    path: '/search',
    component: SearchIndex
  },
  {
    path: '/prioritization-report',
    name: 'proto-prioritization-report',
    component: ProtoPrioritizationReport,
    beforeEnter: (to: any, from: any, next: any) => {
      setPageTitle(i18n.t('page.prioritization_report'));
      next();
    },
  },
  {
    path: '/import',
    name: 'import',
    component: ImportPatient,
    beforeEnter: (to: any, from: any, next: any) => {
      useCurrentPageStore().setCurrentImportPatients(new UIImportPatients());
      setPageTitle(i18n.t('page.import'));
      next();
    }
  },
  {
    path: '/test',
    name: 'test',
    component: Test
  }
];

export const router = createRouter({
  // "mode: history" replaced with "createWebHistory" history function
  // "base" option now passed as first argument to history function (e.g. createWebHistory)
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Default redirection guard for all routes.
router.beforeEach((to, from, next) => {
  if (from.name !== to.name) resetPageTitle();

  // detect masked param with alert
  if (to.params) {
    const params = to.params;
    let mask_found = false;
    Object.keys(params).forEach(function (key) {
      if (isMasked(params[key] as string)) mask_found = true;
    });
    if (mask_found) {
      alert(i18n.t('permission-error'));
      // if possible return to the previous page
      if (from.fullPath) window.location.href = from.fullPath;
      next(false);
    }
  }

  store.dispatch('users/readAccessToken').then(() => {
    const isAuthenticated = store.getters['users/hasAccessToken'];
    if (to.name !== 'login' && to.name !== 'accessToken' && to.name !== 'fusionRequest' && !isAuthenticated) {
      // check for url before redirecting to login, store it to redirect after login
      const _to = to.fullPath || null;
      if (_to) {
        // store url in local storage and continue to login
        localStorage.setItem('after_login_url', _to);
        next({ name: 'login' });
      }
    } else {
      next();
    }
  }).catch(() => {
    next(false);
  });
});
