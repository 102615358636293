import { APIKidneySpecificDetailsConfig } from "@/APIModels/configuration/features/types";
import { UIFeatureConfig } from "@/UIModels/configuration/features";

export class UIKidneySpecificsConfig {
  public enabled = false;
  public paediatricAgeOverride = new UIFeatureConfig();

  public constructor(apiSource?: APIKidneySpecificDetailsConfig) {
    if (apiSource?.enabled) {
      this.enabled = apiSource.enabled;
      this.paediatricAgeOverride = new UIFeatureConfig(apiSource.paediatric_age_override);
    }
  }
}
