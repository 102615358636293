import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("span", null, [
    _createVNode(_component_router_link, { to: { name: 'list-living-donors' } }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('living_donors')), 1)
      ]),
      _: 1
    })
  ]))
}