<template>
  <div class="nav-block-wrap">
    <ul class="nav nav-block flex-column" :class="{ active: active }">
      <li class="nav-item">
        <a class="nav-link" :href="getUrl('hla-typing')">
           <font-awesome-icon :icon="['fas', (show ? 'chevron-down' : 'chevron-right')]" class="nav-caret" fixed-width @click.prevent="toggleNav"/>    
          {{ $t("hla_information") }}
          </a>
      </li>
      <ul class="nav flex-column" v-if="show">
        <li class="nav-item" @click="toggleSideNav">
          <a class="nav-link" :href="getUrl('hla-typing')">{{ $t("typing") }}</a>
        </li>
      </ul>
    </ul>
  </div>
</template>

<script lang="ts">
import { State, Getter } from 'vuex-facing-decorator';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { LivingDonor } from '@/store/livingDonors/types';
import { i18nMessages } from '@/i18n';

@Component({
  ...i18nMessages([
    require('@/components/livingDonors/_locales/common.json'),
    require('@/components/recipients/_locales/SelectedRecipientHlaLinks.json'),
  ])
})
export default class SelectedLivingDonorHlaLinks extends Vue {
  @State(state => state.livingDonors.selected) private donor!: LivingDonor;
  @Getter('clientId', { namespace: 'livingDonors' }) clientId!: string|undefined;

  @Prop({default: true}) open!: boolean;
  @Prop({default: false}) active!: boolean;

  public show = true;

  public toggleNav() {
    this.show = !this.show;
  }

  public toggleSideNav(): void {
    if (window.innerWidth < 992) { 
       this.$emit("toggle");
    }
  }

  public getUrl(hash: string): string {
    hash = `#${hash}`;
    if(this.active) {
      return hash;
    } else {
      // We are fine not checking for the existence of client_id as for new living donors we only ever show this in active mode
      const location = this.$router.resolve({ name: 'edit-living-donor', params: { id: (this.clientId || '') }, hash: hash });
      return location.href;
    }
  }
}
</script>
