<template>
  <div>
    <span class="skeleton-box skeleton-title w-100" />  
    <loading-section/>

    <span class="skeleton-box skeleton-title w-100" />  
    <loading-section/>

    <span class="skeleton-box skeleton-title w-100" />  
    <loading-section/>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import LoadingSection from '@/components/shared/loading-skeletons/LoadingSection.vue';

@Component({
  components: {
    LoadingSection
  }
})
export default class LoadingLivingDonorSections extends Vue {}
</script>
