import { APILungSpecificDetailsConfig } from "@/APIModels/configuration/features/types";
import { UIFeatureConfig } from "@/UIModels/configuration/features";

export class UILungSpecificsConfig {
  public enabled = false;
  public thoracicMeasurements = new UIFeatureConfig();

  public constructor(apiSource?: APILungSpecificDetailsConfig) {
    if (apiSource?.enabled) {
      this.enabled = apiSource.enabled;
      this.thoracicMeasurements = new UIFeatureConfig(apiSource.thoracic_measurements);
    }
  }
}
