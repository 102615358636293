import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_blood_type_form = _resolveComponent("blood-type-form")!
  const _component_measurements_section = _resolveComponent("measurements-section")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_openBlock(), _createBlock(_component_card_section, {
    lookupsToLoad: _ctx.lookupsToLoad,
    onLoaded: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loaded())),
    "section-id": "gci"
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('general_clinical_information')), 1)
    ]),
    body: _withCtx(() => [
      (_ctx.isLivingDonorGeneralClinicalBloodEnabled)
        ? (_openBlock(), _createBlock(_component_blood_type_form, {
            key: 0,
            ref: "bloodTypeForm"
          }, null, 512))
        : _createCommentVNode("", true),
      (_ctx.isLivingDonorGeneralClinicalMeasurementsEnabled)
        ? (_openBlock(), _createBlock(_component_measurements_section, {
            key: 1,
            ref: "measurementsForm"
          }, null, 512))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["lookupsToLoad"]))
}