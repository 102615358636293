import { APILiverSpecificDetailsConfig } from "@/APIModels/configuration/features/types";
import { UIFeatureConfig } from "@/UIModels/configuration/features";

export class UILiverSpecificsConfig {
  public enabled = false;
  public peldResults = new UIFeatureConfig();
  public hccCriteria = new UIFeatureConfig();
  public hccResults = new UIFeatureConfig();
  public bridgingTherapy = new UIFeatureConfig();
  public downstagingTherapy = new UIFeatureConfig();
  public aldProgramParticipation = new UIFeatureConfig();
  public exceptionDiseases = new UIFeatureConfig();

  public constructor(apiSource?: APILiverSpecificDetailsConfig) {
    if (apiSource?.enabled) {
      this.enabled = apiSource.enabled;
      this.peldResults = new UIFeatureConfig(apiSource.lab_liver_peld);
      this.hccCriteria = new UIFeatureConfig(apiSource.hcc_criteria);
      this.hccResults = new UIFeatureConfig(apiSource.hcc_data);
      this.bridgingTherapy = new UIFeatureConfig(apiSource.bridging_therapy);
      this.downstagingTherapy = new UIFeatureConfig(apiSource.downstaging_therapy);
      this.aldProgramParticipation = new UIFeatureConfig(apiSource.ald_program_participation);
      this.exceptionDiseases = new UIFeatureConfig(apiSource.exception_diseases);
    }
  }
}
