import { APIMeasurement } from '@/APIModels/recipients/types';
import { APIPermittedActions } from '@/types';
import { parseDateUi, sanitizeDateApi } from '@/utilities/date-utils';
import { toFixed, isValidFloatFormat } from '@/utils';
import { BmiResult } from '@/store/tools/types';

const DIGITS_AFTER_DECIMAL = 1;

export class UIMeasurement {
  public apiSource: APIMeasurement|null = null;

  public id: string|null = null;
  public date: string|null = null;
  public height_cm: string|null = null;
  public height_in: string|null = null;
  public weight_kg: string|null = null;
  public weight_lbs: string|null = null;
  public height_cm_rounded: string|null = null;
  public height_in_rounded: string|null = null;
  public weight_kg_rounded: string|null = null;
  public weight_lbs_rounded: string|null = null;
  public bmi: number|null = null;

  public loaded = false;
  public permittedActions: APIPermittedActions[] = [];

  get canEdit(): boolean {
    return this.permittedActions.includes(APIPermittedActions.Create) || this.permittedActions.includes(APIPermittedActions.Update);
  }

  // Define new UI view model structure
  public constructor(apiMeasurement: APIMeasurement|null = null) {
    if (apiMeasurement) this.updateFromAPIResponse(apiMeasurement);
  }

  // Is this an unsaved New Measurement?
  get isNew(): boolean {
    return !this.id;
  }

  // Map from API data structure to UI model structure
  public updateFromAPIResponse(apiMeasurement: APIMeasurement) {
    this.apiSource = apiMeasurement;
    this.id = apiMeasurement._id || null;
    this.date = parseDateUi(apiMeasurement.date) || null;
    this.height_cm_rounded = toFixed(apiMeasurement.height_cm, DIGITS_AFTER_DECIMAL) || null;
    this.height_cm = apiMeasurement.height_cm ? apiMeasurement.height_cm.toString() : null;
    this.height_in_rounded = toFixed(apiMeasurement.height_in, DIGITS_AFTER_DECIMAL) || null;
    this.height_in = apiMeasurement.height_in ? apiMeasurement.height_in.toString() : null;
    this.weight_kg_rounded = toFixed(apiMeasurement.weight_kg, DIGITS_AFTER_DECIMAL) || null;
    this.weight_kg = apiMeasurement.weight_kg ? apiMeasurement.weight_kg.toString() : null;
    this.weight_lbs_rounded = toFixed(apiMeasurement.weight_lbs, DIGITS_AFTER_DECIMAL) || null;
    this.weight_lbs = apiMeasurement.weight_lbs ? apiMeasurement.weight_lbs.toString() : null;
    this.bmi = apiMeasurement.bmi || null;
  }

  public updateFromBMIResult(bmiResult: BmiResult) {
    if (!bmiResult) return;

    // Set calculated BMI if it was provided in the calculation response
    this.bmi = bmiResult.bmi == null ? null : toFixed(bmiResult.bmi, DIGITS_AFTER_DECIMAL);
    // Convert height if applicable
    switch (bmiResult.height_unit) {
      case 'cm':
        if (this.height_cm_rounded != null && isValidFloatFormat(this.height_cm_rounded) && this.height_cm_rounded.length > 0) {
          this.height_in_rounded = toFixed(bmiResult.height.in, DIGITS_AFTER_DECIMAL);
          this.height_in = bmiResult.height.in.toString()
          this.height_cm_rounded = toFixed(bmiResult.height.cm, DIGITS_AFTER_DECIMAL);
          this.height_cm = bmiResult.height.cm.toString()
        } else if (this.height_cm == null || this.height_cm == '') {
          this.height_in = null;
        }
        break;
      case 'in':
        if (this.height_in_rounded != null && isValidFloatFormat(this.height_in_rounded) && this.height_in_rounded.length > 0) {
          this.height_cm_rounded = toFixed(bmiResult.height.cm, DIGITS_AFTER_DECIMAL);
          this.height_cm = bmiResult.height.cm.toString()
        } else if (this.height_in == null || this.height_in == '') {
          this.height_cm = null;
        }
        break;
    }

    // Convert weight if applicable
    switch (bmiResult.weight_unit) {
      case 'kg':
        if (this.weight_kg_rounded != null && isValidFloatFormat(this.weight_kg_rounded) && this.weight_kg_rounded.length > 0) {
          this.weight_lbs_rounded = toFixed(bmiResult.weight.lbs, DIGITS_AFTER_DECIMAL);
          this.weight_lbs = bmiResult.weight.lbs.toString()
          this.weight_kg_rounded = toFixed(bmiResult.weight.kg, DIGITS_AFTER_DECIMAL);
          this.weight_kg = bmiResult.weight.kg.toString()
        } else if (this.weight_kg == null || this.weight_kg == '') {
          this.weight_lbs = null;
        }
        break;
      case 'lbs':
        if (this.weight_lbs_rounded && isValidFloatFormat(this.weight_lbs_rounded) && this.weight_lbs_rounded.length > 0) {
          this.weight_kg_rounded = toFixed(bmiResult.weight.kg, DIGITS_AFTER_DECIMAL);
          this.weight_kg = bmiResult.weight.kg.toString()
        } else if (this.weight_lbs == null || this.weight_lbs == '') {
          this.weight_kg = null;
        }
        break;
    }
  }

  // Generate request payload parameters to provide to API for a Measurement patch
  public extractPatch(): APIMeasurement {
    const result: APIMeasurement = {
      date: sanitizeDateApi(this.date),
      height_cm: this.height_cm != null && isValidFloatFormat(this.height_cm) ? parseFloat(this.height_cm) : this.height_cm as any,
      weight_kg: this.weight_kg != null && isValidFloatFormat(this.weight_kg) ? parseFloat(this.weight_kg) : this.weight_kg as any,
      bmi: this.bmi
    };
    return result;
  }
}
