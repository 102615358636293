import { APIMeasurement } from '@/APIModels/recipients/types';
import { APIShowRecipientMeasurementData, APINewResonse, APIRules, APIShowResponse, RulesQuery, SaveResult } from '@/types';
import { UIRecipient } from '@/UIModels/recipient';
import axios from 'axios';
import { APIRoute, EP } from '@/api-endpoints';
import { UIError } from '@/UIModels/error';
import { UISuccess } from '@/UIModels/success';
import { APISaveMeasurementResponse } from '@/types';
import { buildErrorResult } from '@/utils';
import vuexStore from '@/store'; // for gradual conversion, see fullUserDetails
import { UIMeasurement } from '@/UIModels/measurement';

export class UIRecipientMeasurement extends UIMeasurement {
  // Load resource data and permitted actions
  public async load(opts: { selectedId: string, id: string, query: RulesQuery }): Promise<void> {
    if (!opts.selectedId) return;
    if (opts.id) {
      await this.loadShow(opts);
    } else {
      await this.loadNew(opts);
    }
  }

  // Load list for specified recipient
  public static async loadFor(uiRecipient: UIRecipient): Promise<UIRecipientMeasurement[]> {
    const recipientId = uiRecipient.clientId || '';
    let result: UIRecipientMeasurement[] = [];
    const url = APIRoute(EP.recipients.measurements.index, [[':recipientId', recipientId]]);
    await axios.get(url).then((response: any) => {
      const apiMeasurement: APIMeasurement[] = response?.data?.measurements || [];
      result = apiMeasurement.map((item: APIMeasurement): UIRecipientMeasurement => {
        return new UIRecipientMeasurement(item);
      });
    }).catch((error: any) => {
      console.warn(error);
      throw new UIError('recipient_measurements');
    });
    return result;
  }

  private async loadNew(opts: { selectedId: string, id: string, query: RulesQuery }): Promise<void> {
    const url = APIRoute(EP.recipients.measurements.index, [[':recipientId', (opts.selectedId)], [':id', (opts.id)]]);
    try {
      const response: APINewResonse = await axios.get(url, { params: opts.query });
      this.permittedActions = response.data.permitted_actions;
      this.setRules(response.data.rules);
      this.loaded = true;
    } catch (error: unknown) {
      this.loaded = true;
      console.warn(error);
    }
  }

  private async loadShow(opts: { selectedId: string, id: string }): Promise<void> {
    const url = APIRoute(EP.recipients.measurements.show, [[':recipientId', (opts.selectedId)], [':id', (opts.id)]]);
    try {
      const response: APIShowResponse<APIShowRecipientMeasurementData> = await axios.get(url);
      const apiMeasurement: APIMeasurement = response.data.measurement;
      this.permittedActions = response.data.permitted_actions;
      this.setRules(response.data.rules);
      this.updateFromAPIResponse(apiMeasurement);
      this.loaded = true;
    } catch (error: unknown) {
      this.loaded = true;
      console.warn(error);
    }
  }

  private setRules(rules: APIRules): void {
    vuexStore.commit('validations/resetPrefix', 'recipient_measurements');
    vuexStore.commit('validations/set', { rules: { ['recipient_measurements']: rules } });
  }

  // Save edit state to the backend
  public save(opts: { selected: UIRecipientMeasurement|null, recipient: UIRecipient }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const recipientId = opts.recipient.clientId;
      if (!recipientId) reject((new UIError('recipient_measurements')));

      let method: any;
      let ep: string;
      if (opts.selected?.id) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.measurements.update, [[':recipientId', recipientId as string], [':id', opts.selected.id]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.measurements.create, [[':recipientId', recipientId as string]]);
      }
      const payload = {
        measurements: this.extractPatch()
      };
      method(ep, payload).then((response: APISaveMeasurementResponse) => {
        if (response.data.errors) {
          reject((new UIError('recipient_measurements', response)).errorResult);
        } else {
          // Success! We may need to update the current page
          resolve((new UISuccess(response)).getSaveResult());
          // Success! We may need to update the current page
          opts.recipient.load({ reload: true }).then(() => {
            resolve((new UISuccess(response)).getSaveResult());
          });
        }
      }).catch((errorResponse: any) => {
        // if 403 alert user, as there will be no server response
        if (errorResponse.response.status === 403) {
          const forbiddenResponse = buildErrorResult(errorResponse.message);
          reject(forbiddenResponse);
        // otherwise send error response
        } else {
          reject((new UIError('recipient_measurements', errorResponse)).errorResult);
        }
      });
    });
  }
}
