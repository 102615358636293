import { APIManageWaitlistConfig } from "@/APIModels/configuration/features/types";
import { UIFeatureConfig } from "@/UIModels/configuration/features";

export class UIManageWaitlistConfig {
  public enabled = false;
  public activeListingTransfer = new UIFeatureConfig();

  public constructor(apiSource?: APIManageWaitlistConfig) {
    if (apiSource?.enabled) {
      this.enabled = apiSource.enabled;
      this.activeListingTransfer = new UIFeatureConfig(apiSource.transfer_active);  
    }
  }
}
