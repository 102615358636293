<template>
  <div>
    <page-top>
      {{ $t("living_donors")}}
    </page-top>
    <template v-if="!livingDonors">
      <div class="content-wrap">
        <div class="container-fluid">
          <loading-list-view />
        </div>
      </div>
    </template>
    <full-page-list-layout v-else>
      <template v-slot:content>
        <nav class="nav list-patients action-row">
          <div class="ml-auto">
            <router-link class="btn btn-primary ml-auto" :class="{ disabled: !canCreate }" :to="{ name: 'new-living-donor' }">
              {{ $t("add_new_living_donor") }}
            </router-link>
          </div>
        </nav>

        <table-filter
          @filterList="setSearchParams"
          :filterConfigation="getFilterConfiguration"
        />

        <div class="action-row d-flex">
          <p class="element-left">{{ $t("system_returned")}} <b>{{livingDonors?.count || 0}}</b> {{ $t('living_donors') }}</p>

          <column-config
            v-if="columnsFilter"
            class="ml-auto"
            columnKey="living_donors"
            config-id="living-donor-list-columns"
            :options="livingDonorColumns"
            :hiddenOptions="hiddenOptions"
            v-model="columnsFilter.selectedColumns"
          />
        </div>

        <div class="highlight-scroll" v-if="isOverflowed">
          {{ $t('scroll_right_to_see_more') }}
          <font-awesome-icon :icon="['fas', 'chevron-right']" class="nav-caret" fixed-width />
        </div>

        <table-list
          table-id="list-living-donors"
          :horizontalScroll="true"
          :listingView="true"
          mode="remote"
          :showTopScrollBar="true"
          :firstColumnTabbable="true"
          firstColumnRouterName="edit-living-donor"
          :total-records="livingDonors?.count || 0"
          :table-config="livingDonorTableConfig"
          :highlightSelection="true"
          @on-page-number="onPageNumber($event); loadData()"
          @on-page-size="onPageSize($event); loadData()"
          @on-sort-change="filterList($event)"
          @table-row-click="selectLivingDonor($event)"
          :isLoading="isLoading || !livingDonors"
          :jumpToPage="true"
        />
      </template>
    </full-page-list-layout>
  </div>
</template>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-facing-decorator';
import { TableConfig, ColumnOption, APIPaginatedSearchResult } from '@/types';
import PageTop from '@/components/shared/PageTop.vue';
import { ListLivingDonor, } from '@/store/livingDonors/types';
import { Component } from 'vue-facing-decorator';
import { Sex, BloodType, Ethnicity } from "@/store/lookups/types";
import { urlParams, isMasked } from '@/utils';
import LoadingListView from '@/components/shared/loading-skeletons/LoadingListView.vue';
import FullPageListLayout from "@/components/layouts/FullPageListLayout.vue";
import { EP } from '@/api-endpoints';
import { GenericCodeValue } from "@/store/types";
import { i18nMessages } from "@/i18n";
import { useCurrentPageStore } from "@/stores/currentPage";
import { RemotePaginationMixin } from "@/mixins/remote-pagination-mixin";
import TableList from '@/components/shared/TableList.vue';
import TableFilter, { FilterConfigurationType } from '@/components/shared/TableFilter.vue';
import ColumnConfig from '@/components/shared/ColumnConfig.vue';
import { Hospital } from "@/store/hospitals/types";
import { DATA_BUNDLES } from "@/UIModels/configuration/features";

const FILTER_CONFIGURATION: FilterConfigurationType = {
  txc: true,
  affloId: true,
  firstName: true,
  lastName: true,
  mrn: true,
  sex: true,
  abo: true,
  organCode: false,
  phase: false,
  step: false
}

interface ColumnsFilterState {
  selectedColumns: string[];
}

interface LivingDonortListingRow {
  transplant_centre: string;
  registration_date: string,
  client_id: string,
  first_name: string,
  last_name: string,
  blood_type: string;
  dob: string,
  mrn: string;
  sex: string;
  id: string;
}

const PAGE_SIZES = [10, 25, 100];
const DEFAULT_PAGE_SIZE = PAGE_SIZES[1]; // 25

@Component({
  components: {
    PageTop,
    TableList,
    TableFilter,
    ColumnConfig,
    LoadingListView,
    FullPageListLayout
  },
  ...i18nMessages([
    require('../_locales/listLivingDonors.json'),
    require('../_locales/common.json'),
    require('@/components/_locales/Organs.json'),
  ]),
})
export default class ListLivingDonors extends mixins(DateUtilsMixin, RemotePaginationMixin) {
  @State(state => state.pageState.currentPage.columnsFilter) columnsFilter!: ColumnsFilterState;
  @State(state => state.lookups.blood_type) bloodTypeLookup!: BloodType[];
  @State(state => state.hospitals.all) hospitals!: Hospital[];

  // getters
  @Getter('showList', { namespace: 'livingDonors' }) public livingDonors!: APIPaginatedSearchResult<ListLivingDonor>;
  @Getter('sexOptions', { namespace: 'lookups' }) sexOptions!: Sex[];
  @Getter('ethnicity', { namespace: 'lookups' }) ethnicity!: Ethnicity[];
  @Getter('organName', { namespace: 'lookups' }) organName!: (organCode?: number) => string|undefined;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;

  get getFilterConfiguration(): FilterConfigurationType {
    return FILTER_CONFIGURATION;
  }

  get isBasicNameEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.demographics.bundles.isEnabled(DATA_BUNDLES.PatientNameBasic);
  }

  get isDateOfBirthEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.demographics.bundles.isEnabled(DATA_BUNDLES.PatientDOBAge);
  }

  get isMRNEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.demographics.bundles.isEnabled(DATA_BUNDLES.PatientMRN);
  }

  get isSexEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.demographics.bundles.isEnabled(DATA_BUNDLES.PatientSex);
  }
  
  get lookupsToLoad(): string[] {
    const result = [
      'sex',
      'blood_type'
    ];
    return result;
  }

  public isOverflowed = false;
  private searchParams: string|null = null;
  private sortParams: string|null = null;

  private isLoadingLookups = true;
  private isLoadingEntries = true;

  get isLoading(): boolean {
    return this.isLoadingLookups || this.isLoadingEntries;
  }

  get hiddenOptions(): string[] {
    return this.livingDonorColumns.filter((column: ColumnOption) => {
      return column.hidden;
    }).map((column: ColumnOption): string => {
      return column.field;
    });
  }

  get canCreate(): boolean {
    return this.checkAllowed(EP.living_donors.create, 'POST');
  }

  private async loadLookups(lookupsToLoad: string[]): Promise<void> {
    this.isLoadingLookups = true;
    await Promise.all([
      ...lookupsToLoad.map((lookup: string): Promise<any> => { return this.$store.dispatch('lookups/queueLookup', { lookup }); }),
    ]);

    this.isLoadingLookups = false;
  }

  public checkwidth() {
    this.isOverflowed = window.innerWidth < 1200;
  }

  // Vue lifecycle hooks
  public mounted(): void {
    this.perPage = DEFAULT_PAGE_SIZE;
    Promise.all([
      this.$store.dispatch('hospitals/load'),
      this.loadLookups(this.lookupsToLoad),
    ]).finally(() => {
      this.loadData();
      this.initializePageState();
      this.checkwidth();
      window.addEventListener('resize', this.checkwidth);
    });
  }

  private initializePageState(): void {
    // Initialize filter form state
    this.$store.commit('pageState/set', {
      pageKey: 'columnsFilter',
      value: this.buildColumnsFilter(),
    });
  }

  private buildColumnsFilter(): ColumnsFilterState {
    // get selected columns from user preferences or defaults
    const columns = useCurrentPageStore().preferences.getColumns('living_donors');

    return {
      selectedColumns: columns,
    };
  }

  get filteredColumns(): any[] {
    if (!this.columnsFilter || !this.columnsFilter.selectedColumns) {
      return this.livingDonorColumns;
    }
    const selectedFields = this.columnsFilter.selectedColumns;
    const selectedColumns = this.livingDonorColumns.filter((column: any) => {
      return selectedFields.includes(column.field);
    });
    return selectedColumns;
  }

  // Sets the search terms and sorting options
  public setSearchParams(searchParams: string) {
    this.searchParams = searchParams;

    this.filterList(null);
  }

  // Sets the search terms and sorting options
  public filterList(event: any) {
    if (event) {
      const sortParams = event?.sortParams;
      if (sortParams) this.sortParams = urlParams(event.sortParams);

      const currentPage = event.currentPage;
      if (currentPage) this.pageNumber = currentPage;
    }

    this.loadData();
  }

  /**
   * Loads recipients list
   * NOTE: custom search parameters and sort options determined by local component state
   */
   public loadData() {
    this.isLoadingEntries = true;
    const search_params = [this.searchParams || '', this.sortParams || ''].filter((p) => { return p && p.length >=0; });
    this.$store.dispatch('livingDonors/getList', {
        pageNumber: this.pageNumber,
        pageSize: this.perPage,
        search_params: `${search_params.length > 0 ? '&' : ''}${search_params.join('&')}`,
      }
    ).then(() => {
      this.isLoadingEntries = false;
    });
  }

  public selectLivingDonor(event: any) {
    if (event.row.client_id !== undefined) {
      const clientId: string = event.row.client_id.toString();
      this.$router.push({
        name: 'edit-living-donor',
        params: {
          id: clientId,
        }
      });
    } else {
      console.warn('Missing living donor client idid');
      this.$router.push({name: 'list-living-donor'});
    }
  }

  get livingDonorColumns(): any[] {
    return [
      {
        label: this.$t('transplant_centre').toString(),
        field: 'transplant_centre',
        sortable: false,
        tdClass: 'nobr',
        width: '150px'
      },
      {
        label: this.$t('client_id').toString(),
        field: 'client_id',
        sortable: true,
        width: '150px'
      },
      {
        label: this.$t('recipient_registration_date').toString(),
        field: 'registration_date',
        sortable: true,
        width: '150px',
        thClass: 'vgt-left-align',
        tdClass: 'vgt-left-align',
      },
      {
        label: this.$t('first_name').toString(),
        field: 'first_name',
        sortable: true,
        width: '200px',
        hidden: !this.isBasicNameEnabled
      },
      {
        label: this.$t('last_name').toString(),
        field: 'last_name',
        sortable: true,
        width: '200px',
        hidden: !this.isBasicNameEnabled
      },
      {
        label: this.$t('dob').toString(),
        field: 'dob',
        type: 'date',
        sortable: false,
        width: '120px',
        thClass: 'vgt-left-align',
        tdClass: 'vgt-left-align',
        hidden: !this.isDateOfBirthEnabled,
      },
      {
        label: this.$t('mrn').toString(),
        field: 'mrn',
        sortable: false,
        width: '120px',
        thClass: 'vgt-left-align',
        tdClass: 'vgt-left-align',
        hidden: !this.isMRNEnabled
      },
      {
        label: this.$t('sex').toString(),
        field: 'sex',
        width: "130px",
        sortable: false,
        hidden: !this.isSexEnabled,
      },
      {
        label: this.$t('blood_type').toString(),
        field: 'blood_type',
        width: "120px",
        sortable: false
      }
    ];
  }

  // Returns true if a filter is applied
  get filteringColumns(): boolean {
    return this.searchParams !== null;
  }

  private get livingDonorTableConfig(): TableConfig {
    return {
      data: this.livingDonorsList,
      columns: this.filteredColumns,
      empty: this.filteringColumns ? this.$t('cannot_find_any_listings_for_filter').toString() : this.$t('no_living_donors_to_display').toString(),
      createButton: false,
      pagination: true,
      sortOptions: {
        enabled: true
      },
      paginationOptions: {
        enabled: true,
        perPage: this.perPage,
        mode: 'pages',
        perPageDropdown: PAGE_SIZES,
        defaultPageSize: DEFAULT_PAGE_SIZE,
        setCurrentPage: this.pageNumber,
        dropdownAllowAll: false,
        nextLabel: '',
        prevLabel: '',
        rowsPerPageLabel: 'Results per page',
        position: 'bottom'
      }
    };
  }

  get livingDonorsList(): LivingDonortListingRow[] {
    if (!this.livingDonors) return [];

    const livingDonors: ListLivingDonor[] = this.livingDonors.entries || [];
    const result = livingDonors.map((livingDonor: ListLivingDonor): LivingDonortListingRow => {
      return {
        transplant_centre: this.buildTransplantCentreValue(livingDonor) || '-',
        registration_date: this.formatDateFromDateTime(livingDonor.registration_date) || '-',
        client_id: livingDonor.client_id,
        first_name: livingDonor.first_name || '-',
        last_name: livingDonor.last_name || '-',
        blood_type: livingDonor.blood_type || '-',
        dob: this.formatDate(livingDonor.dob) || '-',
        mrn: livingDonor.mrn || '-',
        sex: this.buildSexDisplayValue(livingDonor) || '-',
        id: livingDonor.client_id
      };
    });
    return result;
  }

  private buildTransplantCentreValue(livingDonor: ListLivingDonor): string|null {
    const hospitalId = livingDonor.hospital_id;
    if (!hospitalId) return '-';

    const hospitalDisplayValue = this.buildTransplantCentreFilter.find((option: GenericCodeValue) => { return option.code === hospitalId; });
    if (!hospitalDisplayValue) return '-';

    return hospitalDisplayValue.value;
  }

  get buildTransplantCentreFilter(): GenericCodeValue[] {
    if (!this.hospitals) return [];

    const mapped = this.hospitals.map((hospital: Hospital): GenericCodeValue => {
      return {
        code: hospital._id,
        value: hospital.program_identifier,
      };
    });
    return mapped;
  }

  private buildSexDisplayValue(livingDonor: ListLivingDonor): string|null {
    return (this.sexOptions || []).find((sex: Sex) => { return sex.code === livingDonor.sex; })?.value || null;
  }

  /**
   * Parse combined date/time field to be shown in table
   *
   * @param value the datetime property
   * @returns {string} string representation of date
   */
   formatDateFromDateTime(value: string|null): string {
    if (!value) return '-';
    if (isMasked(value)) return `${value}`;

    return this.parseDisplayDateUiFromDateTime(value) || '-';
  }

  /**
   * Parse date-only field to be shown in table
   *
   * @param value the date property
   * @returns {string} string representation of date
   */
   formatDate(value: string|null): string {
    if (!value) return '-';
    if (isMasked(value)) return `${value}`;

    return this.parseDisplayDateUi(value) || '-';
  }

  formatEthnicity(value: number): string|undefined {
    const ethnicity = this.ethnicity.find((item: Ethnicity) => value === item.code);
    return ethnicity ? ethnicity.value : undefined;
  }
}
</script>
