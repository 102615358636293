<template>
  <card-section
    section-id="demographics"
    :lookups-to-load="lookupsToLoad"
    :hospitals-to-load="hospitalsToLoad"
    :disabled="!uiLivingDonor.canSave"
    @loaded="loaded()"
  >
    <template v-slot:header>
      {{$t('demographics')}}
    </template>
    <template v-slot:body v-if="editState">
      <validation-observer ref="validations">
      <form-layout form-id="demographics-form">
        <template v-slot:contents>
          <sub-section 
            :title="$t('registration_information')" 
            sub-section-id="demographics-internal" 
            :disabled="!uiLivingDonor.canSave"
            :hideDivider="true"
            >
            <template v-slot:contents>
              <div class="row">
                <div class="standard-form-group-large" v-if="checkPropExists('transplant_centre.hospital_id')">
                  <select-input
                    ruleKey="transplant_centre.hospital_id"
                    select-id="demographics-personal-transplant-program"
                    :name="$t('transplant_program')"
                    v-model="editState.transplantProgram" 
                    :options="hospitalOptions"
                  />
                </div>
                <div class="standard-form-group" v-if="checkPropExists('transplant_centre.mrn')">
                  <text-input
                    ruleKey="transplant_centre.mrn"
                    inputId="demographics-personal-mrn"
                    :name="$t('mrn')"
                    v-model="editState.mrn"
                  />
                </div>
                <div class="standard-form-group">
                  <text-input
                    inputId="demographics-personal-client_id"
                    :name="$t('client_id')"
                    :disabled="true"
                    v-model="editState.affloId"
                  />
                </div>
                <div class="standard-form-group">
                  <date-input
                    inputId="demographics-personal-registration_date"
                    :name="$t('registration_date')"
                    :disabled="true"
                    v-model="editState.registrationDate"
                  />
                </div>
                <div class="standard-form-group">
                  <text-input
                    inputId="demographics-personal-registration_time"
                    :name="$t('registration_time')"
                    :disabled="true"
                    v-model="editState.registrationTime"
                  />
                </div>
              </div>
            </template>
          </sub-section>
          <sub-section
            :title="$t('personal_information')"
            sub-section-id="demographics-personal"
            :disabled="!uiLivingDonor.canSave"
            v-if="isPersonalInfoEnabled"
            :hideDivider="true"
          >
            <template v-slot:contents>
              <div class="row">
                <div class="standard-form-group" v-if="checkPropExists('patient_profile.first_name')">
                  <text-input
                    ruleKey="patient_profile.first_name"
                    inputId="demographics-personal-firstname"
                    :name="$t('first_name')"
                    v-model="editState.firstName"
                  />
                </div>
                <div class="standard-form-group" v-if="checkPropExists('patient_profile.middle_name')">
                  <text-input
                    ruleKey="patient_profile.middle_name"
                    inputId="demographics-personal-middlename"
                    :name="$t('middle_name')"
                    v-model="editState.middleName"
                  />
                </div>
                <div class="standard-form-group" v-if="checkPropExists('patient_profile.last_name')">
                  <text-input
                    ruleKey="patient_profile.last_name"
                    inputId="demographics-personal-lastname"
                    :name="$t('last_name')"
                    v-model="editState.lastName"
                  />
                </div>
                <div class="standard-form-group" v-if="checkPropExists('patient_profile.preferred_name')">
                  <text-input
                    ruleKey="patient_profile.preferred_name"
                    inputId="demographics-personal-preferred_name"
                    :name="$t('preferred_name')"
                    v-model="editState.preferredName"
                  />
                </div>
                <div class="standard-form-group" v-if="checkPropExists('patient_profile.preferred_pronouns')">
                  <text-input
                    ruleKey="patient_profile.preferred_pronouns"
                    inputId="demographics-personal-preferred_pronouns"
                    :name="$t('preferred_pronouns')"
                    v-model="editState.preferredPronouns"
                  />
                </div>
              </div>
              <div class="row">
                <template  v-if="checkPropExists('patient_profile.birth.date')">
                  <div class="standard-form-group">
                    <date-input
                      ruleKey="patient_profile.birth.date"
                      inputId="demographics-personal-dob"
                      :name="$t('dob')"
                      v-model="editState.dateOfBirth"
                      :minDateLimit="getDobMinDateLimit"
                      :maxDateLimit="getDobMaxDateLimit"
                    />
                  </div>
                  <div class="standard-form-group">
                    <text-input
                      inputId="demographics-personal-age"
                      :name="$t('age')"
                      :disabled="true"
                      :calculated="true"
                      v-model="editState.ageInYears"
                    />
                  </div>
                </template>
                <div class="standard-form-group" v-if="checkPropExists('patient_profile.government_identification.number')">
                  <text-input
                    ruleKey="patient_profile.government_identification.number"
                    inputId="demographics-personal-government_identification_number"
                    :name="$t('government_identification_number')"
                    v-model="editState.governmentIdentificationNumber"
                    :mask="getMaskFromRules('patient_profile.government_identification.number')"
                  />
                </div>
                <div class="standard-form-group" v-if="checkPropExists('patient_profile.race_codes')">
                  <select-input
                    ruleKey="patient_profile.race_codes"
                    selectId="demographics-personal-race"
                    :multiple="true"
                    :disabled="!uiLivingDonor.canSave"
                    :name="$t('race')"
                    v-model="editState.race"
                    :options="raceOptions"
                  />
                </div>
                <select-other-input
                  v-if="checkPropExists('patient_profile.ethnicity_code')"
                  ruleKey="patient_profile.ethnicity_code"
                  select-id="demographics-personal-ethnicity"
                  :name="$t('ethnicity')"
                  validationId="ethnicity_code"
                  v-model="editState.ethnicity"
                  :options="ethnicityLookup"
                  @change="clearEthnicityOther"
                  :otherTitle="$t('ethnicity_other')"
                  :enableOther="showEthnicityOther"
                  colStyling="standard-form-group-with-other"
                  reduceColumnWidth="standard-form-group"
                >
                  <template v-slot:other>
                    <text-input
                      ruleKey="patient_profile.ethnicity_other"
                      input-id="demographics-personal-ethnicityother"
                      :name="$t('ethnicity_other')"
                      v-model="editState.ethnicityOther"
                    />
                  </template>
                </select-other-input>
                <div class="standard-form-group" v-if="checkPropExists('patient_profile.indigenous_identity_codes')">
                  <select-input
                    ruleKey="patient_profile.indigenous_identity_codes"
                    selectId="demographics-personal-indigenous_identity_codes"
                    :multiple="true"
                    :disabled="!uiLivingDonor.canSave"
                    :name="$t('indigenous_identity_codes')"
                    v-model="editState.indigenousIdentityCodes"
                    :options="indigenousIdentityOptions"
                  />
                </div>
              </div>
              <div class="row">
                <div class="standard-form-group" v-if="checkPropExists('patient_profile.sex')">
                  <select-input
                    ruleKey="patient_profile.sex"
                    selectId="demographics-internal-sex"
                    :name="$t('sex')"
                    v-model="editState.sexAtBirth"
                    :options="sexOptions"
                  />
                </div>
                <div class="standard-form-group-large" v-if="checkPropExists('patient_profile.gender_sex_different')">
                  <checkbox-input
                    ruleKey="patient_profile.gender_sex_different"
                    validationId="gender_sex_different"
                    input-id="demographics-personal-gender-different"
                    :labelName="$t('gender_is_diff')"
                    v-model="editState.genderSexDifferent"
                    :label="$t('yes')"
                  />
                </div>
                <select-other-input
                  ruleKey="patient_profile.gender"
                  select-id="demographics-personal-gender"
                  :name="$t('gender')"
                  validationId="gender"
                  v-if="editState.genderSexDifferent && checkPropExists('patient_profile.gender')"
                  v-model="editState.genderIdentity"
                  :options="genderLookup"
                  @change="clearGenderOther"
                  :otherTitle="$t('gender_other')"
                  colStyling="standard-form-group-with-other"
                  reduceColumnWidth="standard-form-group"
                  :enableOther="checkPropExists('patient_profile.gender_other')"
                >
                  <template v-slot:other>
                    <text-input
                      ruleKey="patient_profile.gender_other"
                      input-id="demographics-personal-other-gender"
                      :name="$t('gender_other')"
                      v-model="editState.genderOther"
                    />
                  </template>
                </select-other-input>
              </div>
              <div class="row" v-if="checkAtLeastOnePropExists(['patient_profile.education.highest_education_level', 'patient_profile.education.academic_grade_level'])">
                <div class="standard-form-group" v-if="checkPropExists('patient_profile.education.highest_education_level')">
                  <select-input
                    ruleKey="patient_profile.education.highest_education_level"
                    selectId="demographics-personal-highest_education_level"
                    :name="$t('highest_education_level')"
                    v-model="editState.highestEducationLevel"
                    :options="highestEducationLevelOptions"
                  />
                </div>
                <div class="standard-form-group" v-if="checkPropExists('patient_profile.education.academic_grade_level')">
                  <select-input
                    ruleKey="patient_profile.education.academic_grade_level"
                    selectId="demographics-personal-academic_grade_level"
                    :name="$t('academic_grade_level')"
                    v-model="editState.academicGradeLevel"
                    :options="academicGradeLevelOptions"
                  />
                </div>
              </div>
            </template>
          </sub-section>
        </template>

        <template v-slot:save>
          <save-toolbar
            :show="showSaveToolbar"
            ref="saveDemographics"
            :label="newLivingDonor ? $t('save_living_donor') : $t('save_demographics')"
            @save="savePatch()"
            :cancelButton="true"
            @cancel="cancelPatch()"
          />
        </template>
      </form-layout>
      </validation-observer>
    </template>
  </card-section>
</template>

<script lang="ts">
import { Getter, State } from "vuex-facing-decorator";
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import DateInput from "@/components/shared/DateInput.vue";
import TextInput from "@/components/shared/TextInput.vue";
import SubSection from "@/components/shared/SubSection.vue";
import CardSection from "@/components/shared/CardSection.vue";
import SaveToolbar from "@/components/shared/SaveToolbar.vue";
import SelectInput from "@/components/shared/SelectInput.vue";
import CheckboxInput from "@/components/shared/CheckboxInput.vue";
import { SaveResult } from "@/types";
import { Component, Prop } from "vue-facing-decorator";
import SelectOtherInput from "@/components/shared/SelectOtherInput.vue";
import { LivingDonor, LivingDonorValidations } from "@/store/livingDonors/types";
import { Ethnicity, Sex, Gender, Province } from "@/store/lookups/types";
import { GenericCodeValue } from "@/store/types";
import FormLayout from '@/components/shared/FormLayout.vue';
import { i18nMessages } from "@/i18n";
import { parseFormErrors } from '@/utils';
import { useCurrentPageStore } from "@/stores/currentPage";
import { UILivingDonorDemographics } from "@/UIModels/livingDonors/demographics";
import { UILivingDonor } from "@/UIModels/livingDonor";
import { Hospital } from "@/store/hospitals/types";

@Component({
  components: {
    TextInput,
    DateInput,
    SubSection,
    CardSection,
    FormLayout,
    SaveToolbar,
    SelectInput,
    CheckboxInput,
    SelectOtherInput,
  },
  ...i18nMessages([
    require('./../_locales/common.json'),
    require('./_locales/common.json'),
    require('@/components/livingDonors/_locales/Demographics.json'),
    require('@/views/_locales/common.json'),
  ]),
  emits: [
    'loaded',
  ],
})
export default class LivingDonorDemographics extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.lookups.province) provinceLookup!: Province[];
  @State(state => state.lookups.ethnicity) ethnicityLookup!: Ethnicity[];
  @State(state => state.livingDonors.selectedLivingDonor.validations) validations!: LivingDonorValidations;
  @State(state => state.lookups.gender) genderLookup!: Gender[];
  @State(state => state.lookups.race) raceOptions!: GenericCodeValue[];
  @State(state => state.lookups.indigenous_identity) indigenousIdentityOptions!: GenericCodeValue[];
  @State(state => state.lookups.highest_education_level) highestEducationLevelOptions!: GenericCodeValue[];
  @State(state => state.lookups.academic_grade_level) academicGradeLevelOptions!: GenericCodeValue[];
  @State(state => state.hospitals.all) hospitals!: Hospital[];

  // Getters
  @Getter('getDobMinDateLimit', { namespace: 'lookups' }) private getDobMinDateLimit!: string;
  @Getter('getDobMaxDateLimit', { namespace: 'lookups' }) private getDobMaxDateLimit!: string;
  @Getter("show", { namespace: "livingDonor" }) private livingDonor!: LivingDonor;
  @Getter("sexOptions", { namespace: "lookups" }) sexOptions!: Sex[];
  @Getter('mustBeBlank', { namespace: "validations" }) private mustBeBlank!: (ruleKey: string) => boolean;
  @Getter('defaultLookup', { namespace: 'lookups' }) defaultLookup!: (lookupId: string) => any;
  @Getter('checkPropExists', { namespace: 'validations' }) checkPropExists!: (ruleKey: string) => boolean;
  @Getter('getMaskFormatType', { namespace: 'lookups' }) getMaskFormatType!: (code?: number|null) => string;
  @Getter('checkAtLeastOnePropExists', { namespace: 'validations' }) checkAtLeastOnePropExists!: (ruleKeys: string[]) => boolean;
  @Getter('getMaskFromRules', { namespace: 'validations' }) private getMaskFromRules!: (ruleKey: string) => string|null;

  // Properties
  @Prop({ default: false }) newLivingDonor!: boolean;

  // Immutable view model based on current recipient (e.g for cancel)
  private selection: UILivingDonorDemographics = new UILivingDonorDemographics();

  // Editable view model for the form
  private editState: UILivingDonorDemographics = this.selection.copyViewModel();

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = [
    "ethnicity",
    "sex",
    "gender",
    "country",
    "race",
    "indigenous_identity",
    "highest_education_level",
    "academic_grade_level",
  ];

  // Hospitals to be loaded by the CardSection component
  public hospitalsToLoad = ['all'];

  get hospitalOptions(): GenericCodeValue[] {
    if (!this.hospitals) return [];

    const mapped = this.hospitals.map((hospital: Hospital): GenericCodeValue => {
      return {
        code: hospital._id,
        value: `${hospital.program_identifier} - ${hospital.hospital_name_info?.name}`,
      };
    });
    return mapped;
  }

  get isPersonalInfoEnabled(): boolean {
    return useCurrentPageStore().configuration.features.livingDonorConfig.demographics.personalInfo.enabled;
  }

  get showEthnicityOther(): boolean {
    return this.checkPropExists('patient_profile.ethnicity_other');
  }

  get showSaveToolbar(): boolean {
    if (!this.uiLivingDonor.canSave) return false;
    return (this.isPersonalInfoEnabled);
  }

  get uiLivingDonor(): UILivingDonor {
    return useCurrentPageStore().currentLivingDonor as UILivingDonor;
  }

  // Initialize the form
  public initializeForm(): void {
    this.selection = new UILivingDonorDemographics(this.uiLivingDonor.apiSource);
    this.editState = this.selection.copyViewModel();
  }

  // Clear ethnicity other when ethnicity changes
  public clearEthnicityOther() {
    this.editState.ethnicityOther = '';
  }

  // Clear gender other when gender changes
  public clearGenderOther() {
    this.editState.genderOther = '';
  }

  // Triggered when all the lookups have been loaded
  // NOTE: this ensure we initialize the form AFTER lookups are loaded by the sub-section
  public loaded(): void {
    this.initializeForm();
    this.$emit('loaded', 'demographics');
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save toolbar that handle the areas present on this form component
    this.saveToolbar.reset();
  }

  get saveToolbar(): SaveToolbar {
    return this.$refs.saveDemographics as unknown as SaveToolbar;
  }

  public cancelPatch(): void {
    this.initializeForm();
  }

  // Handle saving triggered by local save button
  public savePatch(): void {
    // Refer to the save toolbar that handles this form area
    this.saveToolbar.startSaving();
    // Dispatch save action and register the response
    if (this.newLivingDonor) {
      this.createLivingDonor();
    } else {
      this.updateLivingDonor();
    }
  }

  // Create new recipient
  private async createLivingDonor(): Promise<void> {
    try {
      // Attempt to create the recipient
      const success: SaveResult = await this.editState.save({ selected: this.selection, livingDonor: this.uiLivingDonor });
      // Get the Client ID assigned to the new recipient
      const clientId = success.responseData.living_donor.client_id;
      // Navigate to recipient edit
      this.$router.push({ name: 'edit-living-donor', params: { id: clientId } });
    } catch (error: unknown) {
      // Handle errors directly in this form component
      this.handleErrors(error as SaveResult);
      this.saveToolbar.stopSaving(error as SaveResult);
    }
  }

  // Update demographics information for existing recipient
  private async updateLivingDonor(): Promise<void> {
    try {
      // Attempt to update the recipient
      const success: SaveResult = await this.editState.save({ selected: this.selection, livingDonor: this.uiLivingDonor });
      // If successful, update the current recipient and show success notification
      this.selection = this.uiLivingDonor.demographics || new UILivingDonorDemographics();
      this.editState = this.selection.copyViewModel();
      this.saveToolbar.stopSaving(success as SaveResult);
    } catch (error: unknown) {
      // Handle errors directly in this form component
      this.handleErrors(error as SaveResult);
      this.saveToolbar.stopSaving(error as SaveResult);
    }
  }

  // Process error save result
  private handleErrors(errors: SaveResult): void {
    // Derive errors for UI input fields based on API error results
    const formErrors: any = parseFormErrors(errors, this.idLookup);

    // inject api errors into vee-validate
    const validationObserver = this.$refs.validations as any;
    if (validationObserver) validationObserver.setErrors(formErrors);
  }

  // API response keys on the left, id for our UI on the right
  public get idLookup(): {[key: string]: string} {
    const result = {
      "livingDonor_demographics.patient_profile.first_name"                        : "demographics-personal-firstname",
      "livingDonor_demographics.patient_profile.middle_name"                       : "demographics-personal-middlename",
      "livingDonor_demographics.patient_profile.last_name"                         : "demographics-personal-lastname",
      "livingDonor_demographics.patient_profile.preferred_name"                    : "demographics-personal-preferred_name",
      "livingDonor_demographics.patient_profile.preferred_pronouns"                : "demographics-personal-preferred_pronouns",
      "livingDonor_demographics.transplant_centre.hospital_id"                     : "demographics-personal-transplant-program",
      "livingDonor_demographics.transplant_centre.mrn"                             : "demographics-personal-mrn",
      "livingDonor_demographics.patient_profile.government_identification.number"  : "demographics-personal-government_identification_number",
      "livingDonor_demographics.patient_profile.sex"                               : "demographics-internal-sex",
      "livingDonor_demographics.patient_profile.gender_sex_different"              : "demographics-personal-gender-different",
      "livingDonor_demographics.patient_profile.race_codes"                        : "demographics-personal-race",
      "livingDonor_demographics.patient_profile.ethnicity_code"                    : "ethnicity_code",
      "livingDonor_demographics.patient_profile.ethnicity_other"                   : "demographics-personal-ethnicityother",
      "livingDonor_demographics.patient_profile.indigenous_identity_codes"         : "demographics-personal-indigenous_identity_codes",
      "livingDonor_demographics.patient_profile.gender"                            : "demographics-personal-gender",
      "livingDonor_demographics.patient_profile.gender_other"                      : "demographics-personal-other-gender",
      "livingDonor_demographics.patient_profile.birth.date"                        : "demographics-personal-dob",
      "livingDonor_demographics.patient_profile.education.highest_education_level" : "demographics-personal-highest_education_level",
      "livingDonor_demographics.patient_profile.education.academic_grade_level"    : "demographics-personal-academic_grade_level",
    };
    return result;
  }
}
</script>
