import { APILivingDonor } from '@/APIModels/livingDonors/types';
import { APIRoute, EP } from '@/api-endpoints';
import axios from 'axios';
import { APINewResonse, APIShowLivingDonorData, APIPermittedActions, APIRules, APIShowResponse } from '@/types';
import vuexStore from '@/store'; // for gradual conversion, see fullUserDetails
import { UILivingDonorSummary } from '@/UIModels/livingDonors/livingDonorSummary';
import { useCurrentPageStore } from '@/stores/currentPage';
import { DATA_BUNDLES } from './configuration/features';
import { UILivingDonorDemographics } from '@/UIModels/livingDonors/demographics';
import { UISaveable } from './saveable';
import { UILivingDonorBloodType } from '@/UIModels/livingDonors/livingDonorBloodType';
import { UILivingDonorMeasurement } from '@/UIModels/livingDonors/livingDonorMeasurements';

export function buildDisplayName(apiLivingDonor: APILivingDonor): string {
  if (!useCurrentPageStore().configuration.features.livingDonorConfig.demographics.bundles.isEnabled(DATA_BUNDLES.PatientNameBasic)) return `${apiLivingDonor.client_id}`;
  return [apiLivingDonor.patient_profile?.first_name, apiLivingDonor.patient_profile?.last_name].join(' ');
}

export class UILivingDonor extends UISaveable {
  public apiSource?: APILivingDonor;
  public clientId?: string;
  public displayName = '';
  private loaded: boolean;

  public summary: UILivingDonorSummary|null = null;
  public demographics: UILivingDonorDemographics|null = null;
  public bloodType: UILivingDonorBloodType|null = null;
  public livingDonorMeasurements: UILivingDonorMeasurement[] = [];

  get isNew(): boolean {
    return !this.clientId;
  }

  public constructor(clientId?: string) {
    super();
    this.clientId = clientId;
    this.loaded = false;
  }

  // Load resource data and permitted actions
  public async load(opts?: { reload: boolean }): Promise<void> {
    if (this.clientId) {
      await this.loadShow(opts);
    } else {
      await this.loadNew(opts);
    }
  }

  public async loadNew(opts?: { reload: boolean }): Promise<void> {
    const url = APIRoute(EP.living_donors.new);
    try {
      const response: APINewResonse = await axios.get(url);
      this.permittedActions = response.data.permitted_actions;
      this.setRules(response.data.rules);
      this.loaded = true;
    } catch (error: unknown) {
      this.loaded = true;
      console.warn(error);
    }
  }

  /**
   * Load everything for the Living Donor
   * NOTE: does NOT request new data if already loaded
   */
  private async loadShow(opts?: { reload: boolean }): Promise<void> {
    if (opts?.reload) this.loaded = false;

    if (!this.loaded) {
      const clientId = this.clientId;
      if (!clientId) return;

      const url = APIRoute(EP.living_donors.show, [[':id', (clientId as string)]]);
      try {
        const response: APIShowResponse<APIShowLivingDonorData> = await axios.get(url);
        const apiLivingDonor: APILivingDonor = response?.data?.living_donor;
        this.permittedActions = response.data.permitted_actions;
        this.setRules(response.data.rules);
        this.updateFromAPIResponse(apiLivingDonor);
        this.buildBloodTypeFromAPILivingDonor();
        this.livingDonorMeasurements = await UILivingDonorMeasurement.loadFor(this);
        this.demographics = new UILivingDonorDemographics(apiLivingDonor);
        this.buildSummaryFromAPILivingDonor();
        this.loaded = true;
      } catch (error: unknown) {
        this.loaded = true;
        console.warn(error);
      }
    }
  }

  private setRules(rules: APIRules): void {
    vuexStore.commit('validations/resetPrefix', 'patient_profile');
    vuexStore.commit('validations/set', { rules: rules });
  }

  // Map from API data structure to UI model structure
  public updateFromAPIResponse(apiLivingDonor: APILivingDonor) {
    this.apiSource = apiLivingDonor;
    this.clientId = apiLivingDonor.client_id?.toString() || '';
    this.displayName = buildDisplayName(apiLivingDonor);

    // NOTE: If we have the entire living donor document then we are done loading here
    this.loaded = true;
  }

  private buildSummaryFromAPILivingDonor() {
    this.summary = new UILivingDonorSummary(this.apiSource || undefined);
  }

  private buildBloodTypeFromAPILivingDonor() {
    const apiBloodType = this.apiSource?.blood;
    this.bloodType = apiBloodType ? new UILivingDonorBloodType(apiBloodType) : new UILivingDonorBloodType();
  }
}
