import { SaveResult } from '@/types';
import axios from 'axios';
import { APIRoute, EP } from '@/api-endpoints';
import { UIError } from '@/UIModels/error';
import { UISuccess } from '@/UIModels/success';
import { APISaveBloodTypeResponse } from '@/types';
import { UIBloodType } from '@/UIModels/bloodType';
import { UILivingDonor } from '../livingDonor';

export class UILivingDonorBloodType extends UIBloodType {
  // Save edit state to the backend
  public save(opts: { selected: UILivingDonorBloodType|null, livingDonor: UILivingDonor }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const livingDonorId = opts.livingDonor.clientId;
      if (!livingDonorId) reject((new UIError('living_donor')));

      const ep = APIRoute(EP.living_donors.update, [[':id', livingDonorId as string]]);
      const payload = {
        blood: this.extractPatch()
      };
      axios.patch(ep, payload).then((response: APISaveBloodTypeResponse) => {
        if (response.data.errors) {
          reject((new UIError('living_donor', response)).errorResult);
        } else {
          // Success! We may need to update the current page
          /// NOTE: here we simply call living-donor-level reload to handle everything
          opts.livingDonor.load({ reload: true }).then(() => {
            resolve((new UISuccess(response)).getSaveResult());
          });
        }
      }).catch((errorResponse: any) => {
        reject((new UIError('living_donor', errorResponse)).errorResult);
      });
    });
  }  
}
