<template>
  <!-- blood type -->
  <sub-section
    :title="$t('blood_type_title')"
    sub-section-id="gci_blood_type"
    ref="blood_type">
    <template v-slot:contents>
      <validation-observer ref="validations">
      <form-layout
        :disabled="!uiLivingDonor.canSave"
        form-id="blood_type_form">
        <template v-slot:contents>
          <div class="row">
            <div class="standard-form-group">
              <select-input
                ruleKey="blood.type"
                selectId="blood.type"
                :name="$t('blood.type')"
                v-model="editState.type"
                :options="bloodTypeLookupFiltered"
                @change="editState.clearSubType()"
              />
            </div>
            <div class="standard-form-group" v-if="bloodSubTypeLookup(editState.type).length > 0">
              <select-input
                ruleKey="blood.sub_type"
                selectId="blood.sub_type"
                :name="$t('blood.sub_type')"
                :crossValues="{ type: editState.type }"
                v-model="editState.sub_type"
                :options="bloodSubTypeLookup(editState.type)"
              />
            </div>
            <div class="standard-form-group">
              <select-input
                ruleKey="blood.rh_indicator"
                selectId="blood.rh_indicator"
                :name="$t('blood.rh_indicator')"
                v-model="editState.rh_indicator"
                :options="rhIndicatorLookup"
              />
            </div>
          </div>
        </template>

        <template v-slot:save>
          <save-toolbar
            :show="uiLivingDonor.canSave"
            ref="saveBloodType"
            class="card-footer action-row temp-saving row"
            :label="$t('save_blood_type')"
            :cancelButton="true"
            @save="handleSave()"
            @cancel="handleCancel()"
          />
        </template>      
      </form-layout>      
      </validation-observer>
    </template>
  </sub-section>
</template>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter } from 'vuex-facing-decorator';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import CardSection from '@/components/shared/CardSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import HiddenInput from '@/components/shared/HiddenInput.vue';
import { SaveResult } from "@/types";
import { Component } from 'vue-facing-decorator';
import { BloodType, SubBloodType, RhIndicator } from '@/store/lookups/types';
import FormLayout from '@/components/shared/FormLayout.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import { UIError } from '@/UIModels/error';
import { IdLookupProvider } from '@/types';
import { UILivingDonorBloodType } from '@/UIModels/livingDonors/livingDonorBloodType';
import { UILivingDonor } from '@/UIModels/livingDonor';
import { i18nMessages } from "@/i18n";
import { parseFormErrors } from '@/utils';

@Component({
  components: {
    TextInput,
    DateInput,
    SubSection,
    CardSection,
    SaveToolbar,
    SelectInput,
    NumberInput,
    CheckboxInput,
    HiddenInput,
    FormLayout
  },
  ...i18nMessages([
    require('@/components/livingDonors/_locales/GeneralClinicalInformation.json'),
    require('@/components/_locales/common.json'),
  ]),
})
export default class BloodTypeForm extends mixins(DateUtilsMixin) implements IdLookupProvider {
  @Getter('blood_type', { namespace: 'lookups' }) private bloodTypeLookup!: BloodType[];
  @Getter('rh_indicator', { namespace: 'lookups' }) private rhIndicatorLookup!: RhIndicator;

  private isLoading = false;

  // Immutable view model based on current Living Donor (e.g for cancel)
  private selection = new UILivingDonorBloodType();

  // Editable view model for the form
  private editState = new UILivingDonorBloodType(this.selection);

  private get bloodTypeLookupFiltered(): BloodType[] {
    if (!this.bloodTypeLookup) return [];
    return this.bloodTypeLookup.filter((item: BloodType) => { return item.living_donor === true; });
  }

  private bloodSubTypeLookup(type: string|null): SubBloodType[] {
    if (!type) return [];
    const currentBloodType = this.bloodTypeLookupFiltered.find((bloodType: BloodType) => bloodType.value == type)
    return currentBloodType && currentBloodType.sub_tables ? currentBloodType.sub_tables.sub_types : []
  }

  get uiLivingDonor(): UILivingDonor {
    return useCurrentPageStore().currentLivingDonor as UILivingDonor;
  }

  mounted(): void {
    if (this.uiLivingDonor) {
      this.uiLivingDonor.load().then(() => {
        const uiBlood = this.uiLivingDonor.bloodType;
        if (uiBlood) this.selection = new UILivingDonorBloodType(uiBlood);
        this.initializeForm();
        this.isLoading = false;
      }).catch((uiError: UIError) => {
        this.isLoading = false;
        console.warn('Something unexpected happened when attempting to get blood type information', uiError);
      });
    }
  }

  // Process save button click event
  private async handleSave(): Promise<void> {
    if (this.saveToolbar) this.saveToolbar.startSaving();
    if (!this.uiLivingDonor) return;

    const params = {
      selected: this.editState,
      livingDonor: this.uiLivingDonor,
    };

    try {
      const success: SaveResult = await this.editState.save(params);
      this.handleSuccess(success);
    } catch (error: unknown) {
      this.handleErrors(error as SaveResult);
    }
  }

  // Process successful save result
  private handleSuccess(success: SaveResult): void {
    if (this.saveToolbar) this.saveToolbar.stopSaving(success);

    // re-load data
    const uiBlood = this.uiLivingDonor.bloodType || null;
    if (uiBlood) this.selection = new UILivingDonorBloodType(uiBlood);
  }

  // Process error save result
  private handleErrors(errors: SaveResult): void {
    // Derive errors for UI input fields based on API error results
    const formErrors: any = parseFormErrors(errors, this.idLookup());

    // inject api errors into vee-validate
    const validationObserver = this.$refs.validations as any;
    if (validationObserver) validationObserver.setErrors(formErrors);

    if (this.saveToolbar) this.saveToolbar.stopSaving(errors);
  }

  // Reset edit state based on selected address for cancel button click event
  private handleCancel(): void {
    this.initializeForm();
    this.resetSaveToolbar();
  }

  /**
   * Populates the Living Donor Blood Type form state with data from the selected Living Donor.
   */
   public initializeForm(): void {
    this.editState = new UILivingDonorBloodType(this.selection);
  }

  // Clears all save notifications shown by the form.
  public resetSaveToolbar(): void {
    this.saveToolbar.reset();
  }
  
  // Reference to the form's save toolbar
  get saveToolbar(): SaveToolbar {
    return this.$refs.saveBloodType as unknown as SaveToolbar;
  }
 
  public idLookup(): {[key: string]: string} {
    const result = {
      'living_donor.blood.type'                  : 'blood.type',
      'living_donor.blood.sub_type'              : 'blood.sub_type',
      'living_donor.blood.rh_indicator'          : 'blood.rh_indicator',
      // For validations
      'blood.type'                   : 'blood.type',
      'blood.sub_type'               : 'blood.sub_type',
      'blood.rh_indicator'           : 'blood.rh_indicator',
    };
    return result;
  }
}
</script>
