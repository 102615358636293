import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "card summary-card"
}
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "media align-items-center" }
const _hoisted_4 = { class: "media-left" }
const _hoisted_5 = { class: "media-body flex-row align-items-center" }
const _hoisted_6 = { class: "summary-name" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "nav card-nav" }
const _hoisted_9 = { class: "nav-item" }
const _hoisted_10 = { class: "card-body" }
const _hoisted_11 = { class: "summary-list" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 2 }
const _hoisted_15 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_summary_card = _resolveComponent("loading-summary-card")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (!_ctx.uiLivingDonorSummary)
    ? (_openBlock(), _createBlock(_component_loading_summary_card, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['far', 'id-card'],
                "fixed-width": ""
              })
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h3", _hoisted_6, [
                _createTextVNode(_toDisplayString(_ctx.uiLivingDonorSummary.summaryName) + " ", 1),
                (_ctx.uiLivingDonorSummary.summaryStatus)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t(_ctx.uiLivingDonorSummary.summaryStatus)), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("ul", _hoisted_8, [
                _createElementVNode("li", _hoisted_9, [
                  _createVNode(_component_router_link, {
                    to: { name: 'edit-living-donor', params: { id: _ctx.uiLivingDonorSummary.clientId } },
                    class: "nav-link"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('living_donor_profile')), 1)
                    ]),
                    _: 1
                  }, 8, ["to"])
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("dl", null, [
              _createElementVNode("dt", null, _toDisplayString(_ctx.$t('client_id')), 1),
              _createElementVNode("dd", null, _toDisplayString(_ctx.uiLivingDonorSummary.clientId), 1)
            ]),
            (_ctx.isDateOfBirthEnabled)
              ? (_openBlock(), _createElementBlock("dl", _hoisted_12, [
                  _createElementVNode("dt", null, _toDisplayString(_ctx.$t('dob')), 1),
                  _createElementVNode("dd", null, _toDisplayString(_ctx.uiLivingDonorSummary.dateOfBirth || '-'), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.isDateOfBirthEnabled)
              ? (_openBlock(), _createElementBlock("dl", _hoisted_13, [
                  _createElementVNode("dt", null, _toDisplayString(_ctx.$t('age')), 1),
                  _createElementVNode("dd", null, _toDisplayString(_ctx.uiLivingDonorSummary.age != null ? _ctx.uiLivingDonorSummary.age : '-'), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.isSexEnabled)
              ? (_openBlock(), _createElementBlock("dl", _hoisted_14, [
                  _createElementVNode("dt", null, _toDisplayString(_ctx.$t('sex')), 1),
                  _createElementVNode("dd", null, _toDisplayString(_ctx.uiLivingDonorSummary.sex || '-'), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.isBloodTypeEnabled)
              ? (_openBlock(), _createElementBlock("dl", _hoisted_15, [
                  _createElementVNode("dt", null, _toDisplayString(_ctx.$t('abo')), 1),
                  _createElementVNode("dd", null, _toDisplayString(_ctx.uiLivingDonorSummary.bloodType || '-'), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
}