<template>
  <!-- measurements type -->
  <sub-section
    :title='$t("measurements_title")'
    sub-section-id="gci-measurements"
    ref="measurements">
    <template v-slot:contents>
      <!-- measurements content -->

      <!-- Filter Bar + Create Button -->
      <table-toolbar
        :createButton="uiRecipient.canSave"
        :createText="$t('create_measurement')"
        @table-create-row="handleTableCreateRow"
      />

      <!-- List of Items, or History List -->
      <table-list
        ref="patientMeasurementsTable"
        table-id="patient-measurements-table"
        :table-config="patientMeasurementsTableConfig"
        @table-row-click="handleTableRowClick"
        :highlightSelection="true"
        :firstColumnTabbable="true"
        :rowStyleClass="rowStyleClass"
      />

      <measurements-form
        ref="measurementsForm"
        :selection="selection"
        @success="handleSuccess"
      />
    </template>
  </sub-section>
</template>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { State } from 'vuex-facing-decorator';
import SubSection from '@/components/shared/SubSection.vue';
import CardSection from '@/components/shared/CardSection.vue';
import { Component, Prop } from 'vue-facing-decorator';
import { RecipientValidations } from '@/store/recipients/types';
import { useCurrentPageStore } from '@/stores/currentPage';
import { UIRecipient } from '@/UIModels/recipient';
import { UIRecipientMeasurement } from "@/UIModels/recipients/recipientMeasurements";
import TableToolbar from '@/components/shared/TableToolbar.vue';
import TableList from '@/components/shared/TableList.vue';
import { i18nMessages } from "@/i18n";
import { UIListFormSelection } from '@/UIModels/listFormSelection';
import MeasurementsForm from "@/components/recipients/generalClinical/MeasurementsForm.vue";
import { SaveResult } from '@/types';

interface TableRow {
  id: string;
  date: string;
  height: string;
  weight: string;
  bmi: string;
}

// What are all the possible per-page sizes allowed in the table list pagination bar?
// NOTE: The list displays 10 records by default, with pagination (AP-1490)
// See: https://shorecg.atlassian.net/wiki/spaces/AP/pages/1581383697/Paginated+Table
// TODO: TECH_DEBT: can page sizes be moved to application level somehow
const PAGE_SIZES = [5, 10, 25];
const DEFAULT_PAGE_SIZE = PAGE_SIZES[1]; // 10

@Component({
  components: {
    SubSection,
    CardSection,
    TableList,
    TableToolbar,
    MeasurementsForm
  },
  ...i18nMessages([
    require('@/components/recipients/_locales/GeneralClinicalInformation.json'),
    require('@/components/_locales/common.json'),
  ]),
})
export default class MeasurementsSection extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.recipients.selectedRecipient.validations) validations!: RecipientValidations;

  // Selection instance
  private selection = new UIListFormSelection();

  // Editable view model for the form
  private editState = new UIRecipientMeasurement();

  get uiRecipient(): UIRecipient {
    return useCurrentPageStore().currentRecipient as UIRecipient;
  }

  // Figure out if we need any extra style class based on the row data
  private rowStyleClass(row: any): string|null {
    if (!row) return null;
    return null;
  }

  get getRecords(): UIRecipientMeasurement[] {
    return this.uiRecipient.recipientMeasurements || [];
  }

  // Configure the list table
  get patientMeasurementsTableConfig(): TableConfig {
    return {
      data: this.tableRows,
      columns: [
        { label: this.$t('measurement_date').toString(), field: 'date' },
        { label: this.$t('weight').toString(), field: 'weight', class: 'small-width-column' },
        { label: this.$t('height').toString(), field: 'height', class: 'small-width-column' },
        { label: this.$t('bmi').toString(), field: 'bmi', class: 'x-small-width-column' },
      ],
      empty: this.uiRecipient.canSave ? this.$t('use_form_below').toString() : this.$t('measurements_table_empty').toString(),
      sortOptions: {
        enabled: false,
        initialSortBy: [{ field: 'measurement_date', type: 'desc' }]
      },
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPageDropdown: PAGE_SIZES,
        defaultPageSize: DEFAULT_PAGE_SIZE,
        dropdownAllowAll: false,
        position: 'bottom'
      }
    };
  }

  // How many measurement items does this Patient have based on the current filter assignments?
  // NOTE: make sure to do filtering before counting records
  get totalRecords(): number {
    if (!this.getRecords) return 0;

    return this.getRecords.length;
  }

  // Prepare recipient attachments for presentation in the historical tables.
  get tableRows(): TableRow[] {
    const rows: TableRow[] = this.getRecords.map((uiModel: UIRecipientMeasurement) => {
      return {
        id: uiModel.id || '',
        date: this.parseDisplayDateUiFromDateTime(uiModel.date) || '-',
        height: uiModel.height_cm_rounded == null ? '-' : `${uiModel.height_cm_rounded} ${this.$t('cm')} (${uiModel.height_in_rounded} ${this.$t('in')})`,
        weight: uiModel.weight_kg_rounded == null ? '-' : `${uiModel.weight_kg_rounded} ${this.$t('kg')} (${uiModel.weight_lbs_rounded} ${this.$t('lbs')})`,
        bmi: uiModel.bmi == null ? '-' : uiModel.bmi.toString(),
      };
    });
    return rows;
  }

  get patientMeasurementsTable(): TableList {
    return this.$refs.patientMeasurementsTable as TableList;
  }

  // Clear selection
  private handleSuccess(_success: SaveResult): void {
    this.patientMeasurementsTable.resetSelection();
    this.selection = new UIListFormSelection();
  }

  private handleTableCreateRow(): void {
    this.patientMeasurementsTable.resetSelection();
    this.selection = new UIListFormSelection();
  }

  // Select an address UI Model List item from the list based on a row click event
  private handleTableRowClick(event: { row: TableRow }): void {
    const uiMeasurements: UIRecipientMeasurement[] = this.uiRecipient?.recipientMeasurements || [];
    const listItem = uiMeasurements.find((listItem: UIRecipientMeasurement) => { return listItem.id == event.row.id; });
    if (!listItem) return;

    this.selection = new UIListFormSelection(listItem.id);
  }
}
</script>
