<template>
  <validation-observer id="perform-specified-vxm" ref="performSpecifiedVxmValdiations" autocomplete="off" v-slot="{ handleSubmit }">
    <!-- Perform Specified VXM controls -->
    <sub-section
      :title="$t('perform_specified_vxm')"
      sub-section-id="hla-perform-specified-vxm-controls"
      class="view-virtual-crossmatch"
      v-if="checkAllowed('/recipients/:recipient_id/history_vxm', 'POST')"
    >
      <template v-slot:contents>
        <nav class="nav action-row mt-0">
          <save-toolbar
            buttonClass="btn btn-wide btn-primary mt-0"
            ref="performSpecificVxmSaveButton"
            :savingText="$t('generating_vxm_results')"
            :successText="$t('vxm_results_generated')"
            :defaultErrorText="$t('problem_generating_vxm')"
            @save="openModal"
            :disabled="saving"
            :label="$t('perform_specified_vxm')"
          />
        </nav>
      </template>
    </sub-section>
    <!-- Perform Specified VXM dialogue box -->
    <modal-section
      modal-id="perform-specified-vxm-dialogue-box"
      ref="performSpecifiedVxmModal"
      class="modal-sticky-header"
      :centered="true"
    >
      <template v-slot:title>
        {{$t('perform_specified_vxm')}}
      </template>
      <template v-slot:body>
        <template v-if="!editState">
          {{$t('loading')}}
        </template>
        <template v-else>
          <div class="row">
            <div class="form-group col-sm-4">
              <text-input
                input-id="perform-specified-vxm-recipient-id"
                :name="$t('recipient_id')"
                :disabled="recipientId"
                v-model="editState.recipientId"
                rules="required"
              />
            </div>
            <div class="form-group col-sm-4">
              <select-input
                select-id="perform-specified-vxm-donor-type"
                class="form-group"
                :name="$t('donor_type')"
                v-model="editState.donorType"
                rules="required"
                :options="donorTypes"
              />
            </div>
            <div class="form-group col-sm-4">
              <validation-provider
                :rules="{ no_invalid_tags: { tags: editState.donorIdTags }, requires_tags: { tags: editState.donorIdTags } }"
                v-slot="{ errors }"
                :name="$t('donor_id')"
                vid="perform-specified-vxm-donor-id"
                class="generic-tags"
              >
                <label for="perform-specified-vxm-donor-id">
                  {{$t('donor_id')}}
                  <span><i>*</i></span>
                </label>
                <vue-tags-input
                  id="perform-specified-vxm-donor-id"
                  placeholder=""
                  :add-on-key="[13, ' ']"
                  :allow-edit-tags="false"
                  :autocomplete-min-length="1"
                  :max-tags="1"
                  :separators="[' ']"
                  :validation="validateDonorIdTag()"
                  v-model="editState.donorIdInput"
                  :autocomplete-items="filteredDonorIdAutocompleteOptions"
                  @before-adding-tag="beforeDonorIdChanged"
                  @tags-changed="onDonorIdChanged"
                  :tags="editState.donorIdTags"
                  :class="{ 'is-invalid': errors[0] }"
                />
                <div class="invalid-feedback" v-if="errors[0]">
                  <span v-for="(error, index) in errors" :key="`perform-specified-vxm-donor-id-error-${index}`">
                    <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width /> {{ error }}
                  </span>
                </div>
              </validation-provider>
            </div>
          </div>
        </template>
      </template>
      <template v-slot:footer>
        <div class="modal-footer-body">
          <button type="button" data-dismiss="modal" class="btn btn-secondary">
            {{$t('cancel')}}
          </button>
          <a class="btn btn-success" @click="handleSubmit(confirmSpecifiedVxm)">
            {{$t('confirm')}}
          </a>
        </div>
      </template>
    </modal-section>
  </validation-observer>
</template>

<script lang="ts">
import { urlParams } from '@/utils';
import { SaveResult } from '@/types';
import { State, Getter }  from 'vuex-facing-decorator';
import TextInput from '@/components/shared/TextInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator';
import { StateLivingDonorsList, ListLivingDonor } from '@/store/livingDonors/types';
import { ListDonor, StateDonorList } from '@/store/deceasedDonors/types';
import { VueTagsInput, createTags } from '@vojtechlanka/vue-tags-input';
import { i18nMessages } from '@/i18n';

interface PerformSpecifiedVxmState {
  recipientId: string|null;
  donorId: string|null;
  donorType: string;
  // Variables for tag inputs
  donorIdInput: string;
  donorIdTags: { text: string, tiClasses: string[] }[];
}

// Constants related to recipient/donor ID searching
const INDEX_PAGE_SIZE = 25;
const INDEX_PAGE = 1;
const LIVING_DONOR_KEY = 'living_donor_id';
const DECEASED_DONOR_KEY = 'deceased_donor_id';

@Component({
  components: {
    TextInput,
    SelectInput,
    SubSection,
    SaveToolbar,
    ModalSection,
    VueTagsInput,
  },
  ...i18nMessages([
    require('@/components/hla/_locales/HlaPerformSpecifiedVirtualCrossmatch.json'),
    require('@/components/hla/_locales/common.json'),
  ]),
})
export default class HlaPerformSpecifiedVirtualCrossmatch extends Vue {
  @Prop({ required: false }) recipientId?: string; // Locks Recipient ID selection
  @Prop({ required: false }) donorId?: string; // Locks Donor ID selection
  @Prop({ required: false }) donorType?: string; // Locks Donor Type selection

  @State(state => state.deceasedDonors.donorsList) deceasedDonors!: StateDonorList;
  @State(state => state.livingDonors.livingDonorsList) livingDonors!: StateLivingDonorsList;
  @State(state => state.pageState.currentPage.performSpecifiedVxm) editState!: PerformSpecifiedVxmState;

  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;

  private saving = false;

  // Open or close the modal
  private toggleModal(): void {
    const targetModal = this.$refs.performSpecifiedVxmModal as ModalSection;
    targetModal.toggleModal();
  }

  // Load data
  private mounted(): void {
    this.loadDonors();
  }

  get donorTypes() {
    return [
      { code: DECEASED_DONOR_KEY, value: this.$t('deceased_donor') },
      { code: LIVING_DONOR_KEY, value: this.$t('living_donor') },
    ];
  }

  // Identify a Donor ID to use in the search query
  get donorIdForQuery(): string {
    // If there is a tag, then use that (only option if value was pasted)
    const donorIdTags = this?.editState?.donorIdTags || [];
    if (donorIdTags.length > 0) {
      // We only allow one entry, so getting the first item should be sufficient
      const donorIdTagText = donorIdTags[0]?.text;
      return donorIdTagText || '';
    }

    // If there is no tag, then we should be using the tag text input entry value
    const donorIdInput = this?.editState?.donorIdInput;
    return donorIdInput || '';
  }

  // Load donors based on Donor ID input
  // NOTE: here we need to use deceased_donor_id ("Afflo ID", "TGLN ID", "TQ Number", etc.
  private loadDonors(): Promise<void> {
    const donorId = this.donorIdForQuery;
    return new Promise<void>((resolve, reject) => {
      const params: { [key: string]: string } = {};
      const donorTypeKey = this.editState?.donorType || DECEASED_DONOR_KEY;
      const donorEndpoint = donorTypeKey === LIVING_DONOR_KEY ? 'livingDonors/getList' : 'deceasedDonors/getList';

      params[donorTypeKey] = donorId;

      const query = urlParams(params);
      const search_params = query ? `&${query}` : '';
      const opts = {
        pageNumber: INDEX_PAGE,
        pageSize: INDEX_PAGE_SIZE,
        search_params,
      };

      this.$store.dispatch(donorEndpoint, opts).then(() => {
        resolve();
      }).catch(() => {
        reject();
      });
    });
  }

  // Reload donor options before adding tags, as this will affect validations
  private beforeDonorIdChanged(newTag: any): void {
    // Search donors for Donor ID
    this.loadDonors().then(() => {
      // Add the tag after the search has completed, so that validations are handled as expected
      newTag.addTag();
    });
  }

  // Initialize the form when the modal is opened
  private openModal(): void {
    (this.$refs.performSpecifiedVxmValdiations as any).setErrors({});
    this.initializeForm();
    this.toggleModal();
  }

  // Commit UI form interface data to edit state based on selected API data
  private initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'performSpecifiedVxm',
      value: this.buildPerformSpecifiedVxmState(),
    });
    this.initializeTagInputs();
  }

  // Set initial tag objects based on underlying model variables
  private initializeTagInputs(): void {
    // Donor ID
    const donorIdTags = this.editState.donorId ? createTags(this.editState.donorId) : [];
    this.editState.donorIdTags = donorIdTags;
  }

  // Array of donor TGLN IDs included in the filter options
  get deceasedDonorIDsIncluded(): string[] {
    const donors = this.deceasedDonors;

    if (!donors) return [];

    const entries = donors?.entries || [];
    const mapped = entries.map((donor: ListDonor): string => {
      return `${donor.deceased_donor_id}`;
    });
    return mapped;
  }

    // Array of donor TGLN IDs included in the filter options
  get livingDonorIDsIncluded(): string[] {
    const donors = this.livingDonors;

    if (!donors) return [];

    const entries = donors?.entries || [];
    const mapped = entries.map((donor: ListLivingDonor): string => {
      return `${donor.client_id}`;
    });
    return mapped;
  }

  get donorIdAutocompleteOptions(): { text: string }[] {
    // Match options from query filters
    const options: string[] = this.editState.donorType === LIVING_DONOR_KEY ? this.livingDonorIDsIncluded : this.deceasedDonorIDsIncluded;
    const autocompleteOptions: { text: string }[] = options.map((option: string): { text: string } => {
      return {
        text: option,
      };
    });
    return autocompleteOptions;
  }

  // Limit dropdown options to values that match input
  get filteredDonorIdAutocompleteOptions(): { text: string }[] {
    const options = this.donorIdAutocompleteOptions || [];
    const input = this?.editState?.donorIdInput;
    if (!this.editState || !input) {
      return options;
    }
    const filtered = options.filter((option: { text: string }) => {
      return option.text.toLowerCase().indexOf(input.toLowerCase()) !== -1;
    });
    return filtered;
  }

  // Validate manual entry of donor ID to match list of included values
  private validateDonorIdTag(): { classes: string, rule: any }[] {
    const _donorIDsIncluded = this.editState.donorType === LIVING_DONOR_KEY ? [...this.livingDonorIDsIncluded] : [...this.deceasedDonorIDsIncluded];
    return [
      {
        classes: 'invalid-donor-id',
        rule: ((tag: { text: string }) => {
          return !_donorIDsIncluded.includes(tag.text.toUpperCase());
        }),
      },
      {
        classes: 'only-numbers',
        rule: /^([0-9]*)$/,
      }
    ];
  }

  // Setup empty form interface
  private buildPerformSpecifiedVxmState(): PerformSpecifiedVxmState {
    const form: PerformSpecifiedVxmState = {
      recipientId: this.recipientId || null,
      donorId: this.donorId || null,
      donorType: this.donorType || DECEASED_DONOR_KEY,
      // variables for tag inputs (will be re-generated based on above)
      donorIdInput: '',
      donorIdTags: [],
    };
    return form;
  }

  // Update donor ID model based on tags
  private onDonorIdChanged(tags: { text: string }[]): void {
    const donorIds = tags.map((tag: { text: string}) => {
      return tag.text;
    });
    const firstDonorId = donorIds.length > 0 ? donorIds[0] : null;
    const donorIdTags = createTags(donorIds, this.validateDonorIdTag());
    this.editState.donorId = firstDonorId;
    this.editState.donorIdTags = donorIdTags;
  }

  /*
   * In order to ensure that the autocomplete options correspond to the input,
   * we must reload the donors after the 'embedded text input' v-model changes.
   *
   * Note: this also impacts which tag entries are considered valid
   */
  @Watch('editState.donorIdInput')
  private onDonorIdInputChanged(): void {
    this.loadDonors();
  }

  // Initiate specified VXM request
  private confirmSpecifiedVxm(): void {
    this.toggleModal();
    this.saving = true;
    // Refer to the save provider that handles this form area
    const saveToolbar = this.$refs.performSpecificVxmSaveButton as SaveToolbar;
    saveToolbar.startSaving();
    // Setup saving payload
    const payload = {
      recipientId: this.editState?.recipientId,
      donorId: this.editState?.donorId,
      donorType: this.editState?.donorType
    };
    // Dispatch save action and register the response
    this.$store.dispatch('history/createRecipientVxm', payload).then((success: SaveResult) => {
      // Reload recipient, HLA labs, and HLA stem cell therapy procedure
      this.$store.dispatch('history/loadRecipientVxm', this.recipientId).then(() => {
        this.$store.dispatch('history/selectLatestRecipientVxm').then(() => {
          this.saving = false;
          saveToolbar.stopSaving(success);
          // Scroll to the new VXM results
          this.$store.dispatch('utilities/scrollTo', '#hla-selected-vxm');
        });
      }).catch((error:SaveResult) => {
        this.$store.commit('history/clearSelectedVxm');
        this.saving = false;
        saveToolbar.stopSaving(error);
      });
    }).catch((error:SaveResult) => {
        this.$store.commit('history/clearSelectedVxm');
      this.saving = false;
      saveToolbar.stopSaving(error);
    });
  }
}
</script>
