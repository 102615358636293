export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "living_donors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Living Donors"])},
        "add_new_living_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Living Donor"])},
        "transplant_centre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TxC"])},
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afflo ID"])},
        "ctr_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR ID"])},
        "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
        "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
        "mrn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRN"])},
        "organ_referral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Journeys"])},
        "journey_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journey Status"])},
        "journey_phase_and_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journey Phase - Status"])},
        "phase_status": {
          "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])},
          "display": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("phaseName")), " - ", _interpolate(_named("statusName"))])}
        },
        "workflow_step": {
          "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step"])},
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])}
        },
        "checklist_completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checklist Completion"])},
        "checklist_completion_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percent")), "% (", _interpolate(_named("completed")), "/", _interpolate(_named("total")), ")"])},
        "medical_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical Status"])},
        "insurance_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance Type"])},
        "insurance_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance Number"])},
        "recipient_registration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Date"])},
        "blood_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABO"])},
        "rh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RH"])},
        "sex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sex"])},
        "ethnicity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethnicity"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Program"])},
        "view_ontario_recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Ontario Patients"])},
        "view_out_of_province_recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Out Of Province Patients"])},
        "Active (active)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
        "Cancelled (inactive)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
        "Cluster Hold (inactive)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cluster Hold"])},
        "Cluster Suspension (inactive)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cluster Suspension"])},
        "Deferred (deferred)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deferred"])},
        "Delayed (undefined)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delayed"])},
        "HCC Suspension (inactive)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HCC Suspension"])},
        "Heart Suspension (inactive)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heart Suspension"])},
        "Incomplete (incomplete)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incomplete"])},
        "Incomplete Cluster Hold (inactive)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incomplete Cluster Hold"])},
        "Medical Hold (inactive)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical Hold"])},
        "Medical Suspension (inactive)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical Suspension"])},
        "NaMELD Suspension (inactive)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NaMELD Suspension"])},
        "Partial Cluster Transplant Hold (inactive)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partial Cluster Transplant Hold"])},
        "Pending (assessing)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
        "Post-Transplant (undefined)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post-Transplant"])},
        "Received (requested)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received"])},
        "Serum Hold (inactive)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serum Hold"])},
        "To Be Listed (eligible)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To be listed"])},
        "TIP (active)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant in progress"])},
        "Treatment Required (treatment_required)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treatment required"])},
        "Complete (proceeding_with_LDTransplant)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete"])},
        "Waiting for Living Donor Only (living_donor)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for living donor only"])},
        "Inactive (inactive)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
        "no_living_donors_to_display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no living donors to display"])},
        "cannot_find_any_listings_for_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot find any living donors that match the selected filters"])}
      },
      "fr": {
        "living_donors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Living Donors"])},
        "add_new_living_donor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Living Donor"])},
        "transplant_centre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TxC"])},
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afflo ID"])},
        "ctr_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTR ID"])},
        "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
        "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
        "mrn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRN"])},
        "organ_referral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organ Journeys"])},
        "journey_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journey Status"])},
        "journey_phase_and_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journey Phase - Status"])},
        "phase_status": {
          "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])},
          "display": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("phaseName")), " - ", _interpolate(_named("statusName"))])}
        },
        "workflow_step": {
          "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step"])},
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])}
        },
        "checklist_completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checklist Completion"])},
        "checklist_completion_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percent")), "% (", _interpolate(_named("completed")), "/", _interpolate(_named("total")), ")"])},
        "medical_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical Status"])},
        "insurance_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance Type"])},
        "insurance_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance Number"])},
        "recipient_registration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Date"])},
        "blood_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABO"])},
        "rh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RH"])},
        "sex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sex"])},
        "ethnicity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethnicity"])},
        "transplant_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant Program"])},
        "view_ontario_recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Ontario Patients"])},
        "view_out_of_province_recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Out Of Province Patients"])},
        "Active (active)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
        "Cancelled (inactive)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
        "Cluster Hold (inactive)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cluster Hold"])},
        "Cluster Suspension (inactive)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cluster Suspension"])},
        "Deferred (deferred)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deferred"])},
        "Delayed (undefined)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delayed"])},
        "HCC Suspension (inactive)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HCC Suspension"])},
        "Heart Suspension (inactive)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heart Suspension"])},
        "Incomplete (incomplete)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incomplete"])},
        "Incomplete Cluster Hold (inactive)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incomplete Cluster Hold"])},
        "Medical Hold (inactive)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical Hold"])},
        "Medical Suspension (inactive)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical Suspension"])},
        "NaMELD Suspension (inactive)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NaMELD Suspension"])},
        "Partial Cluster Transplant Hold (inactive)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partial Cluster Transplant Hold"])},
        "Pending (assessing)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
        "Post-Transplant (undefined)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post-Transplant"])},
        "Received (requested)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received"])},
        "Serum Hold (inactive)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serum Hold"])},
        "To Be Listed (eligible)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To be listed"])},
        "TIP (active)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transplant in progress"])},
        "Treatment Required (treatment_required)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treatment required"])},
        "Complete (proceeding_with_LDTransplant)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete"])},
        "Waiting for Living Donor Only (living_donor)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for living donor only"])},
        "Inactive (inactive)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
        "no_living_donors_to_display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no living donors to display"])},
        "cannot_find_any_listings_for_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot find any living donors that match the selected filters"])}
      },
      "en_tgln": {
        "living_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TGLN ID"])},
        "system_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OATS ID"])}
      },
      "fr_tgln": {
        "living_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No TGLN"])},
        "system_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID du OATS"])}
      },
      "en_tq": {
        "living_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TQ Number"])}
      },
      "fr_tq": {
        "living_donor_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No TQ"])}
      }
    }
  })
}
