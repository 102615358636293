import { APIFeatureConfig, APIGeneralClinicalConfig, APILivingDonorConfig } from '@/APIModels/configuration/features/types';
import { UIFeatureConfig } from '@/UIModels/configuration/features';
import { UILivingDonorDemographicsConfig } from '@/UIModels/configuration/features/livingDonorConfig/demographics';

export class UILivingDonorConfig {
  public enabled = false;
  public demographics = new UILivingDonorDemographicsConfig();
  public livingDonorGeneralClinical = new UILivingDonorGeneralClinicalConfig();

  public constructor(apiSource: APILivingDonorConfig) {
    if (apiSource?.enabled) {
      this.enabled = apiSource.enabled;
      this.demographics = new UILivingDonorDemographicsConfig(apiSource.demographics);
      this.livingDonorGeneralClinical = new UILivingDonorGeneralClinicalConfig(apiSource.general_clinical);
    }
  }
}

export class UILivingDonorGeneralClinicalConfig {
  public enabled = false;
  public bloodType = new UIFeatureConfig();
  public measurements = new UIFeatureConfig();

  public constructor(apiSource?: APIGeneralClinicalConfig) {
    if (apiSource?.enabled) {
      this.enabled = apiSource.enabled;
      this.bloodType = new UIFeatureConfig(apiSource.blood_type);
      this.measurements = new UIFeatureConfig(apiSource.measurements);
    }
  }
}

