<template>
  <sticky-summary v-if="uiLivingDonorSummary">
    <template v-slot:summaryContent>
      <ul class="living-donor-summary">
        <li class="list-inline-item" v-if="isBasicNameEnabled">
          <span>{{ $t('name') }}: <b>{{ uiLivingDonorSummary.summaryName }} <span v-if="uiLivingDonorSummary.summaryStatus">{{ $t(uiLivingDonorSummary.summaryStatus) }}</span></b></span>
        </li>
        <li class="list-inline-item">
          <span>{{ $t('client_id') }}: <b>{{ uiLivingDonorSummary.clientId }}</b></span>
        </li>
        <li class="list-inline-item" v-if="isDateOfBirthEnabled">
          <span>{{ $t('dob') }}: <b>{{ uiLivingDonorSummary.dateOfBirth || '-' }}</b></span>
        </li>
        <li class="list-inline-item" v-if="isDateOfBirthEnabled">
          <span>{{ $t('age') }}: <b>{{ uiLivingDonorSummary.age != null ? uiLivingDonorSummary.age : '-' }}</b></span>
        </li>
        <li class="list-inline-item" v-if="isSexEnabled">
          <span>{{ $t('sex') }}: <b>{{ uiLivingDonorSummary.sex || '-' }}</b></span>
        </li>
        <li class="list-inline-item" v-if="isBloodTypeEnabled">
          <span>{{ $t('abo') }}: <b>{{ uiLivingDonorSummary.bloodType || '-' }}</b></span>
       </li>
      </ul>
    </template>
  </sticky-summary>
</template>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter } from 'vuex-facing-decorator';
import { Component } from 'vue-facing-decorator';
import StickySummary from "@/components/shared/StickySummary.vue";
import OrganIcon from '@/components/shared/OrganIcon.vue';
import { Hospital } from '@/store/hospitals/types';
import { UILivingDonor } from "@/UIModels/livingDonor";
import { UILivingDonorSummary } from "@/UIModels/livingDonors/livingDonorSummary";
import { useCurrentPageStore } from '@/stores/currentPage';
import { i18nMessages } from '@/i18n';
import { DATA_BUNDLES } from "@/UIModels/configuration/features";

@Component({
  components: {
    OrganIcon,
    StickySummary
  },
  ...i18nMessages([
    require('@/views/_locales/common.json'),
    require('@/components/livingDonors/_locales/LivingDonorStickySummary.json')
  ])
})
export default class LivingDonorStickySummary extends mixins(DateUtilsMixin) {
  //Getters
  @Getter('getDonorAge', { namespace: 'livingDonors' }) getDonorAge!: number|undefined;
  @Getter('getOntarioHospitalById', { namespace: 'hospitals' }) private getOntarioHospitalById!: (hospitalId?: string|null) => Hospital|null;

  get uiLivingDonor(): UILivingDonor {
    return useCurrentPageStore().currentLivingDonor as UILivingDonor;
  }

  get livingDonorDisplayName(): string {
    return useCurrentPageStore().currentLivingDonor?.displayName || '';
  }

  get uiLivingDonorSummary(): UILivingDonorSummary|null {
    if (!this.uiLivingDonor) return null;

    return this.uiLivingDonor.summary as UILivingDonorSummary;
  }

  get isBasicNameEnabled(): boolean {
    return useCurrentPageStore().configuration.features.livingDonorConfig.demographics.bundles.isEnabled(DATA_BUNDLES.PatientNameBasic);
  } 

  get isDateOfBirthEnabled(): boolean {
    return useCurrentPageStore().configuration.features.livingDonorConfig.demographics.bundles.isEnabled(DATA_BUNDLES.PatientDOBAge);
  }

  get isSexEnabled(): boolean {
    return useCurrentPageStore().configuration.features.livingDonorConfig.demographics.bundles.isEnabled(DATA_BUNDLES.PatientSex);
  }

  get isBloodTypeEnabled(): boolean {
    return useCurrentPageStore().configuration.features.livingDonorConfig.livingDonorGeneralClinical.bloodType.enabled;
  }
}
</script>
