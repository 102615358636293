import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "sr-only" }
const _hoisted_2 = { class: "side-nav-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_side_nav_toolbar = _resolveComponent("side-nav-toolbar")!
  const _component_selected_living_donor_profile_links = _resolveComponent("selected-living-donor-profile-links")!
  const _component_base_side_nav = _resolveComponent("base-side-nav")!

  return (_openBlock(), _createBlock(_component_base_side_nav, {
    class: _normalizeClass({ closed: _ctx.sideNavClosed })
  }, {
    "side-nav-content": _withCtx(() => [
      _createVNode(_component_side_nav_toolbar, { onToggle: _ctx.toggleSideNav }, {
        "return-link": _withCtx(() => [
          _createVNode(_component_router_link, {
            class: "nav-link no-scroll",
            title: "Return to previous page",
            "aria-label": _ctx.$t('control_that_returns_to_previous_page'),
            to: { name: 'list-living-donors' }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'arrow-left'],
                "fixed-width": ""
              }),
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('return_to_previous_page')), 1)
            ]),
            _: 1
          }, 8, ["aria-label"])
        ]),
        _: 1
      }, 8, ["onToggle"]),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.newLivingDonor)
          ? (_openBlock(), _createBlock(_component_selected_living_donor_profile_links, {
              key: 0,
              onToggle: _ctx.toggleSideNav,
              active: true,
              newDonor: _ctx.newLivingDonor
            }, null, 8, ["onToggle", "newDonor"]))
          : (_openBlock(), _createBlock(_component_selected_living_donor_profile_links, {
              key: 1,
              onToggle: _ctx.toggleSideNav,
              active: true
            }, null, 8, ["onToggle"]))
      ])
    ]),
    _: 1
  }, 8, ["class"]))
}