import { APIRecipient } from '@/APIModels/recipients/types';
import { UIAddress } from '@/UIModels/address';
import { APINewResonse, APIShowRecipientData, APIRules, APIShowResponse } from '@/types';
import { APISerology, APISerologyInterface } from '@/store/recipients/types';
import { APIRoute, EP } from '@/api-endpoints';
import axios from 'axios';
import { UIContactDetail } from '@/UIModels/contactDetail';
import { UIDeathDetails } from '@/UIModels/recipients/deathDetails';
import { UIRecipientBloodType } from '@/UIModels/recipients/recipientBloodType';
import { UIAttachment } from '@/UIModels/recipients/attachment';
import { UIRecipientMeasurement } from '@/UIModels/recipients/recipientMeasurements';
import { UISerology } from '@/UIModels/recipients/serology';
import vuexStore from '@/store'; // for gradual conversion, see fullUserDetails
import { VirologyCode } from '@/store/lookups/types';
import { UIRecipientSummary } from '@/UIModels/recipients/recipientSummary';
import { UIJourneySummary } from '@/UIModels/journey/journeySummary';
import { useCurrentPageStore } from '@/stores/currentPage';
import { DATA_BUNDLES } from './configuration/features';
import { UIDemographics } from '@/UIModels/recipients/demographics';
import { UIRecipientEgfr } from '@/UIModels/recipients/recipientEgfr';
import { UIRecipientDialysis } from '@/UIModels/recipients/recipientDialysis';
import { RootState } from '@/store/types';
import { UISaveable } from './saveable';

export function buildDisplayName(apiRecipient: APIRecipient): string {
  if (!useCurrentPageStore().configuration.features.recipientConfig.demographics.bundles.isEnabled(DATA_BUNDLES.PatientNameBasic)) return `${apiRecipient.client_id}`;
  return [apiRecipient.patient_profile?.first_name, apiRecipient.patient_profile?.last_name].join(' ');
}

export class UIRecipient extends UISaveable {
  public apiSource?: APIRecipient;
  public clientId?: string;
  public displayName = '';
  private loaded: boolean;

  public summary: UIRecipientSummary|null = null;
  public demographics: UIDemographics|null = null;
  public addresses: UIAddress[] = [];
  public contactDetails: UIContactDetail[] = [];
  public deathDetails: UIDeathDetails|null = null;
  public bloodType: UIRecipientBloodType|null = null;
  public recipientMeasurements: UIRecipientMeasurement[] = [];
  public serologyResults: UISerology[] = [];
  public serologyCodesUsed: any[] = [];
  public attachments: UIAttachment[] = [];
  public recipientEgfrs: UIRecipientEgfr[] = [];
  public recipientDialysis: UIRecipientDialysis[] = [];

  public get isNew(): boolean {
    return !this.clientId;
  }

  public constructor(clientId?: string) {
    super();
    this.clientId = clientId;
    this.loaded = false;
  }

  // Load resource data and permitted actions
  public async load(opts?: { reload: boolean }): Promise<void> {
    if (this.clientId) {
      await this.loadShow(opts);
    } else {
      await this.loadNew(opts);
    }
  }

  public async loadNew(opts?: { reload: boolean }): Promise<void> {
    const url = APIRoute(EP.recipients.create);
    try {
      const response: APINewResonse = await axios.get(url);
      this.permittedActions = response.data.permitted_actions;
      this.setRules(response.data.rules);
      this.loaded = true;
    } catch (error: unknown) {
      this.loaded = true;
      console.warn(error);
    }
  }

  /**
   * Load everything for the Recipient
   * NOTE: does NOT request new data if already loaded
   */
  private async loadShow(opts?: { reload: boolean }): Promise<void> {
    if (opts?.reload) this.loaded = false;

    if (!this.loaded) {
      const clientId = this.clientId;
      if (!clientId) return;

      const url = APIRoute(EP.recipients.show, [[':id', (clientId as string)]]);
      try {
        const response: APIShowResponse<APIShowRecipientData> = await axios.get(url);
        const apiRecipient: APIRecipient = response?.data?.recipient;
        this.permittedActions = response.data.permitted_actions;
        this.updateFromAPIResponse(apiRecipient);
        this.buildDeathFromAPIRecipient();
        this.buildBloodTypeFromAPIRecipient();
        this.recipientMeasurements = await UIRecipientMeasurement.loadFor(this);
        this.contactDetails = await UIContactDetail.loadFor(this);
        this.addresses = await UIAddress.loadFor(this);
        this.attachments = await UIAttachment.loadFor(this);
        this.demographics = new UIDemographics(apiRecipient);
        this.recipientEgfrs = await UIRecipientEgfr.loadFor(this);
        this.recipientDialysis = await UIRecipientDialysis.loadFor(this);
        this.buildSummaryFromAPIRecipient();
        this.loaded = true;
      } catch (error: unknown) {
        this.loaded = true;
        console.warn(error);
      }
    }
  }

  private setRules(rules: APIRules): void {
    vuexStore.commit('validations/resetPrefix', 'patient');
    vuexStore.commit('validations/set', { rules: { ['patient']: rules } });
  }

  // Map from API data structure to UI model structure
  public updateFromAPIResponse(apiRecipient: APIRecipient) {
    this.apiSource = apiRecipient;
    this.clientId = apiRecipient.client_id?.toString() || '';
    this.displayName = buildDisplayName(apiRecipient);

    // NOTE: If we have the entire recipient document then we are done loading here
    this.loaded = true;
  }

  // Get a list of journeys for selected recipient side navigation
  // NOTE: here we use the Journey Summary view model to align filtering and sorting
  get selectedRecipientJourneysList(): UIJourneySummary[] {
    const result: UIJourneySummary[] = [];
    result.push(...(this.summary?.activeJourneys || []));
    result.push(...(this.summary?.postTransplantJourneys || []));
    result.push(...(this.summary?.closedJourneys || []));
    return result;
  }

  public async loadSerologyResults(): Promise<void> {
    const clientId = this.clientId;
    const url = APIRoute(EP.recipients.serology.index, [[':recipient_id', (clientId as string)]]);

    try {
      const response: APIShowResponse<any> = await axios.get(url);

      // array to store all the virology codes
      let codesUsed: (string|null)[] = [];
      const apiSerologyResults: APISerology[] = response?.data?.virologies || [];
      this.serologyResults = apiSerologyResults.map((apiSerology: APISerologyInterface): UISerology => {
        const serology = new UISerology(apiSerology);
        codesUsed = [...codesUsed, ...serology.getVirologyCodesUsed];
        return serology;
      });

      // de-duplicate list of virology codes used
      const deDuplicatedCodesUsed = [...new Set(codesUsed)];

      // store a virology codes array side-by-side with the serology results for all codes used
      this.serologyCodesUsed = this.getVirologyCodesCombined.filter((virologyCode: VirologyCode) => {
        if (deDuplicatedCodesUsed.includes(virologyCode.code)) return virologyCode;
      });
      
    } catch (error: unknown) {
      console.warn(error);
    }
  }

  private buildSummaryFromAPIRecipient() {
    this.summary = new UIRecipientSummary(this.apiSource || undefined);
  }

  private buildDeathFromAPIRecipient() {
    const apiDeath = this.apiSource?.death;
    this.deathDetails = apiDeath ? UIDeathDetails.buildFromAPIRecipientDeath(apiDeath) : UIDeathDetails.buildNew();
  }

  private buildBloodTypeFromAPIRecipient() {
    const apiBloodType = this.apiSource?.diagnostics?.blood;
    this.bloodType = apiBloodType ? new UIRecipientBloodType(apiBloodType) : new UIRecipientBloodType();
  }

  public get getVirologyCodesCombined(): VirologyCode[] {
    return (vuexStore.state as RootState).lookups?.virology_codes_combined || [];
  }
}
