import { APILivingDonorDemographicsConfig, APIRegistrationInfoConfig } from "@/APIModels/configuration/features/types";
import { UIFeatureConfig, UIBundleConfig } from "@/UIModels/configuration/features";

export class UILivingDonorDemographicsConfig {
  public enabled = false;
  public registrationInfo = new UILivingDonorRegistrationInfoConfig();
  public personalInfo = new UIFeatureConfig();
  public financialAssistance = new UIFeatureConfig();
  public bundles = new UIBundleConfig();

  public constructor(apiSource?: APILivingDonorDemographicsConfig) {
    if (apiSource?.enabled) {
      this.enabled = apiSource.enabled;
      this.registrationInfo = new UILivingDonorRegistrationInfoConfig(apiSource.registration_info);
      this.personalInfo = new UIFeatureConfig(apiSource.personal_info);
      this.financialAssistance = new UIFeatureConfig(apiSource.financial_assistance);
      this.bundles = new UIBundleConfig(apiSource.bundles);
    }
  }
}

export class UILivingDonorRegistrationInfoConfig {
  public enabled = false;

  public constructor(apiSource?: APIRegistrationInfoConfig) {
    if (apiSource?.enabled) this.enabled = apiSource.enabled;
  }
}
