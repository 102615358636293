<template>
  <div>
    <loading-living-donor-page v-if="!dispatchEventsComplete" />
    <template v-else>
      <page-top>
        <list-living-donors-link />
        / {{livingDonorDisplayName}}
      </page-top>
      <living-donor-sticky-summary />
      <div class="content-wrap">
        <div class="container-fluid">
          <div class="nav-wrapper">
            <side-nav-living-donor-profile />
            <div class="page-content">
              <living-donor-summary />
              <!-- Card Sections -->
              <demographics
                ref="demographics"
                @loaded="loaded"
              />
              <general-clinical-information
                v-if="isLivingDonorGeneralClinicalEnabled"
                ref="generalClinical"
                @loaded="(ref) => loaded(ref)"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-facing-decorator";
import PageTop from '@/components/shared/PageTop.vue';
import { Component } from 'vue-facing-decorator';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import Demographics from '@/components/livingDonors/Demographics.vue';
import LivingDonorSummary from '@/components/livingDonors/LivingDonorSummary.vue';
import SideNavLivingDonorProfile from '@/components/livingDonors/side-nav/SideNavLivingDonorProfile.vue';
import LoadingSideNav from '@/components/shared/loading-skeletons/LoadingSideNav.vue';
import { useCurrentPageStore, setPageTitle } from '@/stores/currentPage';
import { i18nMessages } from '@/i18n';
import LoadingLivingDonorPage from '@/components/shared/loading-skeletons/LoadingLivingDonorPage.vue';
import ListLivingDonorsLink from '@/components/shared/page-top/ListLivingDonorsLink.vue';
import { UILivingDonor } from "@/UIModels/livingDonor";
import LivingDonorStickySummary from '@/components/livingDonors/LivingDonorStickySummary.vue';
import GeneralClinicalInformation from '@/components/livingDonors/GeneralClinicalInformation.vue';

@Component({
  components: {
    PageTop,
    ListLivingDonorsLink,
    SaveToolbar,
    Demographics,
    LoadingSideNav,
    LivingDonorSummary,
    LoadingLivingDonorPage,
    SideNavLivingDonorProfile,
    LivingDonorStickySummary,
    GeneralClinicalInformation
  },
  ...i18nMessages([
    require('@/views/_locales/common.json'),
  ]),
})
export default class EditLivingDonor extends Vue {
  private dispatchEventsComplete = false;
  private sectionsLoaded = new Set();
  private allSectionsLoaded = false;

  get uiLivingDonor(): UILivingDonor {
    return useCurrentPageStore().currentLivingDonor as UILivingDonor;
  }

  get livingDonorDisplayName(): string {
    return useCurrentPageStore().currentLivingDonor?.displayName || '';
  }

  // Check if living donor General Clinical is enabled
  get isLivingDonorGeneralClinicalEnabled(): boolean {
    return useCurrentPageStore().configuration.features.livingDonorConfig.livingDonorGeneralClinical.enabled;
  }

  // Vue lifecycle hooks
  public async mounted(): Promise<void> {
    const livingDonorIdentifier = this.uiLivingDonor.clientId as string;
    if (!livingDonorIdentifier) {
      console.warn('missing living donor client_id');
    }

    await useCurrentPageStore().currentLivingDonor?.load({ reload: false });

    setPageTitle(this.$t('page.living_donor.edit', { livingDonor: this.livingDonorDisplayName })),
    this.dispatchEventsComplete = true;
  }

  get isLoaded(): boolean {
    return this.allSectionsLoaded;
  }

  public loaded(ref: string): void {
    if (!ref) return;
    // Create a set of all the sections to load filtering out sections we don't care about
    const sectionsToLoad = new Set(Object.keys(this.$refs).filter((ref: string) => !ref.match(/validations|saveLivingDonor|potentialDuplicateModal/)));
    // Add the ref we just loaded
    this.sectionsLoaded.add(ref);
    if (this.sectionsLoaded.size === sectionsToLoad.size) {
      this.$store.dispatch('utilities/scrollBehavior');
      this.allSectionsLoaded = true;
    }
  }
}
</script>
