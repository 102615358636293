<template>
  <!-- Form layout -->
  <validation-observer ref="validations">
    <form-layout
      :disabled="!enableForm"
      form-id="measurement_form">
      <template v-slot:title>
        <!-- Mode indicator / subsection form title -->
        <legend>
          <h5 v-if="!selection?.isNew" class="legend-title">
            {{$t('selected_measurement')}}
          </h5>
          <h5 v-else class="legend-title">
            {{$t('new_measurement')}}
          </h5>
        </legend>
      </template>

      <template v-slot:contents>
        <!-- Data Entry Form (Add, View, and Edit modes) -->
        <div class="row d-flex">
          <div class="measurements-form-group">
            <date-input
              ruleKey="recipient_measurements.date"
              inputId="gci-measurements-date"
              :name="$t('measurement_date')"
              v-model="editState.date"
            />
          </div>
          <div class="measurements-form-group">
            <text-input
              inputId="gci-measurements-bmi"
              :name="$t('bmi')"
              v-model="editState.bmi"
              :calculated="true"
              :disabled="true"
            />
          </div>
        </div>
        <div class="row d-flex">
          <div class="measurements-form-group measureUnits">
            <div class="inner">
              <div class="form-group">
                <p>{{$t('metric')}}</p>
                <number-input
                  ruleKey="recipient_measurements.weight_kg"
                  inputId="gci-measurements-weight_kg"
                  :name="$t('weight')"
                  :append="true"
                  appendText="kg"
                  step="0.1"
                  :calculated="true"
                  calculatedText=""
                  @change="calculateMeasurementFromKg($event?.target?.value)"
                  v-model="editState.weight_kg_rounded"
                />
              </div>
              <div class="form-group">
                <number-input
                  ruleKey="recipient_measurements.height_cm"
                  inputId="gci-measurements-height_cm"
                  :name="$t('height')"
                  :append="true"
                  appendText="cm"
                  step="0.1"
                  :calculated="true"
                  calculatedText=""
                  @change="calculateMeasurementFromCm($event?.target?.value)"
                  v-model="editState.height_cm_rounded"
                />
              </div>
            </div>
          </div>
          <div class="measurements-form-group measureUnits last">
            <div class="inner">
              <p>{{$t('imperial')}}</p>
              <div class="form-group">
                <number-input
                  inputId="gci-measurements-weight_lbs"
                  :name="$t('weight')"
                  :append="true"
                  appendText="lbs"
                  step="0.1"
                  :calculated="true"
                  calculatedText=""
                  @change="calculateMeasurementFromLbs($event?.target?.value)"
                  v-model="editState.weight_lbs_rounded"
                />
              </div>
              <div class="form-group">
                <number-input
                  inputId="gci-measurements-height_in"
                  :name="$t('height')"
                  :append="true"
                  appendText="in"
                  step="0.1"
                  :calculated="true"
                  calculatedText=""
                  @change="calculateMeasurementFromIn($event?.target?.value)"
                  v-model="editState.height_in_rounded"
                />
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:save>
        <save-toolbar
          :show="showSaveToolbar"
          ref="saveMeasurement"
          class="card-footer action-row temp-saving row"
          :label="$t('save_measurement')"
          :cancelButton="true"
          @save="handleSave()"
          @cancel="handleCancel()"
        />
      </template>      

    </form-layout>
  </validation-observer>
</template>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { State } from 'vuex-facing-decorator';
import { BmiResult } from '@/store/tools/types';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import HiddenInput from '@/components/shared/HiddenInput.vue';
import { SaveResult, APIPermittedActions } from "@/types";
import { Component, Prop, Watch } from 'vue-facing-decorator';
import { RecipientValidations } from '@/store/recipients/types';
import FormLayout from '@/components/shared/FormLayout.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import { UIRecipient } from '@/UIModels/recipient';
import { UIRecipientMeasurement } from "@/UIModels/recipients/recipientMeasurements";
import { i18nMessages } from "@/i18n";
import { parseFormErrors } from '@/utils';
import { UIListFormSelection } from '@/UIModels/listFormSelection';
import { BmiCalculationParams } from '@/store/tools/actions';
import { RulesQuery } from '@/types';
import { UIConfiguration } from '@/UIModels/configuration';

@Component({
  components: {
    TextInput,
    DateInput,
    SaveToolbar,
    SelectInput,
    NumberInput,
    CheckboxInput,
    HiddenInput,
    FormLayout
  },
  ...i18nMessages([
    require('@/components/recipients/_locales/GeneralClinicalInformation.json'),
    require('@/components/_locales/common.json'),
  ]),
  emits: [
    'loaded',
    'success'
  ],
})
export default class MeasurementsForm extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.recipients.selectedRecipient.validations) validations!: RecipientValidations;
  @State(state => state.tools.bmiResult) bmiResult!: BmiResult;

  // Selection instance
  @Prop({ required: true }) selection!: UIListFormSelection;

  // Editable view model for the form
  private editState = new UIRecipientMeasurement();
  private permittedActions: string[] = [];

  private isLoadingForm = false;
  private isSuccessfullySaving = false;

  get uiRecipient(): UIRecipient {
    return useCurrentPageStore().currentRecipient as UIRecipient;
  }

  // Can we enable the form?
  get enableForm(): boolean {
    return this.permittedActionsAllowCreateOrUpdate && this.uiRecipient.canSave;
  }

  // Can we show the save toolbar?
  get showSaveToolbar(): boolean {
    return this.permittedActionsAllowCreateOrUpdate && this.uiRecipient.canSave;
  }

  // Can we enable the save toolbar?
  get enableSaveToolbar(): boolean {
    return this.permittedActionsAllowCreateOrUpdate && this.uiRecipient.canSave;
  }

  // Check permitted actions list
  get permittedActionsAllowCreateOrUpdate(): boolean {
    // First we check special case, on #new endpoint permitted_actions is an empty array
    if (this.permittedActions.length === 0) return true;

    // We have a list of permitted actions, so now we can check for "update" keyword
    return this.permittedActions.includes(APIPermittedActions.Update);
  }

  // Which Configuration view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentConfiguration(): UIConfiguration {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.configuration as UIConfiguration;
  }
  
  // Process change to "selection" prop
  // NOTE: this will initialize Follow Up Form
  @Watch('selection', { immediate: true, deep: true })
  private handleSelectionChange(_newSelection: UIRecipientMeasurement, oldSelection?: UIRecipientMeasurement): void {
    if (!oldSelection) return;

    this.resetEditState();
    this.resetFormErrors();

    // Reset save toolbar (unless it is showing success indicator)
    if (!this.isSuccessfullySaving) this.resetSaveToolbar();
    this.isSuccessfullySaving = false;
  }

  // Initialize form edit state by requesting a copy of the "selection" view model
  private async resetEditState(): Promise<void> {
    this.isLoadingForm = true;

    const newEditState = new UIRecipientMeasurement();

    const query = this.selection.isNew ? this.ruleQueryDefaults : this.ruleQueryEditState;
    await newEditState.load({ selectedId: this.uiRecipient.clientId as string, id: this.selection.id as string, query });

    this.editState = newEditState;

    // Sync metric/imperial/bmi
    if (!this.selection.isNew) {
      this.calculateMeasurementFromKg(this.editState.weight_kg);
    }
    
    this.isLoadingForm = false;
  }

  // What validation rule query parameters are needed based on lookup defaults?
  get ruleQueryDefaults(): RulesQuery {
    const params: RulesQuery = {};
    return params;
  }

  // What validation rule query parameters are needed based on current form edit state?
  get ruleQueryEditState(): RulesQuery {
    const params: RulesQuery = {};
    return params;
  }

  // Resets Form Errors
  private resetFormErrors(): void {
    const validations = this.$refs.validations as any;
    if (validations) validations.resetForm();
  }

  // Process save button click event
  public async handleSave(): Promise<void> {
    if (this.saveToolbar) this.saveToolbar.startSaving();

    const params = {
      selected: this.editState,
      recipient: this.uiRecipient
    };

    try {
      const success: SaveResult = await this.editState.save(params);
      this.handleSuccess(success);
    } catch (error: unknown) {
      this.handleErrors(error as SaveResult);
    }
  }

  // Process successful save result
  private async handleSuccess(success: SaveResult): Promise<void> {
    if (this.saveToolbar) {
      this.saveToolbar.stopSaving(success);
    }
    try {
      this.$emit('success', success);
    } catch(err) {
      console.warn(err, 'Something unexpected happen when attempting to load measurement details');
    }
  }

  // Reset form when cancel is clicked
  public async handleCancel(): Promise<void> {
    await this.resetEditState();
    this.resetFormErrors();
    this.resetSaveToolbar();
  }

  // Process error save result
  private handleErrors(errors: SaveResult): void {
    // Derive errors for UI input fields based on API error results
    const formErrors: any = parseFormErrors(errors, this.idLookup());

    // inject api errors into vee-validate
    const validationObserver = this.$refs.validations as any;
    if (validationObserver) validationObserver.setErrors(formErrors);

    if (this.saveToolbar) this.saveToolbar.stopSaving(errors);
  }

  // Clears all save notifications shown by the form.
  public resetSaveToolbar(): void {
    if (this.saveToolbar) this.saveToolbar.reset();
  }

  // Reference to the form's save toolbar
  get saveToolbar(): SaveToolbar {
    return this.$refs.saveMeasurement as SaveToolbar;
  }

  /**
   * Vue lifecyle hook, for when the reactivity system has taken control of the Document Object Model.
   *
   * @listens #mounted
   */
   private async mounted(): Promise<void> {
    this.resetEditState();
  }

  public idLookup(): {[key: string]: string} {
    const result = {
      'recipient_measurements.date'      : 'gci-measurements-date',
      'recipient_measurements.height_cm' : 'gci-measurements-height_cm',
      'recipient_measurements.weight_kg' : 'gci-measurements-weight_kg',
    };
    return result;
  }

  // Updates BMI, Height and Weight from API triggered on change
  // from height / weight fields, or selecting a measurement
  private async calculateMeasurementBmi(params: BmiCalculationParams): Promise<void> {
    try {
      await this.$store.dispatch('tools/loadBmiHeightWeight', params);
      // update pageState with new values
      this.editState.updateFromBMIResult(this.bmiResult);
    } catch (error: any) {
      console.warn(error.description);
    }
  }

  private calculateMeasurementFromKg(newWeightKgValue: string|null): void {
    this.calculateMeasurementBmi({
      height: this.editState.height_cm,
      height_unit: 'cm',
      weight: newWeightKgValue,
      weight_unit: 'kg',
    });
  }

  private calculateMeasurementFromCm(newHeightCmValue: string|null): void {
    this.calculateMeasurementBmi({
      height: newHeightCmValue,
      height_unit: 'cm',
      weight: this.editState.weight_kg,
      weight_unit: 'kg',
    });
  }

  private calculateMeasurementFromLbs(newWeightKgValue: string|null): void {
    this.calculateMeasurementBmi({
      height: this.editState.height_cm,
      height_unit: 'cm',
      weight: newWeightKgValue,
      weight_unit: 'lbs',
    });
  }

  private calculateMeasurementFromIn(newHeightInValue: string|null): void {
    this.calculateMeasurementBmi({
      height: newHeightInValue,
      height_unit: 'in',
      weight: this.editState.weight_kg,
      weight_unit: 'kg',
    });
  }
}
</script>
