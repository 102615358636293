import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_toolbar = _resolveComponent("table-toolbar")!
  const _component_table_list = _resolveComponent("table-list")!
  const _component_measurements_form = _resolveComponent("measurements-form")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_openBlock(), _createBlock(_component_sub_section, {
    title: _ctx.$t("measurements_title"),
    "sub-section-id": "gci-measurements",
    ref: "measurements"
  }, {
    contents: _withCtx(() => [
      _createVNode(_component_table_toolbar, {
        createButton: _ctx.uiLivingDonor.canSave,
        createText: _ctx.$t('create_measurement'),
        onTableCreateRow: _ctx.handleTableCreateRow
      }, null, 8, ["createButton", "createText", "onTableCreateRow"]),
      _createVNode(_component_table_list, {
        ref: "patientMeasurementsTable",
        "table-id": "patient-measurements-table",
        "table-config": _ctx.patientMeasurementsTableConfig,
        onTableRowClick: _ctx.handleTableRowClick,
        highlightSelection: true,
        firstColumnTabbable: true,
        rowStyleClass: _ctx.rowStyleClass
      }, null, 8, ["table-config", "onTableRowClick", "rowStyleClass"]),
      _createVNode(_component_measurements_form, {
        ref: "measurementsForm",
        selection: _ctx.selection,
        onSuccess: _ctx.handleSuccess
      }, null, 8, ["selection", "onSuccess"])
    ]),
    _: 1
  }, 8, ["title"]))
}