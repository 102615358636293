import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content-wrap" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "nav-wrapper" }
const _hoisted_4 = { class: "page-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_recipient_page = _resolveComponent("loading-recipient-page")!
  const _component_list_recipients_link = _resolveComponent("list-recipients-link")!
  const _component_page_top = _resolveComponent("page-top")!
  const _component_recipient_sticky_summary = _resolveComponent("recipient-sticky-summary")!
  const _component_side_nav_recipient_profile = _resolveComponent("side-nav-recipient-profile")!
  const _component_recipient_summary = _resolveComponent("recipient-summary")!
  const _component_demographics = _resolveComponent("demographics")!
  const _component_contact_information = _resolveComponent("contact-information")!
  const _component_proto_care_givers = _resolveComponent("proto-care-givers")!
  const _component_proto_communication_information = _resolveComponent("proto-communication-information")!
  const _component_proto_transportation_and_mobility = _resolveComponent("proto-transportation-and-mobility")!
  const _component_proto_pace_and_engagement = _resolveComponent("proto-pace-and-engagement")!
  const _component_proto_scheduling_preferences = _resolveComponent("proto-scheduling-preferences")!
  const _component_recipient_documents = _resolveComponent("recipient-documents")!
  const _component_general_clinical_information = _resolveComponent("general-clinical-information")!
  const _component_recipient_serology = _resolveComponent("recipient-serology")!
  const _component_recipient_egfr_dialysis = _resolveComponent("recipient-egfr-dialysis")!
  const _component_proto_patient_history = _resolveComponent("proto-patient-history")!
  const _component_recipient_death = _resolveComponent("recipient-death")!
  const _component_ProtoLDContactInformation = _resolveComponent("ProtoLDContactInformation")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.dispatchEventsComplete)
      ? (_openBlock(), _createBlock(_component_loading_recipient_page, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_page_top, null, {
            default: _withCtx(() => [
              _createVNode(_component_list_recipients_link),
              _createTextVNode(" / " + _toDisplayString(_ctx.recipientDisplayName), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_recipient_sticky_summary),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_side_nav_recipient_profile),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_recipient_summary),
                  _createVNode(_component_demographics, {
                    ref: "demographics",
                    onLoaded: _cache[0] || (_cache[0] = (ref) => _ctx.loaded(ref))
                  }, null, 512),
                  _createVNode(_component_contact_information, {
                    ref: "contactInfo",
                    onLoaded: _cache[1] || (_cache[1] = (ref) => _ctx.loaded(ref))
                  }, null, 512),
                  _createVNode(_component_proto_care_givers),
                  _createVNode(_component_proto_communication_information),
                  _createVNode(_component_proto_transportation_and_mobility),
                  _createVNode(_component_proto_pace_and_engagement),
                  _createVNode(_component_proto_scheduling_preferences),
                  (_ctx.isRecipientDocumentsEnabled)
                    ? (_openBlock(), _createBlock(_component_recipient_documents, {
                        key: 0,
                        ref: "recipientDocuments",
                        onLoaded: _cache[2] || (_cache[2] = (ref) => _ctx.loaded(ref))
                      }, null, 512))
                    : _createCommentVNode("", true),
                  (_ctx.isRecipientGeneralClinicalEnabled)
                    ? (_openBlock(), _createBlock(_component_general_clinical_information, {
                        key: 1,
                        ref: "generalClinical",
                        onLoaded: _cache[3] || (_cache[3] = (ref) => _ctx.loaded(ref))
                      }, null, 512))
                    : _createCommentVNode("", true),
                  (_ctx.isRecipientSerologyEnabled)
                    ? (_openBlock(), _createBlock(_component_recipient_serology, {
                        key: 2,
                        ref: "serology",
                        onLoaded: _cache[4] || (_cache[4] = (ref) => _ctx.loaded(ref))
                      }, null, 512))
                    : _createCommentVNode("", true),
                  (_ctx.isRecipientEgfrEnabled || _ctx.isRecipientDialysisEnabled)
                    ? (_openBlock(), _createBlock(_component_recipient_egfr_dialysis, {
                        key: 3,
                        ref: "egfrDialysis",
                        onLoaded: _cache[5] || (_cache[5] = (ref) => _ctx.loaded(ref))
                      }, null, 512))
                    : _createCommentVNode("", true),
                  _createVNode(_component_proto_patient_history),
                  (_ctx.isRecipientDeathEnabled)
                    ? (_openBlock(), _createBlock(_component_recipient_death, {
                        key: 4,
                        ref: "death",
                        onLoaded: _cache[6] || (_cache[6] = (ref) => _ctx.loaded(ref))
                      }, null, 512))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ProtoLDContactInformation, { canSave: true })
                ])
              ])
            ])
          ])
        ], 64))
  ]))
}