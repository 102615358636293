<template>
  <div class="col-12">
    <card-section
      class="inverted mb-1"
      section-id="table-filter"
      :lookups-to-load="lookupsToLoad"
    >
      <template v-slot:header>
        {{$t('filter')}}
      </template>
      <template v-slot:body>
        <div class="row">
          <div class="standard-form-group" v-if="filterConfigation.txc">
            <select-input
              select-id="tableFilter.txc"
              :name="$t('txc')"
              v-model="editState.transplantCenter"
              :options="buildTransplantCentreFilter"
              :placeholder="$t('all')"
            />
          </div>
          <div class="standard-form-group" v-if="filterConfigation.affloId">
            <text-input
              input-id="tableFilter.affloId"
              :name="$t('affloId')"
              v-model="editState.clientId"
            />
          </div>
          <div class="standard-form-group" v-if="isBasicNameEnabled && filterConfigation.firstName">
            <text-input
              input-id="tableFilter.firstName"
              :name="$t('firstName')"
              v-model="editState.firstName"
            />
          </div>
          <div class="standard-form-group" v-if="isBasicNameEnabled && filterConfigation.lastName">
            <text-input
              input-id="tableFilter.lastName"
              :name="$t('lastName')"
              v-model="editState.lastName"
            />
          </div>
          <div class="standard-form-group" v-if="isMRNEnabled && filterConfigation.mrn">
            <text-input
              input-id="tableFilter.mrn"
              :name="$t('mrn')"
              v-model="editState.mrn"
            />
          </div>
          <div class="standard-form-group" v-if="isSexEnabled && filterConfigation.sex">
            <select-input
              select-id="tableFilter.sex"
              :name="$t('sex')"
              v-model="editState.sex"
              :options="sexOptionsDropdownValues"
              :placeholder="$t('all')"
            />
          </div>
          <div class="standard-form-group" v-if="filterConfigation.abo">
            <select-input
              select-id="tableFilter.abo"
              :name="$t('abo')"
              v-model="editState.bloodType"
              :options="bloodTypeOptionsDropdownValues"
              :placeholder="$t('all')"
            />
          </div>
          <div class="standard-form-group" v-if="filterConfigation.organCode">
            <select-input
              select-id="tableFilter.organCode"
              :name="$t('organCode')"
              v-model="editState.organCode"
              :options="buildOrganFilter"
              :placeholder="$t('all')"
            />
          </div>
          <div class="standard-form-group" v-if="filterConfigation.phase">
            <select-input
              select-id="tableFilter.phase"
              :name="$t('phase')"
              v-model="editState.journeyState"
              :options="buildJourneyStateFilter"
              :placeholder="$t('all')"
            />
          </div>
          <div class="standard-form-group" v-if="isWorkflowStepEnabled && filterConfigation.step">
            <select-input
              select-id="tableFilter.step"
              :name="$t('step')"
              v-model="editState.workflowStep"
              :options="buildWorkflowStepFilter"
              :placeholder="$t('all')"
            />
          </div>
        </div>
        <div class="action-row">
          <!-- filter -->
          <button
            type="button"
            @click.prevent="filterButton"           
            class="btn btn-wide btn-outline-primary mr-4"
          >
            {{ $t('filter') }}
          </button>
          
          <!-- clear -->
          <button
            type="button"
            class="btn btn-wide btn-secondary-outline"
            @click.prevent="clearButton"
          >
            {{ $t('clear') }}
          </button>
        </div>
      </template>
    </card-section>
  </div>
</template>

<script lang="ts">
import { State, Getter } from 'vuex-facing-decorator';
import { i18nMessages } from '@/i18n';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import CardSection from "@/components/shared/CardSection.vue";
import { UITableFilter } from "@/UIModels/tableFilter";
import TextInput from '@/components/shared/TextInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import { Organ, OrganCodeValue, BloodType, Sex } from '@/store/lookups/types';
import { Hospital } from "@/store/hospitals/types";
import { NumericCodeValue, GenericCodeValue } from '@/store/types';
import { useCurrentPageStore } from "@/stores/currentPage";
import { DATA_BUNDLES } from "@/UIModels/configuration/features";

export interface Options {
  [key: string]: string | undefined;
}

const FILTER_CONFIGURATION_DEFAULT: FilterConfigurationType = {
  txc: true,
  affloId: true,
  firstName: true,
  lastName: true,
  mrn: true,
  sex: true,
  abo: true,
  organCode: true,
  phase: true,
  step: true
}

export interface FilterConfigurationType {
  txc: boolean;
  affloId: boolean;
  firstName: boolean;
  lastName: boolean;
  mrn: boolean;
  sex: boolean;
  abo: boolean;
  organCode: boolean;
  phase: boolean;
  step: boolean;
}

@Component({
  components: {
    CardSection,
    TextInput,
    SelectInput
  },
  ...i18nMessages([
    require('@/components/shared/_locales/TableFilter.json'),
  ]),
  emits: [
    'filterList',
  ],
})
export default class TableFilter extends Vue {
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.hospitals.all) hospitals!: Hospital[];
  @State(state => state.lookups.blood_type) bloodTypeLookup!: BloodType[];
  @State(state => state.lookups.recipient_journey_phase_status) recipientJourneyPhaseStatus!: any[];
  @State(state => state.lookups.recipient_journey_workflow_step) workflowStepLookup!: GenericCodeValue[];
  @State(state => state.lookups.recipient_journey_phase_status) phaseStatusLookup!: GenericCodeValue[];

  @Getter('organName', { namespace: 'lookups' }) organName!: (organCode?: number) => string;
  @Getter('sexOptions', { namespace: 'lookups' }) sexOptions!: Sex[];
  @Getter('nonExpiredOrganOptions', { namespace: 'lookups' }) nonExpiredOrganOptions!: (type?: string) => NumericCodeValue[];

  private editState: UITableFilter = new UITableFilter();

  @Prop({ default: (() => FILTER_CONFIGURATION_DEFAULT) }) filterConfigation!: FilterConfigurationType;

  lookupsToLoad = ['recipient_journey_phase_status'];

  visible = true;

  get isBasicNameEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.demographics.bundles.isEnabled(DATA_BUNDLES.PatientNameBasic);
  }

  get isDateOfBirthEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.demographics.bundles.isEnabled(DATA_BUNDLES.PatientDOBAge);
  }

  get isMRNEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.demographics.bundles.isEnabled(DATA_BUNDLES.PatientMRN);
  }

  get isSexEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.demographics.bundles.isEnabled(DATA_BUNDLES.PatientSex);
  }

  get isWorkflowStepEnabled(): boolean {
    return useCurrentPageStore().configuration.features.journeyConfig.journeyOverview.phaseStatus.workflowStep.enabled;
  }

  get sexOptionsDropdownValues(): GenericCodeValue[] {
    return this.sexOptions.map((option: Sex): GenericCodeValue => {
      return {
        value: option.value,
        code: option.code
      };
    });
  }

  get buildJourneyStateFilter(): GenericCodeValue[] {
    const result: GenericCodeValue[] = [];

    const phaseOptions = this.phaseStatusLookup || [];
    phaseOptions.forEach((phaseOption: GenericCodeValue) => {
      const statusOptions = phaseOption.sub_tables?.statuses || [];
      if (statusOptions.length === 0) {
        result.push(this.buildJourneyStateOptionPhaseOnly(phaseOption));
      } else {
        statusOptions.forEach((statusOption: GenericCodeValue) => {
          result.push(this.buildJourneyStateOptionPhaseStatus(phaseOption, statusOption));
        });
      }
    });

    return result.sort((a, b) => (a.value > b.value) ? 1 : -1);
  }

  private buildJourneyStateOptionPhaseOnly(phaseOption: GenericCodeValue): GenericCodeValue {
    return {
      code: `{[phase]=${phaseOption.code}`,
      value: phaseOption.value,
    };
  }

  private buildJourneyStateOptionPhaseStatus(phaseOption: GenericCodeValue, statusOption: GenericCodeValue): GenericCodeValue {
    return {
      code: `{[phase]=${phaseOption.code}&[status]=${statusOption.code}}`,
      value: this.$t('phase_status.display', { phaseName: phaseOption.value, statusName: statusOption.value }).toString(),
    };
  }

  get hospitalOptions(): GenericCodeValue[] {
    if (!this.hospitals) return [];

    const mapped = this.hospitals.map((hospital: Hospital): GenericCodeValue => {
      return {
        code: hospital._id,
        value: `${hospital.program_identifier} - ${hospital.hospital_name_info?.name}`,
      };
    });
    return mapped;
  }

  get bloodTypeOptionsDropdownValues(): GenericCodeValue[] {
    return this.bloodTypeLookup.map((option: Sex): GenericCodeValue => {
      return {
        value: option.value,
        code: option.code
      };
    });
  }

  get buildOrganFilter(): any {
    const allowedOrganCodes = [
      OrganCodeValue.Liver, 
      OrganCodeValue.Heart,  
      OrganCodeValue.Kidney,
      OrganCodeValue.Lung,
      OrganCodeValue.PancreasWhole,
      OrganCodeValue.PancreasIslets,
      OrganCodeValue.SmallBowel,
      OrganCodeValue.VCA];

    // Match options from query filters
    const filter: NumericCodeValue[] = [];

    // NOTE: here we assume that the Recipient Listing page should only support organs with non-expired entry in the organ lookup
    this.nonExpiredOrganOptions().forEach((organ) => {
      if (allowedOrganCodes.includes(organ.code)) {
        // Map lookup format (code/value) to filter option format (value/text)
        filter.push({
          code: organ.code,
          value: `${this.organName(organ.code)}`,
          expired_date: organ.expired_date
        });
      }
    });

    return filter;
  }

  get buildWorkflowStepFilter(): GenericCodeValue[] {
    const result = this.workflowStepLookup || [];
    return result.sort((a, b) => (a.value > b.value) ? 1 : -1);
  }

  get buildTransplantCentreFilter(): GenericCodeValue[] {
    if (!this.hospitals) return [];

    const mapped = this.hospitals.map((hospital: Hospital): GenericCodeValue => {
      return {
        code: hospital._id,
        value: hospital.program_identifier,
      };
    });
    return mapped;
  }

  toggleVisible(): void {
    this.visible = !this.visible;
  }

  filterButton(): void {
    this.$emit('filterList', this.editState.extractPatch());
  }

  clearButton(): void {
    this.editState = new UITableFilter();
    this.$emit('filterList', this.editState.extractPatch());
  }

}
</script>
