
import { APILivingDonor } from '@/APIModels/livingDonors/types';
import { calculateAge } from '@/utils';
import { parseDisplayDateUi } from '@/utilities/date-utils';
import { buildDisplayName } from '@/UIModels/livingDonor';

export class UILivingDonorSummary {
  public apiSource?: APILivingDonor;
  public clientId: string|null = null;

  public summaryName: string|null = null;
  public summaryStatus: string|null = null;

  public dateOfBirth: string|null = null;
  public age: number|null = null;
  public sex: string|null = null;
  public bloodType: string|null = null;

  public constructor(apiLivingDonor?: APILivingDonor) {
    if (apiLivingDonor) this.updateFromAPIResponse(apiLivingDonor);
  }

  // Map from API data structure to UI model structure
  public updateFromAPIResponse(apiLivingDonor: APILivingDonor) {
    this.apiSource = apiLivingDonor;
    
    this.summaryName = buildDisplayName(apiLivingDonor);
    this.summaryStatus = apiLivingDonor.death?.death_date ? 'deceased_indicator' : null;

    this.clientId = String(apiLivingDonor.client_id);

    this.dateOfBirth = parseDisplayDateUi(apiLivingDonor.patient_profile?.birth?.date) || null;
    const calculatedAge = calculateAge(apiLivingDonor.patient_profile?.birth?.date || null, apiLivingDonor.death?.death_date || null);
    this.age = calculatedAge != null ? calculatedAge : null;

    this.sex = apiLivingDonor.patient_profile?.sex || null;
    this.bloodType = apiLivingDonor.blood?.type || null;
  }
}
