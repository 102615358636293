import axios from 'axios';
import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { APIRoute, EP } from '@/api-endpoints';
import { handleAllocationErrors, buildErrorResult } from '@/utils';
import { DeceasedDonorOrganDonations } from '@/store/deceasedDonors/types';
import { AllocationState, AllocationOfferTypeValues, RecipientDetails, RecipientDetailsVxm, RecipientDetailsVxmCurrentCumulative, RecipientDetailsVxmClass } from '@/store/allocations/types';
import { SaveResult, ResponseError } from '@/types';
import { TaskState, TaskType } from '@/store/tasks/types';

/**
 * Blank defaults for nested class1/class2 sub-documents in recipient VXM results
 *
 * @returns {RecipientDetailsVxmClass} object containing defaults
 */
function defaultRecipientDetailsVxmClass(): RecipientDetailsVxmClass {
  return {
    result: null,
    messages: [],
    unacceptable_allele_group: [],
    unacceptable_allele_specific: [],
    unacceptable_epitopes: [],
    indeterminate_allele_group: [],
    indeterminate_allele_specific: [],
    indeterminate_epitopes: [],
    possible_allele_specific: [],
    untested: [],
    untested_epitopes: [],
  };
}

/**
 * Blank defaults for nested current/cumulative sub-documents in recipient VXM results
 *
 * @returns {RecipientDetailsVxmClass} object containing defaults
 */
function defaultCurrentCumulative(): RecipientDetailsVxmCurrentCumulative {
  return {
    result: null,
    class1: defaultRecipientDetailsVxmClass(),
    class2: defaultRecipientDetailsVxmClass(),
  };
}

/**
 * Sanitize response data to ensure it matches the expected type interface
 *
 * @param rawRecipientDetails recipient details response from the back-end
 * @returns {RecipientDetails} sanitized details for display of allocation recipient details
 */
function sanitizeRecipientDetails(rawRecipientDetails: RecipientDetails): RecipientDetails {
  const rawVxm: RecipientDetailsVxm = rawRecipientDetails.recipient.vxm;

  const sanitizedCurrent: RecipientDetailsVxmCurrentCumulative = Object.assign({}, rawVxm.current || defaultCurrentCumulative());
  sanitizedCurrent.class1 = sanitizedCurrent.class1 || defaultRecipientDetailsVxmClass();
  sanitizedCurrent.class2 = sanitizedCurrent.class2 || defaultRecipientDetailsVxmClass();

  const sanitizedCumulative: RecipientDetailsVxmCurrentCumulative = Object.assign({}, rawVxm.cumulative || defaultCurrentCumulative());
  sanitizedCumulative.class1 = sanitizedCumulative.class1 || defaultRecipientDetailsVxmClass();
  sanitizedCumulative.class2 = sanitizedCumulative.class2 || defaultRecipientDetailsVxmClass();

  const sanitizedVxm = Object.assign({}, rawVxm);
  sanitizedVxm.current = sanitizedCurrent;
  sanitizedVxm.cumulative = sanitizedCumulative;

  const result = Object.assign({}, rawRecipientDetails);
  result.recipient.vxm = sanitizedVxm;

  return result;
}

export const actions: ActionTree<AllocationState, RootState> = {
  // Get Exclusion Rules given a clientId and organCode
  getExclusionRules({ commit, getters }, { clientId, organCode }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.deceasedDonors.allocations.exclusion_rules, [[':donor_id', clientId], [':organ_id', organCode]]);
      axios.get(url).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Commit rules if we have any
          commit('setExclusionRules', response.data.exclusion_rules);
          resolve();
        }
        resolve();
      }).catch((error: any) => {
        reject(error);
      });
    });
  },

  /**
   * Helper method to request an allocation given an endpoint and payload and then set things up
   * @param commit - The vuex commit method for this namespace
   * @param state
   * @param dispatch
   * @param url - The url to send the POST request to
   * @param payload - The paylod to attach
   */
    // request allocation then poll donor allocation section to find out if allocation finished
  requestAllocationTaskFromUrl({ commit, state, dispatch }, {url, payload}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios.post(url, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Note: Tp-12416 special case when we have a non-supercedable alloction and simply need to force refresh
          // however as we already do a refresh after a successful allocation then this is no longer necessary
          const allocationTask = response.data.allocation as TaskType;
          const taskId = allocationTask._id || undefined;
          Promise.all([
            dispatch('tasks/loadTask', taskId, { root: true })
          ]).then(() => {
            resolve(response);
          });
        } else if (response.data && response.data.errors) {
          // Build error result
          const errorResult = handleAllocationErrors(response.data.errors);
          // Reject with errors
          reject(errorResult);
        }
      }).catch((error: any) => {
        // check for response error code
        const response = error && error.response ? error.response : null;
        if (!response) reject(error);
        if (response.status == 401) {
          localStorage.removeItem('access_token');
          window.location.href = EP.auth.login;
          reject(error);
        } else if (response.status == 403) {
          reject(error);
        }

        // Handle generic error message with an alert popup e.g. Network error
        if (!!error.message) {
          alert(error.message);
          // on clicking okay, refresh the page for the user
          location.reload();
        }
        reject(error);
      });
    });
  },

  // Run an allocation given a clientId and organCode
  runAllocation({ dispatch }, { clientId, organCode, payload }): Promise<any> {
    const url = APIRoute(EP.deceasedDonors.allocations.create, [[':donorId', clientId], [':organId', organCode]]);
    return dispatch('requestAllocationTaskFromUrl', { url: url, payload: payload});
  },
  // ReRun an allocation given a clientId and organCode
  reRunAllocation({ dispatch }, { clientId, organCode, payload, allocationId }): Promise<any> {
    const url = APIRoute(EP.deceasedDonors.allocations.rerun, [[':donorId', clientId], [':organId', organCode], [':allocationId', allocationId]]);
    return dispatch('requestAllocationTaskFromUrl', { url: url, payload: payload});
  },
  // Run an expedited allocation given a clientId and organCode
  runExpeditedAllocation({ dispatch }, { clientId, organCode, payload }): Promise<any> {
    const url = APIRoute(EP.deceasedDonors.allocations.run_expedited, [[':donorId', clientId], [':organId', organCode]]);
    return dispatch('requestAllocationTaskFromUrl', { url: url, payload: payload});
  },
  // Rerun an expedited allocation given a clientId, organCode and allocationId
  rerunExpeditedAllocation({ dispatch }, { clientId, organCode, payload, allocationId }): Promise<any> {
    const url = APIRoute(EP.deceasedDonors.allocations.rerun_expedited, [[':donorId', clientId], [':organId', organCode], [':allocationId', allocationId]]);
    return dispatch('requestAllocationTaskFromUrl', { url: url, payload: payload});
  },

  saveChecklist({commit, getters }, { clientId, organCode, allocationId, checklist}): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const payload={ checklist: checklist };
      const url = APIRoute(
        EP.deceasedDonors.allocations.checklist.create,
        [[':donor_id', clientId], [':organ_id', organCode], [':allocation_id', allocationId]]
      );
      axios.post(url, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else {
          // Build error result
          const errorResult = handleAllocationErrors(response.data.errors);
          // Reject with errors
          reject(errorResult);
        }
      }).catch((error: any) => {
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  getAllAllocations({commit, getters, dispatch, state}, {clientId, clearSelectedAllocation}): Promise<void> {
    return new Promise<void>((resolve, _reject) => {
      if(state.isLoadingAllocations) {
        resolve();
        return;
      }
      if(clearSelectedAllocation) {
        commit('clearAllocation');
      }
      commit('startLoadingAllocations');
      Promise.all([
        dispatch('getAllocations', { clientId: clientId, state: 'all' }),
        dispatch('getAllocations', { clientId: clientId, state: 'inactive' }),
        dispatch('getAllocations', { clientId: clientId, state: 'active' }),
      ]).finally(() => {
        setTimeout(() => {
          commit('stopLoadingAllocations');
          resolve();
        }, 1000);

        // emit to parent component we're done reloading the allocations
        // this.loadingAllocations = false;
      });
    });
  },
  // Get allocations by state, clientId (default state is all)
  getAllocations({ commit, getters }, { clientId, state, organCode }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.deceasedDonors.allocations.all, [[':donorId', clientId]]);
      // Default to active allocations if we have no state supplied
      const opts = {
        allocations: {
          state: state || 'all',
          organ_code: organCode || null
        }
      };
      axios.post(url, opts).then((response: any) => {
        const donor = response.data.donor;
        if (donor && donor.organ_allocations.length >= 0) {
          commit('setLastLoadedAllocationDonorId', donor.donor_id);
          switch(state) {
            case 'active':
              commit('setActiveAllocations', donor.organ_allocations);
              break;
            case 'inactive':
              commit('setInactiveAllocations', donor.organ_allocations);
              break;
            default:
              // Default is considered to be state all
              commit('setAllocations', donor.organ_allocations);
              break;
          }
        } else {
          commit('setAllocations', undefined);
        }
        resolve();
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  // Get allocation details given a clientId, organCode and allocationId
  getAllocation({ commit, getters, state }, { clientId, organCode, allocationId }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if(state.isCreatingAllocation) {
        resolve();
        return;
      }
      commit('startLoading');
      commit('clearAllocation');
      const url = APIRoute(EP.deceasedDonors.allocations.show, [[':donorId', clientId], [':organId', organCode], [':allocationId', allocationId]]);
      axios.get(url).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          commit('setAllocation', response.data.allocation);
          resolve();
        } else {
          // Build error result
          const errorResult = handleAllocationErrors(response.data.errors);
          // Reject with errors
          reject(errorResult);
        }
      }).catch((error: any) => {
        reject(error);
      }).finally(() => {
        commit('stopLoading');
      });
    });
  },
  // Get checklist details given an allocationId
  getChecklistDetails({ commit, getters }, { donorId, organCode, allocationId}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(
        EP.deceasedDonors.allocations.checklist.show,
        [[':donor_id', donorId], [':organ_id', organCode], [':allocation_id', allocationId]]
      );
      axios.get(url).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          commit('setChecklistDetails', response.data.checklist.checklists);
          resolve();
        } else {
          // Build error result
          const errorResult = handleAllocationErrors(response.data.errors);
          // Reject with errors
          reject(errorResult);
        }
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  // Get checklist history given an allocationId
  getChecklistHistory({ commit, getters }, { donorId, organCode, allocationId}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(
        EP.deceasedDonors.allocations.checklist.history,
        [[':donor_id', donorId], [':organ_id', organCode], [':allocation_id', allocationId]]
      );
      axios.get(url).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          commit('setChecklistHistory', response.data.checklists_history.checklists_history || []);
          resolve();
        } else {
          // Build error result
          const errorResult = handleAllocationErrors(response.data.errors);
          // Reject with errors
          reject(errorResult);
        }
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  // TODO: TECH_DEBT: this should return a standardized Promise<SaveResult> instead of <any>
  handleOffer({ commit, getters }, { url, payload, type }): Promise<any> {
    if (type === 'discontinue') commit('startDiscontinuingOneOffer');
    if (type === 'create') commit('startMakingOffer');
    return new Promise<any>((resolve, reject) => {
      axios.post(url, payload).then((response: any) => {
        // reject immediately if there is an error
        if (response.data && response.data.errors) {
          reject({ error: response.data.errors.allocation_service || response.data.errors, error_code: undefined });
        }
        // DIAG: allocation response looks like { offer: actions: [{ ... }] }
        const responseData = response.data.offer;
        const responseAction = responseData.actions;
        let responseActionResult: ResponseError = { error: false, error_message: undefined, error_code: undefined };
        if (Array.isArray(responseAction) && responseAction.length > 0) {
          // TODO: Handle multiple errors
          responseActionResult = { ...responseAction[0] };
        }
        const isSuccessful = responseData && !responseActionResult.error;
        if (isSuccessful) {
          commit('setAllocation', responseData.allocation);
          resolve(response);
        } else {
          // Reject with errors
          reject({ error: responseActionResult.error_message, error_code: responseActionResult.error_code });
        }
      }).catch((error: any) => {
        reject({ error: error, code: undefined });
      }).finally(() => {
        if (type === 'discontinue') commit('stopDiscontinuingOneOffer');
        if (type === 'create') commit('stopMakingOffer');
      });
    });
  },
  makeOffer({ dispatch }, { clientId, organCode, allocationId, offerDetails }): Promise<any> {
    let url: string;
    if (offerDetails.type === AllocationOfferTypeValues.NoOffer) {
      url = APIRoute(EP.deceasedDonors.allocations.offers.no_offer, [[':donorId', clientId], [':organId', organCode], [':allocationId', allocationId]]);
    } else {
      url = APIRoute(EP.deceasedDonors.allocations.offers.create, [[':donorId', clientId], [':organId', organCode], [':allocationId', allocationId]]);
    }
    const payload = { allocation_offer: offerDetails };
    return dispatch('handleOffer', { url: url, payload: payload, type: 'create' });
  },
  respondOffer({ commit, getters }, { clientId, organCode, allocationId, responseDetails }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.deceasedDonors.allocations.offers.respond, [[':donor_id', clientId], [':organ_id', organCode], [':allocation_id', allocationId]]);
      const payload = { responses: { actions: responseDetails } };
      axios.post(url, payload).then((response: any) => {
        // reject immediately if there is an error
        if (response.data && response.data.errors) {
          reject(response.data.errors.allocation_service || response.data.errors);
        }
        // DIAG: allocation response looks like { offer: actions: [{ ... }] }
        const responseData = response.data.offer;
        const responseAction = responseData.actions;
        // filter actions for any errors
        const actionErrors = responseAction.filter((item: any) => {
          // only return items with an error of true
          return item.error === true;
        });
        const actionErrorResponse: any[] = [];
        // if we have errors
        if (actionErrors.length > 0) {
          actionErrors.forEach((item: any) => {
            const errorResult = {
              recipient_id: item.recipient_id,
              error_code: item.error_code,
              error_message: item.error_message
            };
            actionErrorResponse.push(errorResult);
          });
        }
        // was this a successful response
        const isSuccessful = responseData && actionErrorResponse.length <= 0;
        if (isSuccessful) {
          commit('setAllocation', responseData.allocation);
          resolve(response);
        } else {
          // Reject with errors
          reject(actionErrorResponse);
        }
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  discontinueOffer({ dispatch }, { clientId, organCode, allocationId, discontinueDetails }): Promise<any> {
    const url = APIRoute(EP.deceasedDonors.allocations.offers.discontinue_offers, [[':donorId', clientId], [':organId', organCode], [':allocationId', allocationId]]);
    const payload = { allocation_offer: discontinueDetails };
    return dispatch('handleOffer', { url: url, payload: payload, type: 'discontinue' });
  },
  discontinueAllocationFromUrl({ commit, dispatch, state }, {url, donor_client_id, reason_id, loadingCounter}): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      commit(`start${loadingCounter}`);
      const payload = {
        allocation_offer: {
          reason_id: reason_id,
        },
      };
      axios.post(url, payload).then((response: any) => {
        // reject immediately if there is an error
        if (response.data && response.data.errors) {
          reject(response.data.errors.allocation_service || response.data.errors);
          return;
        }
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          commit('clearAllocation');
          Promise.all([
            dispatch('getAllAllocations', { clientId: donor_client_id }),
            dispatch('deceasedDonors/get', donor_client_id, { root: true })
          ]).then(() => {
            commit(`stop${loadingCounter}`);
            resolve(response);
          });
        } else {
          // Build error result
          const errorResult = handleAllocationErrors(response.data.errors);
          // Reject with errors
          reject(errorResult);
        }
      }).catch((error: any) => {
        reject(error);
      }).finally(() => {
        commit(`stop${loadingCounter}`);
      });
    });
  },
  discontinueAllocation({ dispatch, commit }, { clientId, organCode, allocationId, reasonId }): Promise<void> {
    const url = APIRoute(EP.deceasedDonors.allocations.offers.discontinue_allocation, [[':donorId', clientId], [':organId', organCode], [':allocationId', allocationId]]);
    return dispatch('discontinueAllocationFromUrl', {url: url, donor_client_id: clientId, reason_id: reasonId, loadingCounter: 'DiscontinuingOneAllocation'});
  },
  discontinueOrgans({ dispatch }, { clientId, reasonId }): Promise<void> {
    const url = APIRoute(EP.deceasedDonors.allocations.discontinue_offers, [[':donorId', clientId]]);
    return dispatch('discontinueAllocationFromUrl', {url: url, donor_client_id: clientId, reason_id: reasonId, loadingCounter: 'DiscontinuingAllAllocations'});
  },
  getDonorDetails({ commit, getters, state }, { donorId, organCode, allocationId }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!state.donorDetails) {
        const url = APIRoute(EP.deceasedDonors.allocations.donor_details, [[':donorId', donorId], [':organId', organCode], [':allocationId', allocationId]]);
        axios.get(url).then((response: any) => {
          const isSuccessful = response.data && !response.data.errors;
          if (isSuccessful) {
            // Commit rules if we have any
            commit('setDonorDetails', response.data.allocation);
            resolve();
          }
          resolve();
        }).catch((error: any) => {
          reject(error);
        });
      } else {
        resolve();
      }
    });
  },
  getRecipientDetails({ commit, getters }, { donorId, allocationOrganCode, allocationId, recipientId, journeyOrganCode }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.deceasedDonors.allocations.recipient_details, [[':donorId', donorId], [':organId', allocationOrganCode], [':allocationId', allocationId], [':recipientId', recipientId], [':journeyOrganCode', journeyOrganCode]]);
      axios.get(url).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Sanitize response data to ensure it matches the expected type interface
          const recipient = response.data.allocation;
          const sanitized = sanitizeRecipientDetails(recipient);
          commit('setRecipientDetails', sanitized);
          resolve();
        } else {
          // Build error result
          const errorResult = handleAllocationErrors(response.data.errors);
          // Reject with errors
          reject(errorResult);
        }
        resolve();
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  // Generate an Allocation Report
  generateAllocationReport({ commit }, { payload }): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      commit('startGeneratingReport');
      if (!payload || !payload.allocation_client_id) reject();
      const url = APIRoute(EP.reports.allocation_report);
      axios.post(url, payload).then((response: any) => {
        // Do we have a url
        const isSuccessful = response.data && response.data.report;
        if (isSuccessful) resolve(response.data.report);
        reject(response);
      }).catch((error: any) => {
        reject(error);
      }).finally(() => {
        commit('stopGeneratingReport');
      });
    });
  },

  // Add Recipient to Allocation
  addRecipient({}, { donorId, organCode, allocationId, recipientId, transplantProgramId, reason }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!allocationId) reject();
      const url = APIRoute(EP.deceasedDonors.allocations.add_recipient, [[':donorId', donorId], [':organId', organCode], [':allocationId', allocationId]]);
      const payload = {
        recipient_id: recipientId,
        transplant_program_id: transplantProgramId,
        added_manually_reason: reason
      };
      axios.post(url, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) resolve();
        // Build error result
        const errorResult = handleAllocationErrors(response.data.errors);
        // Reject with errors
        reject(errorResult);
      }).catch((error: any) => {
        reject(error);
      });
    });
  },

  // Update an OPO Program Organ Donation based on the current Allocation
  saveOpoTransplantDetails({}, { donorId, organId, allocationId, organDonation }: { donorId: string, organId: string, allocationId: string, organDonation: DeceasedDonorOrganDonations }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const ep = APIRoute(EP.deceasedDonors.allocations.opo_transplant_details, [[':donor_id', donorId], [':organ_id', organId], [':allocation_id', allocationId]]);
      const payload = { organ_donation: organDonation };
      axios.put(ep, payload).then((response: any) => {
        if (response.data && !response.data.errors) {
          const success: SaveResult = { success: true, responseData: response.data };
          resolve(success);
        } else {
          const activityError: SaveResult = buildErrorResult(response.data.errors, 'opo_transplant_details');
          reject(activityError);
        }
      }).catch((error: any) => {
        const serverError: SaveResult = { success: false, errorMessages: [error.message] };
        reject(serverError);
      });
    });
  },

  getExcludedRecipients({}, {donorId, organCode, allocationId}): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      const url = APIRoute(EP.deceasedDonors.allocations.excluded_recipients, [[':donor_id', donorId], [':organ_id', organCode], [':id', allocationId]]);
      axios.get(url).then((response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  }
};
