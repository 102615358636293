<template>
  <base-side-nav :class="{ closed: sideNavClosed }">
    <template v-slot:side-nav-content>
      <side-nav-toolbar @toggle="toggleSideNav">
        <template v-slot:return-link>

          <router-link class="nav-link no-scroll" title="Return to previous page" :aria-label="$t('control_that_returns_to_previous_page')" :to="{ name: 'list-living-donors' }">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width />
            <span class="sr-only">
              {{$t('return_to_previous_page')}}
            </span>
          </router-link>
        </template>
      </side-nav-toolbar>

      <div class="side-nav-container">
        <template v-if="newLivingDonor">
          <selected-living-donor-profile-links @toggle="toggleSideNav" :active="true" :newDonor="newLivingDonor"/>
        </template>
        <template v-else>
          <selected-living-donor-profile-links @toggle="toggleSideNav" :active="true" />
        </template>
      </div>
    </template>
  </base-side-nav>
</template>

<script lang="ts">
import { State, Getter } from 'vuex-facing-decorator';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import BaseSideNav from '@/components/shared/side-nav/BaseSideNav.vue';
import SideNavToolbar from '@/components/shared/side-nav/SideNavToolbar.vue';
import SelectedLivingDonorProfileLinks from "@/components/livingDonors/side-nav/SelectedLivingDonorProfileLinks.vue";
import { LivingDonor, LivingDonorAllocationSummary } from '@/store/livingDonors/types';
import { i18nMessages } from '@/i18n';

@Component({
  components: {
    BaseSideNav,
    SideNavToolbar,
    SelectedLivingDonorProfileLinks,
  },
  ...i18nMessages([
    require('@/components/_locales/common.json'),
    require('../_locales/common.json'),
  ]),
})
export default class SideNavLivingDonor extends Vue {
  @State(state => state.livingDonors.selectedLivingDonor) private livingDonor!: LivingDonor;

  @Prop({default: false }) newLivingDonor!: boolean;

  public sideNavClosed = false;

  /**
   * Boolean used to show the side menu, if true side-nav is open
   * Changes the 'closed' class on the nav
   *
   * @returns {void} changes the sideNavClosed boolean
   */
  public toggleSideNav(): void {
    this.sideNavClosed = !this.sideNavClosed;
  }
}
</script>
