import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "summary-header" }
const _hoisted_2 = { class: "navbar" }
const _hoisted_3 = {
  class: "navbar-nav nav-sticky",
  role: "navigation"
}
const _hoisted_4 = { class: "nav-item" }
const _hoisted_5 = { class: "nav-item dropdown" }
const _hoisted_6 = {
  id: "summaryMenuDropdown",
  href: "#",
  class: "nav-link dropdown-toggle",
  "data-toggle": "dropdown",
  "aria-haspopup": "true",
  "aria-expanded": "false",
  title: "View main navigation"
}
const _hoisted_7 = {
  class: "dropdown-menu",
  "aria-labelledby": "summaryMenuDropdown",
  role: "navigation"
}
const _hoisted_8 = { class: "nav-sticky summary-content" }
const _hoisted_9 = {
  class: "navbar-nav nav-sticky",
  role: "navigation"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_user_menu = _resolveComponent("user-menu")!

  return (_openBlock(), _createElementBlock("section", {
    id: "sumSticky",
    class: _normalizeClass([{ active: _ctx.isVisible }, "summary-sticky d-none d-md-block"]),
    "aria-hidden": "true"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("nav", _hoisted_2, [
        _createElementVNode("ul", _hoisted_3, [
          _createElementVNode("li", _hoisted_4, [
            _createVNode(_component_router_link, {
              to: { name: 'index' },
              exact: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: ['fas', 'home'],
                  "fixed-width": ""
                })
              ]),
              _: 1
            })
          ]),
          _createElementVNode("li", _hoisted_5, [
            _createElementVNode("a", _hoisted_6, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['far', 'bars'],
                "fixed-width": ""
              })
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_router_link, {
                class: "nav-link active d-inline-block d-md-none",
                to: { name: 'index' },
                exact: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('dashboard')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_router_link, {
                class: "nav-link",
                to: { name: 'list-recipients' },
                exact: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('recipients')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_router_link, {
                class: "nav-link",
                to: { name: 'list-living-donors' },
                exact: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('living_donors')), 1)
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _renderSlot(_ctx.$slots, "summaryContent")
        ]),
        _createElementVNode("ul", _hoisted_9, [
          _createElementVNode("li", null, [
            _createVNode(_component_user_menu)
          ])
        ])
      ])
    ])
  ], 2))
}