<template>
  <div>
    <template v-if="!livingDonor">
      <page-top>
        <span class="skeleton-box" style="width: 200px" />
      </page-top>
       <div class="content-wrap">
        <div class="container-fluid">
          <div class="nav-wrapper">
            <loading-side-nav />
            <div class="page-content">
              <loading-donor-page />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <!-- Page Top -->
      <page-top>
        <router-link :to="{ name: 'list-living-donors' }">
          {{ $t("living_donors")}}
        </router-link>
        /
        <router-link :to="{ name: 'edit-living-donor', params: { id: clientId } }">
          {{livingDonorDisplayName}}
        </router-link>
        / {{livingDonorOrganName}}
      </page-top>
      <living-donor-sticky-summary/>
      <!-- Page wrap -->
      <div class="content-wrap">
        <!-- Page Container  -->
        <div class="container-fluid">
          <!-- Nav wrapper -->
          <div class="nav-wrapper">
            <!-- Sidebar Navigation -->
            <side-nav-living-donor-organ />
            <!-- Donor Content -->
            <div class="page-content">
              <living-donor-summary
                ref="livingDonorSummary"
                @loaded="loaded"
              />
              <living-donor-offers
                ref="allocations"
                @loaded="loaded"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<i18n src="../_locales/common.json"></i18n>
<i18n src="../_locales/listLivingDonors.json"></i18n>

<script lang="ts">
import { State, Getter } from 'vuex-facing-decorator';
import PageTop from '@/components/shared/PageTop.vue';
import { LivingAllocation } from '@/store/livingAllocations/types';
import { Component, Vue, Watch } from 'vue-facing-decorator';
import { LivingDonor } from '@/store/livingDonors/types';
import LivingDonorSummary from '@/components/livingDonors/LivingDonorSummary.vue';
import LivingDonorOffers from '@/components/livingAllocations/LivingDonorOffers.vue';
import LivingDonorStickySummary from '@/components/livingDonors/LivingDonorStickySummary.vue';
import LoadingSideNav from '@/components/shared/loading-skeletons/LoadingSideNav.vue';
import LoadingDonorPage from '@/components/shared/loading-skeletons/LoadingDonorPage.vue';
import SideNavLivingDonorOrgan from '@/components/livingDonors/side-nav/SideNavLivingDonorOrgan.vue';
import { i18nMessages } from '@/i18n';
import { setPageTitle } from '@/stores/currentPage';
import { UILivingDonor } from "@/UIModels/livingDonor";
import { UILivingDonorSummary } from "@/UIModels/livingDonors/livingDonorSummary";
import { useCurrentPageStore } from '@/stores/currentPage';

@Component({
  components: {
    PageTop,
    SideNavLivingDonorOrgan,
    LoadingSideNav,
    LoadingDonorPage,
    LivingDonorStickySummary,
    LivingDonorSummary,
    LivingDonorOffers
  },
  ...i18nMessages([
    require('../_locales/common.json'),
    require('../_locales/listLivingDonors.json'),
  ]),
})
export default class EditDeceasedDonorAllocations extends Vue {
  @State(state => state.livingAllocations.selected) private allocation!: LivingAllocation;
  @State(state => state.livingDonors.selectedLivingDonor) private livingDonor!: LivingDonor;

  @Getter('clientId', { namespace: 'livingDonors' }) private clientId!: string|undefined;
  @Getter('organName', { namespace: 'lookups' }) organNameLookup!: (organCode?: number) => string;

  // Set page title based on organ and allocation region
  private setPageTitle(): void {
    setPageTitle(this.$t('page.living_donor.organ_allocation', { livingDonor: this.livingDonorDisplayName, organ: this.livingDonorOrganName }));
  }

  private sectionsLoaded = new Set();
  private allSectionsLoaded = false;

  get uiLivingDonor(): UILivingDonor {
    return useCurrentPageStore().currentLivingDonor as UILivingDonor;
  }

  get livingDonorDisplayName(): string {
    return useCurrentPageStore().currentLivingDonor?.displayName || '';
  }

  get uiLivingDonorSummary(): UILivingDonorSummary|null {
    if (!this.uiLivingDonor) return null;

    return this.uiLivingDonor.summary as UILivingDonorSummary;
  }

  // Return true if all sections and their associated data has been loaded
  get isLoaded(): boolean {
    return this.allSectionsLoaded;
  }

  // Return true is the section reference supplied has been loaded
  public isSectionLoaded(ref: string): boolean {
    if (!ref) return false;
    return this.sectionsLoaded.has(ref);
  }

  public loaded(ref: string): void {
    if (!ref) return;
    // Create a set of all the sections to load filtering out validations and the save button
    const sectionsToLoad = new Set(Object.keys(this.$refs).filter((ref: string) => !ref.match(/validations|saveDonor/)));
    // Add the ref we just loaded
    this.sectionsLoaded.add(ref);
    if (this.sectionsLoaded.size === sectionsToLoad.size) {
      this.$store.dispatch('utilities/scrollBehavior');
      this.allSectionsLoaded = true;
    }
  }

  /**
   * Vue lifecyle hook, for when the reactivity system has taken control of the Document Object Model.
   *
   * @listens #mounted
   */
  public mounted(): void {
    this.setPageTitle();
    this.$store.dispatch('hospitals/load');
    this.getAllocations();
  }

  // Update title if organ or allocation region changes
  @Watch('$route.params.organ_code')
  onOrganCodeChange() {
    if(!this.$route.hash){
      this.setPageTitle();
      this.getAllocations();
    }
  }

  @Watch('$route.params.option')
  onAllocationRegionChange() {
    if(!this.$route.hash){
      this.setPageTitle();
      this.getAllocations();
    }
  }

  /**
   * Return the organ name as a string
   *
   * Using the organ_code param, find the matching consented organ for the selected donor.
   *
   * @returns {string} organ name
   */
  get livingDonorOrganName(): string {
    // Prepare organ name for display
    const rawOrganCode = this.$route.params.organ_code || '';
    const numericOrganCode = rawOrganCode != null ? parseFloat(rawOrganCode as string) : undefined;
    const rawOrganName = this.organNameLookup(numericOrganCode);
    const prettyOrganName = numericOrganCode ? rawOrganName : 'Unknown';
    const donorIndicators = this.livingDonor.indicators || {};
    const organCount = '';
    return `${organCount}${prettyOrganName}`;
  }

  private getAllocations() {
    this.$store.dispatch('livingAllocations/getAllAllocations', { clientId: this.clientId, clearSelectedAllocation: true });
  }
}
</script>
