import { APIFilterInterface } from '@/APIModels/tools/types';
import { urlParams } from '@/utils';

export class UITableFilter {
  public apiSource: APIFilterInterface|null = null;
  public transplantCenter: string|null = null;
  public clientId: string|null = null;
  public firstName: string|null = null;
  public lastName: string|null = null;
  public mrn: string|null = null;
  public sex: string|null = null;
  public bloodType: string|null = null;
  public organCode: string|null = null;
  public journeyState: string|null = null;
  public workflowStep: string|null = null;

  // Define new UI view model structure
  public constructor(apiFilter: APIFilterInterface|null = null) {
    if (apiFilter) this.updateFromAPIResponse(apiFilter);
  }

  // Map from API data structure to UI model structure
  public updateFromAPIResponse(apiFilter: APIFilterInterface) {
    this.apiSource = apiFilter;
    this.transplantCenter = apiFilter.transplant_centre || null;
    this.clientId = apiFilter.client_id || null;
    this.firstName = apiFilter.first_name || null;
    this.lastName = apiFilter.last_name || null;
    this.mrn = apiFilter.mrn || null;
    this.sex = apiFilter.sex || null;
    this.bloodType = apiFilter.blood_type || null;
    this.organCode = apiFilter.organ_code || null;
    this.journeyState = apiFilter.journey_state || null;
    this.workflowStep = apiFilter.workflow_step || null;
  }

  // Return search values for api
  public extractPatch(): any {
    const filterBy: APIFilterInterface = {
      transplant_centre: this.transplantCenter || '',
      client_id: this.clientId || '',
      first_name: this.firstName || '',
      last_name: this.lastName || '',
      mrn: this.mrn || '',
      sex: this.sex || '',
      blood_type: this.bloodType || '',
      organ_code: this.organCode || '',
      journey_state: this.journeyState || '',
      workflow_step: this.workflowStep || ''
    };

    return urlParams(filterBy);
  }
}
