<template>
  <loading-summary-card v-if="!uiLivingDonorSummary" />
  <div class="card summary-card" v-else>
    <div class="card-header">
      <div class="media align-items-center">
        <div class="media-left">
          <font-awesome-icon :icon="['far', 'id-card']" fixed-width />
        </div>
        <div class="media-body flex-row align-items-center">
          <h3 class="summary-name">
            {{ uiLivingDonorSummary.summaryName }}
            <span v-if="uiLivingDonorSummary.summaryStatus">{{ $t(uiLivingDonorSummary.summaryStatus) }}</span>
          </h3>
          <ul class="nav card-nav">
            <li class="nav-item">
              <router-link :to="{ name: 'edit-living-donor', params: { id: uiLivingDonorSummary.clientId } }" class="nav-link">{{ $t('living_donor_profile') }}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="summary-list">
        <dl>
          <dt>{{ $t('client_id') }}</dt>
          <dd>{{ uiLivingDonorSummary.clientId }}</dd>
        </dl>
        <dl v-if="isDateOfBirthEnabled">
          <dt>{{ $t('dob') }}</dt>
          <dd>{{ uiLivingDonorSummary.dateOfBirth || '-' }}</dd>
        </dl>
        <dl v-if="isDateOfBirthEnabled">
          <dt>{{ $t('age') }}</dt>
          <dd>{{ uiLivingDonorSummary.age != null ? uiLivingDonorSummary.age : '-' }}</dd>
        </dl>
        <dl v-if="isSexEnabled">
          <dt>{{ $t('sex') }}</dt>
          <dd>{{ uiLivingDonorSummary.sex || '-' }}</dd>
        </dl>
        <dl v-if="isBloodTypeEnabled">
          <dt>{{ $t('abo') }}</dt>
          <dd>{{ uiLivingDonorSummary.bloodType || '-' }}</dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter } from 'vuex-facing-decorator';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Prop } from 'vue-facing-decorator';
import DiscontinueOrgansModal from '@/components/allocations/_DiscontinueOrgansModal.vue';
import { Hospital } from '@/store/hospitals/types';
import OrganIcon from '@/components/shared/OrganIcon.vue';
import { UILivingDonor } from "@/UIModels/livingDonor";
import { UILivingDonorSummary } from "@/UIModels/livingDonors/livingDonorSummary";
import { useCurrentPageStore } from '@/stores/currentPage';
import { DATA_BUNDLES } from "@/UIModels/configuration/features";
import { i18nMessages } from '@/i18n';
import LoadingSummaryCard from '@/components/shared/loading-skeletons/LoadingSummaryCard.vue';

@Component({
   components: {
    LoadingSummaryCard,
    SubSection,
    OrganIcon,
    DiscontinueOrgansModal
   },
   ...i18nMessages([
    require('@/components/livingDonors/_locales/common.json'),
    require('@/components/livingDonors/_locales/livingDonorSummary.json')
  ]),
})
export default class LivingDonorSummary extends mixins(DateUtilsMixin) {
  //Getters
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter('getOntarioHospitalById', { namespace: 'hospitals' }) private getOntarioHospitalById!: (hospitalId?: string|null) => Hospital|null;
  @Getter('getHospitalAbbreviation', { namespace: 'hospitals' }) getHospitalAbbreviation!: (hospitalCode?: string|null) => string|null;
  @Getter('organName', { namespace: 'lookups' }) organNameLookup!: (organCode?: number) => string;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('getDonorAge', { namespace: 'livingDonors' }) getDonorAge!: number|undefined;

  @Prop({ default: '' }) inputClass!: string; // Class string for list

  public lookupsToLoad = [];
  private isLoadingLookups = true;

  get uiLivingDonor(): UILivingDonor {
    return useCurrentPageStore().currentLivingDonor as UILivingDonor;
  }

  get livingDonorDisplayName(): string {
    return useCurrentPageStore().currentLivingDonor?.displayName || '';
  }

  get uiLivingDonorSummary(): UILivingDonorSummary|null {
    if (!this.uiLivingDonor) return null;

    return this.uiLivingDonor.summary as UILivingDonorSummary;
  }

  get isDateOfBirthEnabled(): boolean {
    return useCurrentPageStore().configuration.features.livingDonorConfig.demographics.bundles.isEnabled(DATA_BUNDLES.PatientDOBAge);
  }

  get isSexEnabled(): boolean {
    return useCurrentPageStore().configuration.features.livingDonorConfig.demographics.bundles.isEnabled(DATA_BUNDLES.PatientSex);
  }

  get isBloodTypeEnabled(): boolean {
    return useCurrentPageStore().configuration.features.livingDonorConfig.livingDonorGeneralClinical.bloodType.enabled;
  }
}
</script>
