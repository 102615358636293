import { APIJourneyDetailsConfig, APIJourneyOverviewConfig, APIPhaseStatusConfig } from "@/APIModels/configuration/features/types";
import { UIFeatureConfig } from "@/UIModels/configuration/features";

export class UIJourneyOverviewConfig {
  public enabled = false;
  public journeyDetails = new UIJourneyDetailsConfig();
  public phaseStatus = new UIPhaseStatusConfig();

  public constructor(apiSource?: APIJourneyOverviewConfig) {
    if (apiSource?.enabled) {
      this.enabled = apiSource.enabled;
      this.journeyDetails = new UIJourneyDetailsConfig(apiSource.journey_details);
      this.phaseStatus = new UIPhaseStatusConfig(apiSource.phase_status);
    }
  }
}

export class UIJourneyDetailsConfig {
  public enabled = false;
  public urgentListing = new UIFeatureConfig();
  public referredWith = new UIFeatureConfig();

  public constructor(apiSource?: APIJourneyDetailsConfig) {
    if (apiSource?.enabled) {
      this.enabled = apiSource.enabled;
      this.urgentListing = new UIFeatureConfig(apiSource.urgent_listing);
      this.referredWith = new UIFeatureConfig(apiSource.referred_with);
    }
  }
}

export class UIPhaseStatusConfig {
  public enabled = false;
  public workflowStep = new UIFeatureConfig();

  public constructor(apiSource?: APIPhaseStatusConfig) {
    if (apiSource?.enabled) {
      this.enabled = apiSource.enabled;
      this.workflowStep = new UIFeatureConfig(apiSource.workflow_step);
    }
  }
}
