<template>
  <div class="nav-main">
    <nav class="navbar navbar-expand-lg p-0">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#nav-main"
        aria-controls="nav-main"
        aria-expanded="false"
        @click.prevent="toggleMenu"
        :aria-label="$t('toggle_navigation')"
      >
        <span class="far fa-w fa-hx fa-bars">
          <font-awesome-icon :icon="['far', 'bars']" />
        </span>
      </button>
      <div id="nav-main" class="collapse navbar-collapse" role="navigation" v-if="mainMenuOpen" @click.prevent="toggleMenu">
        <router-link class="nav-link" active-class="active" :to="{ name: 'index' }" exact>
          {{ $t('dashboard') }}
        </router-link>

        <router-link class="nav-link" active-class="active" :to="{ name: 'proto-recipients' }" v-if="isProtoRecepientListingsEnabled" @click.prevent="toggleMenu">
          PATIENTS
        </router-link>
        <router-link class="nav-link" active-class="active" :to="{ name: 'list-recipients' }" v-else-if="showListRecipients" @click.prevent="toggleMenu">
          {{ $t('recipients') }}
        </router-link>

        <router-link class="nav-link" active-class="active" :to="{ name: 'list-living-donors' }" v-if="showListLivingDonors" @click.prevent="toggleMenu">
          {{ $t('living_donors') }}
        </router-link>

        <router-link class="nav-link" active-class="active" :to="{ name: 'proto-prioritization-report' }" v-if="isProtoPrioritizationReportEnabled" @click.prevent="toggleMenu">
          PRIORITIZATION REPORT
        </router-link>
      </div>
    </nav>
  </div>
</template>

<script lang="ts">
import { Getter } from 'vuex-facing-decorator';
import { Component, Vue } from 'vue-facing-decorator';
import { EP } from '@/api-endpoints';
import { i18nMessages } from '@/i18n';
import { useCurrentPageStore } from '@/stores/currentPage';
import { PROTOTYPES } from '@/UIModels/prototypes/list';

@Component({
  ...i18nMessages([
    require('../_locales/MainMenu.json'),
  ]),
})
export default class MainMenu extends Vue {
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;

  get isProtoRecepientListingsEnabled(): boolean {
      return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.ProtoRecipientsListings);
    }


  public mainMenuOpen = false;

  // Lifecycle events
  private mounted(): void {
    this.mainMenuOpen = window.innerWidth >= 992;
    window.addEventListener('resize', this.checkWindowWidth);
  }

  // Event listeners
  private checkWindowWidth(): void {
    // set to true if inner width greater than 992
    this.mainMenuOpen = window.innerWidth >= 992;
  }

  get showListRecipients(): boolean {
    return this.checkAllowed(EP.recipients.create, 'GET');
  }

  get showListLivingDonors(): boolean {
    return this.checkAllowed(EP.living_donors.create, 'GET');
  }

  get isProtoPrioritizationReportEnabled(): boolean {
      return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.PrioritizationReport);
    }

  public toggleMenu(): void {
    if (window.innerWidth < 992) { 
      this.$emit('toggle');
      this.mainMenuOpen = !this.mainMenuOpen;
    }
  }
}
</script>
