import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "legend-title"
}
const _hoisted_2 = {
  key: 1,
  class: "legend-title"
}
const _hoisted_3 = { class: "row d-flex" }
const _hoisted_4 = { class: "measurements-form-group" }
const _hoisted_5 = { class: "measurements-form-group" }
const _hoisted_6 = { class: "row d-flex" }
const _hoisted_7 = { class: "measurements-form-group measureUnits" }
const _hoisted_8 = { class: "inner" }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = { class: "measurements-form-group measureUnits last" }
const _hoisted_12 = { class: "inner" }
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = { class: "form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_input = _resolveComponent("date-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_number_input = _resolveComponent("number-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_openBlock(), _createBlock(_component_validation_observer, { ref: "validations" }, {
    default: _withCtx(() => [
      _createVNode(_component_form_layout, {
        disabled: !_ctx.enableForm,
        "form-id": "measurement_form"
      }, {
        title: _withCtx(() => [
          _createElementVNode("legend", null, [
            (!_ctx.selection?.isNew)
              ? (_openBlock(), _createElementBlock("h5", _hoisted_1, _toDisplayString(_ctx.$t('selected_measurement')), 1))
              : (_openBlock(), _createElementBlock("h5", _hoisted_2, _toDisplayString(_ctx.$t('new_measurement')), 1))
          ])
        ]),
        contents: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_date_input, {
                ruleKey: "recipient_measurements.date",
                inputId: "gci-measurements-date",
                name: _ctx.$t('measurement_date'),
                modelValue: _ctx.editState.date,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.date) = $event))
              }, null, 8, ["name", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_text_input, {
                inputId: "gci-measurements-bmi",
                name: _ctx.$t('bmi'),
                modelValue: _ctx.editState.bmi,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.bmi) = $event)),
                calculated: true,
                disabled: true
              }, null, 8, ["name", "modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('metric')), 1),
                  _createVNode(_component_number_input, {
                    ruleKey: "recipient_measurements.weight_kg",
                    inputId: "gci-measurements-weight_kg",
                    name: _ctx.$t('weight'),
                    append: true,
                    appendText: "kg",
                    step: "0.1",
                    calculated: true,
                    calculatedText: "",
                    onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.calculateMeasurementFromKg($event?.target?.value))),
                    modelValue: _ctx.editState.weight_kg_rounded,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.weight_kg_rounded) = $event))
                  }, null, 8, ["name", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_number_input, {
                    ruleKey: "recipient_measurements.height_cm",
                    inputId: "gci-measurements-height_cm",
                    name: _ctx.$t('height'),
                    append: true,
                    appendText: "cm",
                    step: "0.1",
                    calculated: true,
                    calculatedText: "",
                    onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.calculateMeasurementFromCm($event?.target?.value))),
                    modelValue: _ctx.editState.height_cm_rounded,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editState.height_cm_rounded) = $event))
                  }, null, 8, ["name", "modelValue"])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('imperial')), 1),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_number_input, {
                    inputId: "gci-measurements-weight_lbs",
                    name: _ctx.$t('weight'),
                    append: true,
                    appendText: "lbs",
                    step: "0.1",
                    calculated: true,
                    calculatedText: "",
                    onChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.calculateMeasurementFromLbs($event?.target?.value))),
                    modelValue: _ctx.editState.weight_lbs_rounded,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editState.weight_lbs_rounded) = $event))
                  }, null, 8, ["name", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_number_input, {
                    inputId: "gci-measurements-height_in",
                    name: _ctx.$t('height'),
                    append: true,
                    appendText: "in",
                    step: "0.1",
                    calculated: true,
                    calculatedText: "",
                    onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.calculateMeasurementFromIn($event?.target?.value))),
                    modelValue: _ctx.editState.height_in_rounded,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editState.height_in_rounded) = $event))
                  }, null, 8, ["name", "modelValue"])
                ])
              ])
            ])
          ])
        ]),
        save: _withCtx(() => [
          _createVNode(_component_save_toolbar, {
            show: _ctx.showSaveToolbar,
            ref: "saveMeasurement",
            class: "card-footer action-row temp-saving row",
            label: _ctx.$t('save_measurement'),
            cancelButton: true,
            onSave: _cache[10] || (_cache[10] = ($event: any) => (_ctx.handleSave())),
            onCancel: _cache[11] || (_cache[11] = ($event: any) => (_ctx.handleCancel()))
          }, null, 8, ["show", "label"])
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    _: 1
  }, 512))
}