import { APIBloodType } from '@/APIModels/recipients/types';

export class UIBloodType {
  public apiSource: APIBloodType|null = null;

  public type: string|null = null;
  public sub_type: string|null = null;
  public rh_indicator: string|null = null;

  private loaded = false;

  // Define new UI view model structure
  public constructor(apiBloodType: APIBloodType|null = null) {
    if (apiBloodType) this.updateFromAPIResponse(apiBloodType);
  }

  // Map from API data structure to UI model structure
  public updateFromAPIResponse(apiBloodType: APIBloodType) {
    this.apiSource = apiBloodType;
    this.type = apiBloodType.type || null;
    this.sub_type = apiBloodType.sub_type || null;
    this.rh_indicator = apiBloodType.rh_indicator || null;

    this.loaded = true;
  }

  // Reset sub type when type is changed
  public clearSubType(): void {
    this.sub_type = null;
  }

  // Generate request payload parameters to provide to API for a Recipient Death patch
  public extractPatch(): APIBloodType {
    const result: APIBloodType = {
      type: this.type || null,
      sub_type: this.sub_type || null,
      rh_indicator: this.rh_indicator || null,
    };

    return result;
  }
}
