import { APIFeatureConfig, APIGeneralClinicalConfig, APIRecipientConfig } from '@/APIModels/configuration/features/types';
import { UIFeatureConfig } from '@/UIModels/configuration/features';
import { UIDemographicsConfig } from '@/UIModels/configuration/features/recipientConfig/demographics';
import { UIContactInfoConfig } from '@/UIModels/configuration/features/recipientConfig/contactInfo';

export class UIRecipientConfig {
  public enabled = false;
  public importPatients = new UIFeatureConfig();
  public demographics = new UIDemographicsConfig();
  public contactInfo = new UIContactInfoConfig();
  public recipientDocuments = new UIFeatureConfig();
  public recipientGeneralClinical = new UIRecipientGeneralClinicalConfig();
  public recipientSerology = new UIFeatureConfig();
  public recipientDeath = new UIFeatureConfig();
  public recipientEgfr = new UIFeatureConfig();
  public recipientDialysis = new UIFeatureConfig();

  public constructor(apiSource?: APIRecipientConfig) {
    if (apiSource?.enabled) {
      this.importPatients = new UIFeatureConfig(apiSource.import_patients);
      this.demographics = new UIDemographicsConfig(apiSource.demographics);
      this.contactInfo = new UIContactInfoConfig(apiSource.contact_info);
      this.recipientDocuments = new UIFeatureConfig(apiSource.recipient_documents);
      this.recipientGeneralClinical = new UIRecipientGeneralClinicalConfig(apiSource.general_clinical);
      this.recipientSerology = new UIFeatureConfig(apiSource.virology);
      this.recipientDeath = new UIFeatureConfig(apiSource.death);
      this.recipientEgfr = new UIFeatureConfig(apiSource.egfr);
      this.recipientDialysis = new UIFeatureConfig(apiSource.dialysis);
    }
  }
}

export class UIRecipientGeneralClinicalConfig {
  public enabled = false;
  public bloodType = new UIFeatureConfig();
  public measurements = new UIFeatureConfig();

  public constructor(apiSource?: APIGeneralClinicalConfig) {
    if (apiSource?.enabled) {
      this.enabled = apiSource.enabled;
      this.bloodType = new UIFeatureConfig(apiSource.blood_type);
      this.measurements = new UIFeatureConfig(apiSource.measurements);
    }
  }
}

