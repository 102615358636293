<template>
  <div>
    <loading-recipient-page v-if="!dispatchEventsComplete" />
    <template v-else>
      <page-top>
        <list-recipients-link />
        / {{recipientDisplayName}}
      </page-top>
      <recipient-sticky-summary />
      <div class="content-wrap">
        <div class="container-fluid">
          <div class="nav-wrapper">
            <side-nav-recipient-profile />
            <div class="page-content">
              <recipient-summary />
              <!-- Card Sections -->
              <demographics
                ref="demographics"
                @loaded="(ref) => loaded(ref)"
              />
              <contact-information
                ref="contactInfo"
                @loaded="(ref) => loaded(ref)"
              />
              <proto-care-givers />

              <proto-communication-information />
              
              <proto-transportation-and-mobility />

              <proto-pace-and-engagement />

              <proto-scheduling-preferences />
             
              <recipient-documents
                ref="recipientDocuments"
                v-if="isRecipientDocumentsEnabled"
                @loaded="(ref) => loaded(ref)"
              />
              <general-clinical-information
                v-if="isRecipientGeneralClinicalEnabled"
                ref="generalClinical"
                @loaded="(ref) => loaded(ref)"
              />
              <recipient-serology
                ref="serology"
                v-if="isRecipientSerologyEnabled"
                @loaded="(ref) => loaded(ref)"
              />
              <recipient-egfr-dialysis
                ref="egfrDialysis"
                v-if="isRecipientEgfrEnabled || isRecipientDialysisEnabled"
                @loaded="(ref) => loaded(ref)"
              />

              <proto-patient-history/>
              
              <recipient-death
                ref="death"
                v-if="isRecipientDeathEnabled"
                @loaded="(ref) => loaded(ref)"
              />
              <!-- Living Donor Cards -->
                <ProtoLDContactInformation :canSave="true" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-facing-decorator";
import PageTop from '@/components/shared/PageTop.vue';
import { Component } from 'vue-facing-decorator';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import Demographics from '@/components/recipients/Demographics.vue';
import RecipientSummary from '@/components/recipients/RecipientSummary.vue';
import ContactInformation from '@/components/recipients/ContactInformation.vue';
import GeneralClinicalInformation from '@/components/recipients/GeneralClinicalInformation.vue';
import VirologyResults from '@/components/virology/VirologyResults.vue';
import SideNavRecipientProfile from '@/components/recipients/side-nav/SideNavRecipientProfile.vue';
import RecipientStickySummary from '@/components/recipients/RecipientStickySummary.vue';
import RecipientDeath from "@/components/recipients/RecipientDeath.vue";
import RecipientDocuments from '@/components/recipients/RecipientDocuments.vue';
import RecipientSerology from '@/components/recipients/RecipientSerology.vue';
import RecipientEgfrDialysis from '@/components/recipients/RecipientEgfrDialysis.vue';
import LoadingSideNav from '@/components/shared/loading-skeletons/LoadingSideNav.vue';
import { useCurrentPageStore, setPageTitle } from '@/stores/currentPage';
import { i18nMessages } from '@/i18n';
import LoadingRecipientPage from '@/components/shared/loading-skeletons/LoadingRecipientPage.vue';
import ProtoTransportationAndMobility from '@/prototypes/profile/ProtoTransportationAndMobility.vue';
import ProtoCommunicationInformation from '@/prototypes/profile/ProtoCommunicationInformation.vue';
import ProtoPaceAndEngagement from '@/prototypes/profile/ProtoPaceAndEngagement.vue';
import ProtoCareGivers from '@/prototypes/profile/ProtoCareGivers.vue';
import ProtoSchedulingPreferences from '@/prototypes/profile/ProtoSchedulingPreferences.vue';
import ProtoPatientHistory from '@/prototypes/profile/ProtoPatientHistory.vue';
import ListRecipientsLink from '@/components/shared/page-top/ListRecipientsLink.vue';
import { UIRecipient } from "@/UIModels/recipient";

// Living Donor Cards
import ProtoLDContactInformation from '@/prototypes/livingDonor/ProtoLDContactInformation.vue';

@Component({
  components: {
    PageTop,
    ListRecipientsLink,
    SaveToolbar,
    Demographics,
    RecipientDeath,
    RecipientSerology,
    RecipientEgfrDialysis,
    VirologyResults,
    RecipientSummary,
    ContactInformation,
    SideNavRecipientProfile,
    GeneralClinicalInformation,
    RecipientStickySummary,
    LoadingSideNav,
    RecipientDocuments,
    LoadingRecipientPage,
    ProtoTransportationAndMobility,
    ProtoCommunicationInformation,
    ProtoPaceAndEngagement,
    ProtoCareGivers,
    ProtoSchedulingPreferences,
    ProtoPatientHistory,
    ProtoLDContactInformation
  },
  ...i18nMessages([
    require('../_locales/common.json'),
  ]),
})
export default class EditRecipient extends Vue {
  private dispatchEventsComplete = false;
  private sectionsLoaded = new Set();
  private allSectionsLoaded = false;

  get uiRecipient(): UIRecipient {
    return useCurrentPageStore().currentRecipient as UIRecipient;
  }

  get recipientDisplayName(): string {
    return useCurrentPageStore().currentRecipient?.displayName || '';
  }

  get isContactInfoEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.contactInfo.enabled;
  }

  get isRecipientDocumentsEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.recipientDocuments.enabled;
  }

  // Check if Recipient General Clinical is enabled
  get isRecipientGeneralClinicalEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.recipientGeneralClinical.enabled;
  }

  // Check if Recipient Serology is enabled
  get isRecipientSerologyEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.recipientSerology.enabled;
  }

  // Check if Recipient Egfr is enabled
  get isRecipientEgfrEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.recipientEgfr.enabled;
  }

  // Check if Recipient Dialysis is enabled
  get isRecipientDialysisEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.recipientDialysis.enabled;
  }

  // Check if Recipient Death is enabled
  get isRecipientDeathEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.recipientDeath.enabled;
  }

  // Vue lifecycle hooks
  public async mounted(): Promise<void> {
    const recipientIdentifier = this.uiRecipient.clientId as string;
    if (!recipientIdentifier) {
      console.warn('missing recipient client_id');
    }
    await useCurrentPageStore().currentRecipient?.load({ reload: false });
    setPageTitle(this.$t('page.recipient.edit', { recipient: this.recipientDisplayName })),
    Promise.all([
      this.$store.dispatch('validations/loadEdit', { view: 'recipients', action: 'edit', clientId: recipientIdentifier }),
    ]).finally(() => {
      this.dispatchEventsComplete = true;
    });
  }

  get isLoaded(): boolean {
    return this.allSectionsLoaded;
  }

  public loaded(ref: string): void {
    if (!ref) return;
    // Create a set of all the sections to load filtering out sections we don't care about
    const sectionsToLoad = new Set(Object.keys(this.$refs).filter((ref: string) => !ref.match(/validations|saveRecipient|potentialDuplicateModal/)));
    // Add the ref we just loaded
    this.sectionsLoaded.add(ref);
    if (this.sectionsLoaded.size === sectionsToLoad.size) {
      this.$store.dispatch('utilities/scrollBehavior');
      this.allSectionsLoaded = true;
    }
  }
}
</script>
