import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "adaptive-top-scrollbar",
  ref: "adaptiveTopScrollbar"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "p-column-title"
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { key: 2 }
const _hoisted_6 = ["onClick", "title"]
const _hoisted_7 = { key: 3 }
const _hoisted_8 = ["href", "title"]
const _hoisted_9 = { class: "bold-link" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { key: 4 }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { key: 5 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Column = _resolveComponent("Column")!
  const _component_more_less = _resolveComponent("more-less")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_TablePaginator = _resolveComponent("TablePaginator")!

  return (_ctx.tableConfig && _ctx.tableConfig.columns)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["mb-3", _ctx.getStyleClass()])
      }, [
        (_ctx.showTopScrollBar)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[3] || (_cache[3] = [
              _createElementVNode("div", null, null, -1)
            ]), 512))
          : _createCommentVNode("", true),
        _createVNode(_component_DataTable, {
          id: _ctx.tableId,
          ref: _ctx.tableId,
          responsiveLayout: "stack",
          showGridlines: true,
          value: _ctx.paginatedRows,
          loading: _ctx.isLoading,
          rowKey: "id",
          lazy: _ctx.isLazy,
          class: _normalizeClass({'scrollbox': true, 't-horizontal-view': this.horizontalScroll}),
          totalRecords: _ctx.getNumberOfRecords,
          onRowClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onRowClick($event))),
          expandedRows: _ctx.paginatedRows,
          first: _ctx.pageOffset,
          rows: _ctx.pageSize,
          rowClass: _ctx.rowStyle,
          rowGroupMode: _ctx.getGroupMode,
          groupRowsBy: _ctx.getGroupBy,
          "filter-display": _ctx.filterDisplay,
          "show-filter-menu": false,
          "sort-mode": _ctx.sortMode,
          "null-sort-order": -1,
          "multi-sort-meta": _ctx.multiSortMeta,
          "removable-sort": true,
          onSort: _ctx.onSort
        }, _createSlots({
          groupheader: _withCtx(() => [
            _cache[4] || (_cache[4] = _createElementVNode("span", { "aria-hidden": "true" }, " ", -1))
          ]),
          empty: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: ['far', 'exclamation-circle'],
              "fixed-width": ""
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.tableConfig.empty || _ctx.$t('use_form_below_new_row')), 1)
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableConfig.columns, (col) => {
              return (_openBlock(), _createElementBlock(_Fragment, null, [
                (!col.expanded && !col.hidden)
                  ? (_openBlock(), _createBlock(_component_Column, {
                      field: col.field,
                      class: _normalizeClass(col.class),
                      header: col.label,
                      sortable: col.sortable || false,
                      key: col.field,
                      style: _normalizeStyle(_ctx.buildColumnStyle(col)),
                      bodyClass: col.tdClass,
                      sortField: col.sortField
                    }, {
                      filter: _withCtx(() => [
                        (col.filterOptions)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                              (col.filterOptions.type == 'text')
                                ? (_openBlock(), _createBlock(_component_text_input, {
                                    key: 0,
                                    "input-id": `${col.field}_filter`,
                                    name: col.label,
                                    "hide-label": true,
                                    "model-value": _ctx.searchParams[col.field],
                                    "onUpdate:modelValue": (event) => _ctx.onFilter(event, col)
                                  }, null, 8, ["input-id", "name", "model-value", "onUpdate:modelValue"]))
                                : (col.filterOptions.type == 'dropdown')
                                  ? (_openBlock(), _createBlock(_component_select_input, {
                                      key: 1,
                                      "select-id": `${col.field}_filter`,
                                      name: col.label,
                                      "hide-label": true,
                                      "model-value": _ctx.searchParams[col.field],
                                      "onUpdate:modelValue": (event) => _ctx.onFilter(event, col),
                                      options: col.filterOptions.filterDropdownItems,
                                      undefinedText: col.filterOptions.placeholder
                                    }, null, 8, ["select-id", "name", "model-value", "onUpdate:modelValue", "options", "undefinedText"]))
                                  : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      body: _withCtx((slotProps) => [
                        (_ctx.isMobile)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(col.label), 1))
                          : _createCommentVNode("", true),
                        (col.html)
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 1,
                              innerHTML: slotProps.data[col.field]
                            }, null, 8, _hoisted_4))
                          : (col.download)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                                _createElementVNode("a", {
                                  href: "#",
                                  onClick: _withModifiers(($event: any) => (_ctx.onDownloadClick(slotProps.data.downloadParams)), ["prevent","stop"]),
                                  title: col.tooltip
                                }, [
                                  _createVNode(_component_font_awesome_icon, {
                                    class: "text-grey mr-2",
                                    icon: ['far', 'download'],
                                    size: "sm",
                                    "aria-hidden": "true",
                                    "fixed-width": ""
                                  }),
                                  _createTextVNode(" " + _toDisplayString(slotProps.data[col.field]), 1)
                                ], 8, _hoisted_6)
                              ]))
                            : (col.htmlLink)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                                  (slotProps.data[col.field])
                                    ? (_openBlock(), _createElementBlock("a", {
                                        key: 0,
                                        href: slotProps.data[col.field],
                                        title: slotProps.data[col.field],
                                        target: "_blank"
                                      }, [
                                        _createVNode(_component_font_awesome_icon, {
                                          class: "text-grey mr-2",
                                          icon: ['far', 'external-link'],
                                          size: "sm",
                                          "aria-hidden": "true",
                                          "fixed-width": ""
                                        }),
                                        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('follow_link')), 1)
                                      ], 8, _hoisted_8))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_10, "-"))
                                ]))
                              : (_ctx.labelMatchesTabbableColumn(col.field))
                                ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                                    (_ctx.firstColumnRouterName)
                                      ? (_openBlock(), _createBlock(_component_router_link, {
                                          key: 0,
                                          to: { name: _ctx.firstColumnRouterName, params: { id: slotProps.data.id } }
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.renderSlotField(slotProps.data, col)), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["to"]))
                                      : (_openBlock(), _createElementBlock("a", {
                                          key: 1,
                                          href: "#",
                                          tabindex: "0",
                                          class: "table-link",
                                          onClick: _withModifiers(($event: any) => (_ctx.onRowClick(slotProps)), ["stop","prevent"])
                                        }, _toDisplayString(_ctx.renderSlotField(slotProps.data, col)), 9, _hoisted_12))
                                  ]))
                                : (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.renderSlotField(slotProps.data, col)), 1))
                      ]),
                      _: 2
                    }, 1032, ["field", "class", "header", "sortable", "style", "bodyClass", "sortField"]))
                  : _createCommentVNode("", true)
              ], 64))
            }), 256))
          ]),
          _: 2
        }, [
          (_ctx.getNameOfExpandedField)
            ? {
                name: "expansion",
                fn: _withCtx((slotProps) => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_more_less, {
                      descriptionText: slotProps.data[_ctx.getNameOfExpandedField]
                    }, null, 8, ["descriptionText"])
                  ])
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["id", "value", "loading", "lazy", "class", "totalRecords", "expandedRows", "first", "rows", "rowClass", "rowGroupMode", "groupRowsBy", "filter-display", "sort-mode", "multi-sort-meta", "onSort"]),
        _createVNode(_component_TablePaginator, {
          id: `${_ctx.tableId}-paginator`,
          pageOffset: _ctx.pageOffset,
          pageSize: _ctx.pageSize,
          numberOfRecords: _ctx.getNumberOfRecords,
          perPageDropdown: _ctx.perPageDropdown,
          onOnPageNumber: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onPageNumber($event))),
          onOnPageSize: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onPageSize($event))),
          jumpToPage: _ctx.jumpToPage
        }, null, 8, ["id", "pageOffset", "pageSize", "numberOfRecords", "perPageDropdown", "jumpToPage"])
      ], 2))
    : _createCommentVNode("", true)
}