<template>
  <validation-provider
    ref="provider"
    :rules="formRules"
    :name="selectId"
    :label="name"
    :vid="validationId ? validationId : selectId"
    v-model="validatedValue"
    v-slot="{ field, errors }">
    <label :for="selectId" :class="{ 'sr-only': hideLabel }">
      {{label || name}}
      <!-- don't show asterisk if disabled -->
      <validation-asterisk
        v-if="!disabled" 
        :rules=formRules 
        :crossValues="crossValues" 
        :ruleKey="ruleKey"/>
      <slot name="link" />
    </label>
    <!-- masked input -->
    <span class="mobile-spacing-wrapper">
      <input
        v-if="isMasked"
        type="text"
        :class="getClassObject(errors)"
        :id="selectId"
        :tabindex="-1"
        v-bind="field"
      />
      <template v-else>
        <!-- single select input -->
        <select
          v-if="!multiple"
          :class="getClassObject(errors)"
          :id="selectId"
          :title="selectTitleValue"
          v-bind="field"
          :readonly="isReadOnly(readonly || disabled)"
          v-on="selectEvents()">

          <!-- null option (e.g. 'Select...') -->
          <!-- Vue 3 does not support setting attributes to null any more, so here we use empty string internally -->
          <!-- NOTE: when selected, the value emitted for v-model externally will be 'null' -->
          <option v-if="!disabled && nullable" value="">
            {{ placeholder ? placeholder : undefinedText || null }}
          </option>

          <!-- option list -->
          <option
            v-for="(o, idx) in selectFilteredOptions"
            :key="`${selectId}-${o[valueKey]}-${idx}`"
            :value="o[valueKey]"
            :disabled="isDisabledOption(o)"
          >
            {{o[textKey]}}
          </option>
        </select>

        <!-- multi select input -->
        <vue-tags-input
          v-else
          :id="selectId"
          :placeholder="placeholder || (undefinedPlaceholder ? undefinedText : null)"
          :add-on-key="[13, ' ']"
          :allow-edit-tags="false"
          :autocomplete-min-length="0"
          :add-only-from-autocomplete="true"
          :separators="[' ']"
          v-model="tag"
          :validation="getTagValidationRule()"
          :autocomplete-items="getAutocompleteOptions"
          @tags-changed="(updatedTagObjects) => updateVModel(updatedTagObjects)"
          @before-adding-tag="(event) => beforeAddingTag(event)"
          :disabled="isReadOnly(readonly||disabled)"
          :tags="tags"
          :class="getClassObject(errors)"
          tagClass="invalid-tag"
          :max-tags="maxTags"
          @blur="handleBlur"
        />
          
      </template>

      <template v-if="isLoadingSelectionValues">
        <font-awesome-icon style="float: right;top: -26px;position: relative;right: 24px;margin-bottom: -26px;" :icon="['far', 'spinner-third']" fixed-width spin aria-hidden="true" />
      </template>
      
      <!-- error block, not shown if disabled -->
      <div
        v-if="errors[0] && !disabled"
        class="invalid-feedback"
        :id="`${selectId}-error`"
      >
        <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
        {{ translateError(errors, label || name) }}
      </div>
    </span>
  </validation-provider>
</template>

<script lang="ts">
import { ClassObject } from '@/types';
import { mergeClasses, isMasked } from '@/utils';
import { GenericCodeValue } from '@/store/types';
import { NumericCodeValue } from '@/store/types';
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator';
import { Getter } from 'vuex-facing-decorator';
import { TagObject } from '@/store/utilities/types';
import { Rules } from '@/store/validations/types';
import ValidationAsterisk from '@/components/shared/ValidationAsterisk.vue';
import { VueTagsInput, createTags } from '@vojtechlanka/vue-tags-input';

@Component({
  components: {
    ValidationAsterisk,
    VueTagsInput,
  },
  emits: [
    'change',
    'update:modelValue',
  ],
})
export default class SelectInput extends Vue {
  // vue-tag notes:
  // - v-model = used by vue tag for user input, cleared when a tag is added
  // - tags = tag's user has chosen
  // - autocomplete-items = tags user can choose
  // - validation = generates are rule vue-tags can apply to user input (sent as an array)
  // - tags-changed = sends when the tag's the user has chosen change
  // - before-adding-tag = sent before the tag is added to 'tags'

  @Getter('getRuleSet', { namespace: 'validations' }) private ruleSet!: Rules;
  @Getter('getRules', { namespace: 'validations' }) private getRules!: (ruleSet: any, ruleKey: string, rules: string, idLookupidLookup: {[key: string]: string}|null) => any;
  @Getter('isReadOnly', { namespace: 'validations' }) private isReadOnly!: (readonly?: any) => boolean;
  @Getter('translateError', { namespace: 'utilities' }) private translateError!: (error?: any, field?: string|null) => string;
  @Getter('getTagsFromLookup', { namespace: 'utilities' }) getTagsFromLookup!: (lookup: any[]) => TagObject[];
  @Getter('isExpired', { namespace: 'utilities' }) private isExpired!: (expired_date?: string|undefined) => boolean;

  // V-model
  @Prop() modelValue!: string|string[];

  // Standard properties
  @Prop({ required: true }) selectId!: string; // Set the ID
  @Prop({ required: true }) name!: string; // Set the label and name property
  @Prop({ required: true }) options!: any[]; // Enumerable data for building the options
  @Prop({ required: false }) validation!: any

  // Optional properties
  @Prop({ default: true }) nullable!: boolean // Whether a null option will be displayed in the dropdown
  @Prop({ default: false }) hasLookup!: boolean // Whether the options are from a lookup or not
  @Prop({ default: null }) lookup!: any // if hasLookup == true, this should contain the lookup data to use
  @Prop({ default: null }) idLookup!: {[key: string]: string}|null  // if hasLookup == true, this should contain the lookup data to use

  @Prop({ default: null }) placeholder!: string
  @Prop({ default: null }) validationId!: string; // OPTIONAL specify a 'vid' property for validation-provider, if it must be different than the element ID
                                                  // used by parent component after attempting to save to decide where server-side validation errors are shown
  @Prop({ default: null }) label!: string; // Optional label value
  @Prop({ default: false }) disabled!: boolean; // Turn input data entry off
  @Prop({ default: 'Not Applicable' }) nullText!: string; // Label used for null value
  @Prop({ default: 'value' }) textKey!: string; // Key for text displayed as option label
  @Prop({ default: 'code' }) valueKey!: string; // Key for code value associated with option
  @Prop({ default: 'Select...' }) undefinedText!: string; // Label used for null state
  @Prop({ default: true }) undefinedPlaceholder!: boolean; // If true multi-select input placeholder will show undefinedText as fallback when no placeholder defined
  @Prop({ default: false }) inline!: boolean; // Show select input inline
  @Prop({ default: false }) readonly!: boolean; // Render input as if it were plain text and turn input data entry off
  @Prop({ default: false }) multiple!: boolean; // Display multiple options at once
  @Prop({ default: false }) hideLabel!: boolean; // Hide label visually, while still being readable for screen readers
  @Prop({ default: true }) hideExpired!: boolean; // By default filter out expired options

  @Prop({ default: null }) rules!: any; // Sets the validation rules
  @Prop({ default: null }) ruleKey!: string // OPTIONAL parameter path to load client-side validation e.g. new_validations, edit_validations
                                            // used by input components to set 'rules' properties in their validation providers based on the client-side validations loaded from the back-end;getStyleDefaulting; // Key for value associated with group options
  @Prop({ default: false }) enableGroups!: boolean; // Optional, should options be treated as options or grouped options
  @Prop({ default: null }) crossValues!: any; // valus needed for cross field validation for the asterix
  @Prop({ default: false }) numeric!: boolean; // If true handle option codes internally as strings, but emit numbers

  @Prop({ default: false }) hide_restricted_permissions!: boolean; // Optional, if true, options with restricted_permissions are filtered out
  @Prop({ default: false }) isLoadingSelectionValues!: boolean; // Optional, if true, show a loading icon to indicate something is happening in the background

  @Prop({ default: 'code' }) saveWithAttribute!: string; // Optional, which attribute to build saved value from when using select-multiple
  @Prop({ default: false }) uppercase!: boolean // Optional, relates to waitlist, convert tag values uppercase if true

  @Prop({ default: 0 }) maxTags!: number // The maximum amount the tags array is allowed to hold. 0 = no limit.
  @Prop({ default: false }) filterByLookupRule!: boolean;
  @Prop({ default: '' }) defaultValue!: string|string[];

  // multi-select local storage
  tag = ''; // tag is added to on keyboard input, and cleared when the tag is added
  tags: any = [];
  programTags: any[] = [];
  tagOptions: any[] = [];

  // single-select local storage
  private validatedValue: string|string[] = this.defaultValue;

  // filter out expired options
  filterOutExpiredOptions(options: any[]): any[] {
    if (!options) return [];
    const filteredOptions = options.filter((option: any) => {
      // return non-expired items
      if (!this.isExpired(option.expired_date)) return option;
    });
    return filteredOptions;
  }

  // Validate manual entry of transplant programs to match list of included values
  getTagValidationRule(): { classes: string, rule: any }[] {
    // if no options return blank rule
    if (!this.tagOptions) return [];

    // otherwise build rule
    const _validOptions: any[] = [];

    if (this.hasCodeAttribute) {
      this.tagOptions.map((item: any) => { _validOptions.push(item.code); });
    } else {
      this.tagOptions.map((item: any) => { _validOptions.push(item.text); });
    }

    return [
      {
        classes: 'invalid-tag',
        rule: ((tag: TagObject) => {
          // if contains .code attribute, validate against it
          if (this.hasCodeAttribute) { return !_validOptions.includes(tag.code); }

          // otherwise validate against the .text attribute
          return this.uppercase ? !_validOptions.includes(tag.text.toUpperCase()) : !_validOptions.includes(tag.text);
        }),
      }
    ];
  }

  // Sanitize value to string for comparison in select options
  sanitizeSingleValue(value: string[]|string): string {
    if (!value) return '';

    const firstValue = Array.isArray(value) ? value[0] : value;
    return firstValue?.toString() || '';
  }

  // checks if options have the code attribute (used to determine which validation to use)
  get hasCodeAttribute(): boolean {
    // if no options return false
    if (!this.options) return false;

    // test for code attribute
    let hasCode = false;
    this.options.map((item: any) => {
      if (item.hasOwnProperty('code')) { hasCode = true; }
    });
    return hasCode;
  }

  // --- for drop-down version ---
  // return disabled if option has disabled or restricted_permissions
  isDisabledOption(option: NumericCodeValue): boolean {
    const disabled = option.disabled || option.restricted_permissions || this.isExpired(option.expiry_date);
    return !!disabled;
  }

  // --- for drop-down version ---
  isSelectOptionNotExpired(option: any): boolean {
    if (!this.hideExpired) return true;
    const date = option.expired_date || option.expiry_date;
    if (this.modelValue == option.code) return true;
    return !this.isExpired(date);
  }

  // masked input
  get isMasked(): boolean {
    return isMasked(`${this.modelValue}`);
  }

  private getLength(value: any): any {
    return this.modelValue.toString().length;
  }

  get formRules(): any {
    if (this.multiple) {
      const rules: any = { no_invalid_tags: { tags: this.programTags } };
      // if one of the rules is 'required' convert it to the vue-tags-input version
      if (this.rules === 'required') {
        rules.required_tags = { tags: this.programTags };
      }
      return this.getRules(this.ruleSet, this.ruleKey, rules as any, this.idLookup);
    } else {
      return this.getRules(this.ruleSet, this.ruleKey, this.rules, this.idLookup);
    }
  }

  public mounted(): void {
    if (this.multiple) {
      this.importTags();
    } else {
      // catch zero value, interpreted as null
      let value;
      if (this.sanitizeSingleValue(this.modelValue) === '0') {
        value = 0;
      } else {
        value = this.modelValue && this.getLength(this.modelValue) > 0 ? this.modelValue : this.defaultValue;
      }
      const validationProvider = this.$refs.provider as any;
      if (validationProvider) validationProvider.reset({ value });
    }
  }

  private importTags(): void {
    // clear tags
    this.tags = [];
    this.programTags = [];

    // if options don't have code attributes, just import the values otherwise covert into tag options
    if (this.hasCodeAttribute) {
      this.tagOptions = this.getTagsFromLookup(this.options) || [];
    } else {
      this.tagOptions = this.options;
    }
    // convert codes to tags
    if (this.tagOptions && this.modelValue) {

      const badInputs: string[] = []; // to store the user's bad inputs

      // first find and add all the matching values
      const selectedOptions = Array.isArray(this.modelValue) ? this.modelValue : [this.modelValue];
      selectedOptions.map((selectedOption: number|string) => {
        let found = null;
        if (this.hasCodeAttribute) {
          found = this.tagOptions.find( option => option.code === selectedOption );
        } else {
          found = this.tagOptions.find( option => option.text === selectedOption );
        }
        // if we've found something and it's an object, add it otherwise add it as a user input
        if (found && typeof found == 'object') {
          this.tags.push(found);
        } else {
          this.tags.push({ text: selectedOption });
        }
      });
    }
    this.programTags = this.tags;
  }

  // Limit dropdown options to values that match input
  get getAutocompleteOptions(): TagObject[] {
    // Filter out expired options if needed
    const options = this.hideExpired ? this.filterOutExpiredOptions(this.tagOptions) : this.tagOptions;
    // If using the 'filter by lookup rule' option, remove options not included in 'in_lookup' rule definition
    const filtedByRules = this.filterByLookupRule && this.inLookupRuleCodes.length > 0 ? options.filter((option: any) => { return this.inLookupRuleCodes.includes(option.code); }) : options;
    // If hide_restricted_permissions enabled, filter out options with restricted_permissions = true
    const filtered_options = this.hide_restricted_permissions ? filtedByRules.filter((option: any) => { return !option.restricted_permissions; }) : filtedByRules;

    const input = this.tag;
    if (!this.tag || !input) {
      return filtered_options || [];
    }
    const filtered = filtered_options.filter((option: { text: string, code: number|string }) => {
      return option.text.toLowerCase().indexOf(input.toLowerCase()) !== -1;
    });
    return filtered;
  }

  private updateVModel(tags: TagObject[]): void {
    // build new v-model value using attribute set in saveWithAttribute
    const newValue: any[] = [];
    tags.map((tag: any) => {
        // if we don't have the attribute (e.g. code) use tag.text as the value (.e.g. for user input, should fail validation)
        const value = tag[this.saveWithAttribute] ? tag[this.saveWithAttribute] : tag['text'];
        newValue.push(value);
    });
    // update v-model
    this.$emit('update:modelValue', newValue);
    this.$emit('change', newValue);

    // update program tags
    this.programTags = createTags(tags, this.getTagValidationRule());
  }

  private beforeAddingTag(event: { tag: TagObject, addTag: () => void; }): void {
    // convert text attribute to uppercase
    if (this.uppercase && event.tag.text) {
      const standardized = event.tag.text.toUpperCase();
      event.tag.text = standardized;
    }
    event.addTag();
  }

  // determine which primary class to use for the control, based on whether we're using the tag control or select-input
  get getPrimaryClass(): string {
    // if inline
    if (this.inline) return 'select-inline';
    // return default styling based on whether multi-select or single select
    return this.multiple ? 'select-multi' : 'form-control';
  }

  // Merge constant and conditional classes
  private getClassObject(errors: any[]): ClassObject {

    // if disabled, ignore setting conditional classes
    if (this.disabled) {
      return mergeClasses(this.getPrimaryClass, undefined);
    }

    // Define dynamic classes that are only sometimes present as a class object based on function arguments
    let conditionalClasses = undefined;
    if (this.inline) {
      // styling for inline select
      conditionalClasses = {
        'is-invalid': errors[0],
        'select-inline': !this.readonly,
        'select-inline-plaintext': this.readonly
      };
    } else if (this.multiple) {
      // styling for multi-select
      conditionalClasses = {
        'is-invalid': errors[0],
        'select-multi': !this.readonly,
        'select-multi-plaintext': this.readonly
      };
    } else {
      // styling for single-select
      conditionalClasses = {
        'is-invalid': errors[0],
        'form-control': !this.readonly,
        'form-control-plaintext': this.readonly
      };
    }

    // Merge the customizable class string with the dynamic classes
    return mergeClasses(this.getPrimaryClass, conditionalClasses);
  }

  // What lookup rule codes are defined for this field?
  get inLookupRuleCodes(): string[] {
    const formRules = this.formRules || '';
    const inLookupRule = formRules.match(/(?:.*)in_lookup\:((?:(?:.*),?)*)(?:.*)/);
    const result = inLookupRule ? (inLookupRule[1] || '').split(',') : [];
    return result;
  }

  // --- for drop-down version ---
  get selectFilteredOptions(): any {
    // Filter out expired options if needed
    const options = this.hideExpired ? this.filterOutExpiredOptions(this.options) : this.options;
    // If using the 'filter by lookup rule' option, remove options not included in 'in_lookup' rule definition
    const filtedByRules = this.filterByLookupRule && this.inLookupRuleCodes.length > 0 ? options.filter((option: any) => { return this.inLookupRuleCodes.includes(option.code); }) : options;
    // If hide_restricted_permissions enabled, filter out options with restricted_permissions = true
    const filtered_options = this.hide_restricted_permissions ? filtedByRules.filter((option: any) => { return !option.restricted_permissions; }) : filtedByRules;
    // Sanitize option code types if needed
    const sanitized = !this.numeric ? filtered_options : filtered_options.map((option: NumericCodeValue): GenericCodeValue => {
      return { ...option, code: option.code.toString() };
    });
    // Return sanitized filtered options
    return sanitized;
  }

  // --- for drop-down version ---
  // Return the value of the selected option for the title tag
  // (useful to see selected item when hovering over a select input)
  get selectTitleValue(): string|undefined {
    if (this.selectFilteredOptions == undefined) {
      return undefined;
    }
    const option = this.selectFilteredOptions.find((item: any) => {
      return this.validatedValue === item.code;
    });
    if (option && option.value) {
      return option.value;
    }
    return undefined;
  }

  // --- for drop-down version ---
  public selectEvents(): any {
    const _vm = this as SelectInput;
    return Object.assign({},
      // parent listeners
      this.$attrs.listeners,
      {
        // custom listeners
        change(event: any) {
          // Emit updated value for v-model
          const selectLocalValue = event.target.value != null ? event.target.value : null;
          const newValue = selectLocalValue == null || selectLocalValue === '' ? null : (_vm.numeric ? Number(selectLocalValue) : selectLocalValue);
          _vm.$emit('update:modelValue', newValue);
          _vm.$emit('change', newValue);
        }
      }
    );
  }

  // When our incoming options change we need to update the select list or we'll never see the new options
  @Watch('options')
  private onOptionsChange() {
    if ((this.options || []).length > 0) {
      // Build our options (tags) from selectFilteredOptions
      this.tagOptions = this.getTagsFromLookup(this.selectFilteredOptions);
      // refresh chosen tags
      if (this.options.length > 0 && this.multiple) { this.importTags(); }
    } else {
      this.tagOptions = [];
    }
  }

  @Watch('modelValue')
  public onValueChange() {
    if (this.multiple) {
      // if the 'value' has changed but the tag hasn't, then the parent v-model has changed, so update the control
      // otherwise it's the user inputting a value on child control so ignore it (allowing them to enter wrong values)
      this.importTags();
    } else {
      const value = (this.modelValue && this.getLength(this.modelValue) > 0) || this.sanitizeSingleValue(this.modelValue) === '0' ? this.modelValue : this.defaultValue;
      const validationProvider = this.$refs.provider as any;
      if (validationProvider) validationProvider.reset({ value });
    }
  }

  // Reset tag input value when navigating away
  private handleBlur() {
    this.tag = '';
  }
}
</script>
