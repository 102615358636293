<template>
  <span>
    <i v-if="isRequired">*</i>
  </span>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator';
import { Getter, State } from 'vuex-facing-decorator';
import '@/vee-validate-rules';
import { validate } from 'vee-validate';

export interface ValidationType {
  rules: string|null;
  crossValues: ValidationType|null;
  ruleKey: string|null;
}

export interface ValidationTag {
  no_invalid_tags?: {
    tags: string|null;
  }
  required_tags?: string|null;
}

@Component
export default class ValidationAsterisk extends Vue {
  @State(state => state.journeyState.validationErrors) validationErrors!: {[key: string]: string};

  @Getter('getBaseCrossValues', { namespace: 'validations' }) private getBaseCrossValues!: any;

  @Prop({ default: '' }) rules!: string; // Validation rules
  @Prop({ default: null }) crossValues!: any;
  @Prop({ default: '' }) ruleKey!: string|Array<string>; // api key

  isRequired = false;

  /** 
   * Convert incoming prop for rules into a string for vee-validate
   * 
   * How does this work: 
   * Incoming rules can either be a string, object, null or undefined.  This method
   * does the work or figuring out what, if any, incoming rules need to be applied.  
   * In the case of a boolean rule like required, we check if the boolean is true 
   * before adding it.  In the case of a regex rule we only need to know if the rule 
   * is an instance of a RegExp.  In the case of a string we need to make sure there 
   * is a value for the rule.
   * 
   */ 
  convertObjectRules(rules: any): string {
    // Most fields have null or undefined rules
    if (rules == null) return "";
    // If the incoming rule is an object
    if (typeof rules === 'object') {
      // For each rule, check if it should should be included and pass any rule options along
      const filteredRules = Object.keys(rules).map((rule: string) => {
        // Boolean rules are included if true as a string with the rule e.g. 'required'
        if (typeof rules[rule] === 'boolean' && rules[rule]) return rule;
        // RegExp rules are included if the rule is an instance of RegExp e.g. 'regex:/^[A-Z]$/'
        if (rules[rule] instanceof RegExp) return `regex:${rules[rule]}`;
        // String rules should have a value e.g. 'digits:12'
        if (typeof rules[rule] === 'string' && !!rules[rule]) return `${rule}:${rules[rule]}`;
      }).filter((rule: string|undefined) => rule);
      return filteredRules.length > 0 ? filteredRules.join('|') : "";
    } else {
      return rules || "";
    }
  }

  // Check if key exists in nested object and if the validation message includes 'required'
  propExistsAndRequired(obj: any, path: string): boolean {
    // get array of keys
    const keys = Object.keys(obj);

    let isRequired = false;

    // loop over keys and compare with path to see if there is an error 
    keys.forEach((key: string) => {
      // check if the path is included in the array of keys
      if(key.includes(path)) {
        const requiredRules = ['required', 'required_if_includes'];
        // check if rule includes one of the above, if so flag as required
        requiredRules.map((rule: string) => {
          if (obj[key][0].toLowerCase().includes(rule)) isRequired = true;
        })
      }
    });
    return isRequired;
  }

  /** 
   * Decides whether or not to show an asterisk / set isRequired to true
   * 
   * How does this work: 
   * Rather than run the validation rules against the field's value we use the 
   * manual validation method inside vee-validate's api and test it against a 
   * blank value. If it is valud then it does not need an asterisk (is not required) 
   * but if it returns true we set isRequired to true which shows an asterisk. 
   * 
   * For any cross-field validation rules (e.g 'required_if_filled:@urgent') 
   * we also need to supply the validate method with any attributes it will need
   * to process them via the crossValues property.
   * 
   */ 
  checkRequired(): void {
    let ruleKeys = this.ruleKey;
    // 
    if (typeof ruleKeys == 'string') {
      ruleKeys = [ruleKeys];
    }

    // test if rules includes required_tags rule (for vue-tags-input)
    const testRules = this.rules as ValidationTag|string|null;
    if (typeof testRules == 'object' && testRules && testRules.required_tags != null) {
      this.isRequired = true;
      return;
    }

    if (ruleKeys) {
      // for loop to terminate 
      for (const ruleKey of ruleKeys) {
        // set isRequired to true if there is a ruleKey prop and there are validationErrors and the ruleKey exists in the validationErrors 
        if (ruleKey && this.validationErrors && this.propExistsAndRequired(this.validationErrors, ruleKey)) {
          // set isRequired to true and break early
          this.isRequired = true;
          // return early as rest of logic isn't necessary if a validation error occurred from missing required value
          return;
        }
      }
    }

    // get a base set of cross field validation values, 
    // and add to them with any attributes added from the prop crossValues
    const crossValues = { ...this.getBaseCrossValues, ...(this.$props as ValidationType).crossValues };
    const rules = this.convertObjectRules((this.$props as ValidationType).rules);
    validate('', rules, {
      name: 'test',
      values: crossValues
    }).then(result => {
      if (result) {
        // NOTE: only use the async validation result if the rules it was for are still the rules
        if (rules == this.convertObjectRules((this.$props as ValidationType).rules)) this.isRequired = !result.valid;
      } else {
        this.isRequired = false;
      }
    }).catch((error: any) => {
      this.isRequired = false;
    });
  }

  public mounted(): void {
    this.checkRequired();
  }

  // Check rules against blank value
  @Watch('rules')
  @Watch('crossValues')
  @Watch('getBaseCrossValues')
  @Watch('validationErrors')
  runOnUpdate(): void { 
    this.checkRequired();
  }
}
</script>
