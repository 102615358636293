
import { APIRecipient } from '@/APIModels/recipients/types';
import { APIJourney } from '@/store/recipientJourney/types';
import { UIJourneySummary } from '@/UIModels/journey/journeySummary';
import { calculateAge, sortByDate } from '@/utils';
import { parseDisplayDateUi } from '@/utilities/date-utils';
import { SortType } from '@/types';
import { buildDisplayName } from '@/UIModels/recipient';

export class UIRecipientSummary {
  public apiSource?: APIRecipient;
  public clientId: string|null = null;

  public summaryName: string|null = null;
  public summaryStatus: string|null = null;

  public dateOfBirth: string|null = null;
  public age: number|null = null;
  public sex: string|null = null;
  public bloodType: string|null = null;

  public journeySummaries: UIJourneySummary[] = [];

  get activeJourneys(): UIJourneySummary[] {
    return this.journeySummaries.filter((uiJourneySummary: UIJourneySummary) => { return uiJourneySummary.summaryCategory === 'active'; });
  }

  get postTransplantJourneys(): UIJourneySummary[] {
    const filtered = this.journeySummaries.filter((uiJourneySummary: UIJourneySummary) => { return uiJourneySummary.summaryCategory === 'post_transplant'; });
    return sortByDate(filtered, { fieldName: 'transplantedDateObject', sortType: SortType.Descending });
  }

  get closedJourneys(): UIJourneySummary[] {
    return this.journeySummaries.filter((uiJourneySummary: UIJourneySummary) => { return uiJourneySummary.summaryCategory === 'closed'; });
  }

  public constructor(apiRecipient?: APIRecipient) {
    if (apiRecipient) this.updateFromAPIResponse(apiRecipient);
  }

  // Map from API data structure to UI model structure
  public updateFromAPIResponse(apiRecipient: APIRecipient) {
    this.apiSource = apiRecipient;
    
    this.summaryName = buildDisplayName(apiRecipient);
    this.summaryStatus = apiRecipient.death?.death_date ? 'deceased_indicator' : null;

    this.clientId = String(apiRecipient.client_id);

    this.dateOfBirth = parseDisplayDateUi(apiRecipient.patient_profile?.birth?.date) || null;
    const calculatedAge = calculateAge(apiRecipient.patient_profile?.birth?.date || null, apiRecipient.death?.death_date || null);
    this.age = calculatedAge != null ? calculatedAge : null;

    this.sex = apiRecipient.patient_profile?.sex || null;
    this.bloodType = apiRecipient.diagnostics?.blood?.type || null;

    if (!!apiRecipient.journeys && apiRecipient.journeys.length > 0) this.journeySummaries = apiRecipient.journeys.map((apiJourney: APIJourney): UIJourneySummary => {
      return new UIJourneySummary(apiJourney);
    });
  }
}
