import { APIContactInfoConfig } from "@/APIModels/configuration/features/types";
import { UIFeatureConfig } from "@/UIModels/configuration/features";

export class UIContactInfoConfig {
  public enabled = false;
  public contactDetails = new UIFeatureConfig();
  public addresses = new UIFeatureConfig();

  public constructor(apiSource?: APIContactInfoConfig) {
    if (apiSource?.enabled) {
      this.enabled = apiSource.enabled;
      this.contactDetails = new UIFeatureConfig(apiSource.patient_contact_details);
      this.addresses = new UIFeatureConfig(apiSource.patient_addresses);
    }
  }
}
