<template>
  <span>
    <router-link :to="{ name: 'list-living-donors' }">
      {{ $t('living_donors') }}
    </router-link>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { i18nMessages } from '@/i18n';

@Component({
  ...i18nMessages([
    require('@/views/_locales/common.json'),
  ])
})
export default class ListLivingDonorsLink extends Vue {
}
</script>
