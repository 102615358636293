import { APIRecipient } from '@/APIModels/recipients/types';
import { APIRecipientImportHistory } from '@/store/recipients/types';
import { UIRecipient } from '@/UIModels/recipient';
import { APIRoute, EP } from '@/api-endpoints';
import axios from 'axios';
import { UIError } from '@/UIModels/error';
import { APIShowResponse } from '@/types';

export class UIRecipientImportHistoryRow {
  public apiSource?: APIRecipient;
  public datetime: string|null = null;
  public status: string|null = null;
  public uploadedBy: string|null = null;

  // Define new UI view model structure
  public constructor(apiHistory: APIRecipientImportHistory|null = null) {
    if (apiHistory) this.updateFromAPIResponse(apiHistory);
  }

  // Map from API data structure to UI model structure
  public updateFromAPIResponse(apiHistory: APIRecipientImportHistory) {
    this.apiSource = apiHistory;
    this.datetime = apiHistory.created_at || null;
    this.uploadedBy = apiHistory.updated_by || null;
    this.status = apiHistory.status || null;
  }
}

export class UIRecipientImportHistory {  
  public importHistory: UIRecipientImportHistoryRow[] = [];
  public recordCount = 0;

  public loaded = false;

  // Load import list for specified recipient
  public async load(uiRecipient: UIRecipient, params: any): Promise<void> {
    const recipientId = uiRecipient.clientId || '';
    const url = APIRoute(EP.recipients.import_history.index, [[':recipient_id', recipientId]]);

    try {
      const response: APIShowResponse<any> = await axios.get(url, { params: params });
      const apiImportHistory: APIRecipientImportHistory[] = response?.data?.import_row_infos.entries || [];
      this.importHistory = apiImportHistory.map((item: APIRecipientImportHistory): UIRecipientImportHistoryRow => {
        return new UIRecipientImportHistoryRow(item);
      });
      this.recordCount = response.data.record_count;
      this.loaded = true;
    } catch (error: unknown) {
      this.loaded = true;
      console.warn(error);
      throw new UIError('import_patients');
    }
  }  
}
