import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "living-donor-summary" }
const _hoisted_2 = {
  key: 0,
  class: "list-inline-item"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "list-inline-item" }
const _hoisted_5 = {
  key: 1,
  class: "list-inline-item"
}
const _hoisted_6 = {
  key: 2,
  class: "list-inline-item"
}
const _hoisted_7 = {
  key: 3,
  class: "list-inline-item"
}
const _hoisted_8 = {
  key: 4,
  class: "list-inline-item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sticky_summary = _resolveComponent("sticky-summary")!

  return (_ctx.uiLivingDonorSummary)
    ? (_openBlock(), _createBlock(_component_sticky_summary, { key: 0 }, {
        summaryContent: _withCtx(() => [
          _createElementVNode("ul", _hoisted_1, [
            (_ctx.isBasicNameEnabled)
              ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
                  _createElementVNode("span", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('name')) + ": ", 1),
                    _createElementVNode("b", null, [
                      _createTextVNode(_toDisplayString(_ctx.uiLivingDonorSummary.summaryName) + " ", 1),
                      (_ctx.uiLivingDonorSummary.summaryStatus)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t(_ctx.uiLivingDonorSummary.summaryStatus)), 1))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("li", _hoisted_4, [
              _createElementVNode("span", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('client_id')) + ": ", 1),
                _createElementVNode("b", null, _toDisplayString(_ctx.uiLivingDonorSummary.clientId), 1)
              ])
            ]),
            (_ctx.isDateOfBirthEnabled)
              ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
                  _createElementVNode("span", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('dob')) + ": ", 1),
                    _createElementVNode("b", null, _toDisplayString(_ctx.uiLivingDonorSummary.dateOfBirth || '-'), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.isDateOfBirthEnabled)
              ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
                  _createElementVNode("span", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('age')) + ": ", 1),
                    _createElementVNode("b", null, _toDisplayString(_ctx.uiLivingDonorSummary.age != null ? _ctx.uiLivingDonorSummary.age : '-'), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.isSexEnabled)
              ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
                  _createElementVNode("span", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('sex')) + ": ", 1),
                    _createElementVNode("b", null, _toDisplayString(_ctx.uiLivingDonorSummary.sex || '-'), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.isBloodTypeEnabled)
              ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
                  _createElementVNode("span", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('abo')) + ": ", 1),
                    _createElementVNode("b", null, _toDisplayString(_ctx.uiLivingDonorSummary.bloodType || '-'), 1)
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}