<template>
  <div>
    <loading-living-donor-page v-if="!dispatchEventsComplete" />
    <template v-else>
      <page-top>
        <list-living-donors-link />
        / {{ $t('new') }}
      </page-top>

      <div class="content-wrap">
        <div class="container-fluid">
          <div class="nav-wrapper">
            <side-nav-living-donor-profile :newLivingDonor="true" />
            <div class="page-content">
              <!-- Card Sections -->
              <demographics
                ref="demographics"
                :newLivingDonor="true"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-facing-decorator';
import { Vue } from "vue-facing-decorator";
import PageTop from '@/components/shared/PageTop.vue';
import Demographics from '@/components/livingDonors/Demographics.vue';
import SideNavLivingDonorProfile from "@/components/livingDonors/side-nav/SideNavLivingDonorProfile.vue";
import { i18nMessages } from '@/i18n';
import LoadingLivingDonorPage from '@/components/shared/loading-skeletons/LoadingLivingDonorPage.vue';
import ListLivingDonorsLink from '@/components/shared/page-top/ListLivingDonorsLink.vue';
import { useCurrentPageStore } from '@/stores/currentPage';

@Component({
  components: {
    LoadingLivingDonorPage,
    PageTop,
    ListLivingDonorsLink,
    Demographics,
    SideNavLivingDonorProfile,
  },
  ...i18nMessages([
    require('@/views/_locales/common.json'),
  ]),
})
export default class NewLivingDonor extends Vue {
  private dispatchEventsComplete = false;

  public async mounted(): Promise<void> {
    await useCurrentPageStore().currentLivingDonor?.loadNew();

    this.dispatchEventsComplete = true;
    this.$store.dispatch('utilities/scrollBehavior');
  }
}
</script>
